import React, { useEffect } from 'react' 
import '../layout/Portifolio.Module.css'
import $ from 'jquery'
import { projectos } from '../events';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes } from 'react-router-dom'

function Portfolio(){
  useEffect(() =>{

    $(".list").on('click',function(e){
      $(".list").removeClass("active");
      $(this).addClass("active");
    })

    $('.list').on('click',function(){
      const value = $(this).attr('data-filter');
      if(value == 'all'){
        $('.itemBox').show('1000');
      }
      else{
        $('.itemBox').not(`.${value}`).hide('1000');
        $('.itemBox').filter(`.${value}`).show('1000');
      }
    });

}, [])

const { t, i18n } = useTranslation('');
  return (
   <div className='portifolio'>
    <section id="gallery">

        <h4>{t('portifolio.titleTrab')}</h4>
        <h1 className='title_head'>{t('portifolio.portoSele')}</h1>

      <div className='filter-button'>
      <ul>
      <li className='list active' data-filter="all">{t('portifolio.tudo')}</li>
      <li className='list' data-filter={t('portifolio.comer')}>{t('portifolio.comer')}</li>
      <li className='list' data-filter={t('portifolio.exte')}>{t('portifolio.exte')}</li>
      <li className='list' data-filter={t('portifolio.inte')}>{t('portifolio.inte')}</li>
      <li className='list' data-filter={t('portifolio.indus')}>{t('portifolio.indus')}</li>
      <li className='list' data-filter={t('portifolio.pub')}>{t('portifolio.pub')}</li>
    </ul>
      </div>

        <ul className="works">
  
         {projectos.map((item)=>(
             <li className={`${item.type[i18n.language]} itemBox`} key={item.id}>
             <div className='img-overlay'>
              <Link to={`/projectos/${item.name}`}><img src={item.imgP} width="100%" alt={item.type[i18n.language]}/>
             <div className="overlay-txt">
              <div className="text">{item.name}</div>
             </div>
             </Link>
             </div>
           </li>
         ))}

        </ul>

      </section>
      </div>

  )
}

export default Portfolio