import React, { useState } from 'react'
import styles from '../layout/Footer.Module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMapMarkedAlt} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faLinkedin, faYoutube, faTiktok} from '@fortawesome/free-brands-svg-icons'
import logob from '../images/logos/logo-b.png'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

function Footer() {
 const facebook = {color: "white"}
 const bege = {color: "#B09273"} 
 const [isActive, setActive] = useState("true");

 // Menu Open and Close function
 const OpenMenu = () =>{
    setActive(!isActive);
};

const { t, i18n } = useTranslation('');

  return (
    <>
    <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30 d-flex justify-content-center">
                        <div className="single-cta place-icon">
                        <FontAwesomeIcon className='icon-place' icon={faMapMarkedAlt} size="3x" flip="horizontal" style={bege}/>
                            <div className="cta-text visit">
                                <h4>{t('footer.visitar')}</h4>
                                <span>Rua A, Lote 7 <br></br>4480-619 – Árvore Vila do Conde, Portugal</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30 d-flex justify-content-center">
                        <div className="single-cta">
                        <FontAwesomeIcon icon={faPhone} size="3x" flip="horizontal" style={bege}/>
                            <div className="cta-text">
                                <h4>{t('footer.ligar')}</h4>
                                <span>+351 252 615 273</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30 d-flex justify-content-center">
                        <div className="single-cta">
                        <FontAwesomeIcon icon={faEnvelope} size="3x" flip="horizontal" style={bege}/>
                            <div className="cta-text">
                                <h4>{t('footer.contactar')}</h4>
                                <span>geral@gruporr.pt</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href=""><img src={logob} className="img-fluid" alt="logo" id='logo'/></a>
                            </div>
                            <div className="footer-text">
                                <p><a href='/politica-de-privacidade/'>Política de privacidade</a></p>
                                <p><a href='/politica-de-cookies/'>Política de cookies</a></p>
                                <p><a href='https://www.livroreclamacoes.pt/Inicio/'>Livro de reclamações</a></p>
                            </div>
                            <div className="footer-social-icon">
                                <span>{t('footer.seguir')}</span>
                                <a href="https://www.facebook.com/Advancelight/"><FontAwesomeIcon icon={faFacebook} style={facebook} size="3x"/></a>
                                <a href="https://www.instagram.com/advancelightpt/"><FontAwesomeIcon icon={faInstagram} size="3x"/></a>
                                <a href="https://www.youtube.com/channel/UCWZeoH9q8Rg4KFIWHDzQVRw"><FontAwesomeIcon icon={faYoutube} style={facebook} size="3x"/></a>
                                <a href="https://www.linkedin.com/company/grupo-ruan-ruan/"><FontAwesomeIcon icon={faLinkedin} style={facebook} size="3x"/></a>
                                <a href="https://www.tiktok.com/@advancelightpt"><FontAwesomeIcon icon={faTiktok} style={facebook} size="3x"/></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>{t('footer.luteis')}</h3>
                            </div>
                            <ul>
                                <li><a href="/">{t('navbar.home')}</a></li>                        
                                <li><Link to="sobre-nos">{t('navbar.sobre')}</Link></li>
                                <li><Link to="portfolio">{t('navbar.portfolio')}</Link></li>
                                <li><Link to="noticias">{t('homepage.titleNoticias')}</Link></li>
                                <li><Link to="eventos">{t('navbar.eventos')}</Link></li>
                                <li><Link to="contactos">{t('navbar.contactos')}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>{t('footer.horario')}</h3>
                            </div>
                            <div className="footer-text mb-25">
                            <ul>
                               {t('footer.diasuteis')}
                               <br/>09:30 – 18:30<br/><br/>
                               {t('footer.fsemana')}<br/>
                               {t('footer.encerrado')}
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

     {/* Sidebar */}
     <div className="sidebar">
        <div className={`fixedSidebar fixedSidebarRight ${isActive ?  null : "active"}`}>
          <a href="tel:+351 252 615 273" className="sidebarItem" data-tooltip="Contacto">
          <i className="fa fa-phone" aria-hidden="true"></i>
          </a>
          <a href="https://www.facebook.com/Advancelight/" className="sidebarItem" data-tooltip="Facebook">
          <i className="fab fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="https://www.instagram.com/advancelightpt/" className="sidebarItem" data-tooltip="Instagram">
          <i className="fab fa-instagram" aria-hidden="true"></i>
          </a>
          <a href="https://www.linkedin.com/company/grupo-ruan-ruan/" className="sidebarItem" data-tooltip="Linkedin">
          <i className="fab fa-linkedin" aria-hidden="true"></i>
          </a>
          <a href="https://www.tiktok.com/@advancelightpt" className="sidebarItem" data-tooltip="Tiktok">
            <i className="fab fa-tiktok" aria-hidden="true"></i>
          </a>
          <a href="mailto:geral@gruporr.pt" className="sidebarItem" data-tooltip="Email">
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </a>
          <a className={`sidebarItem buttonTrigger ${isActive ?  null : "active"}`} onClick={(OpenMenu)}>
            <svg version="1.1" id="fi_626033" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 490.651 490.651" style={{enableBackground: 'new 0 0 490.651 490.651'}} xmlSpace="preserve">
              <g>
                <g>
                  <path d="M370.211,472.453L143.053,245.317L370.189,18.203c4.16-4.16,4.16-10.923,0-15.083c-4.16-4.16-10.923-4.16-15.083,0
			L120.44,237.787c-4.16,4.16-4.16,10.923,0,15.083l234.667,234.667c2.091,2.091,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115
			C374.371,483.376,374.371,476.613,370.211,472.453z" />
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>

    </>
  )
}

export default Footer