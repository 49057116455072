import React, { useEffect, useState, useRef} from 'react' 
import { Link, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import '../layout/Popups.Module.css'
import '../layout/Navbar.Module.css'
import logoBranco from '../images/logos/Logo com Avatar br.png'
import  $ from 'jquery'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import emailjs from '@emailjs/browser'
import axios from 'axios';
import Recru from './Recru'
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import i18n from './i18n';

function LoginModal({
  login, loginClose,
  loginShow,register,
  registerClose,registerShow,
  changeModal,changeToLogin
}) {

/************Change Login/Register modal**********/
function submitRegister(){
  alert("Entraremos em contacto em breve")
}
const { t, i18n } = useTranslation('');
const [email, setEmail] = useState('');
const [password, setPassword] = useState('');
const { login: authenticate } = useAuth();

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
      const response = await axios.post('https://advancetest.advance-rr.com/login.php', {
          email: email,
          password: password,
      });

      console.log('Resposta do servidor:', response.data); 

      const data = response.data;

      // Certifique-se de que a estrutura da resposta está correta
      if (data.success) {
          authenticate({ nome: data.nome, email: data.email });
          loginClose();
      } else {
          alert(data.message || 'Erro desconhecido'); // Use um valor padrão se message não existir
      }
  } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Ocorreu um erro ao tentar fazer login.');
  }
};

const form = useRef();
function handleDownload(e){
  e.preventDefault();
    alert('Entraremos em contacto logo que possível para fornecer os dados da sua nova conta');

  emailjs.sendForm('service_u0tl3ep', 'template_4zyrqei', form.current, {
    publicKey: 'q9GuAywOCrOMlKxC4',
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );
}

  /**************Esqueçi a palavra passe**************** */
  const [Email, setEm] = useState('');
  const [message, setMessage] = useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSub = async (e) => {
      e.preventDefault();

      try {
          const response = await axios.post('https://advancetest.advance-rr.com/solicitar_redefinicao.php', {
              email: Email,
          });

          setMessage(response.data.message);
          console.log(response.data.message)
      } catch (error) {
          console.error('Erro ao solicitar redefinição:', error);
          setMessage('Erro ao enviar solicitação. Tente novamente mais tarde.');
      }
  };

  return (
    <>
    {/*Pop up downloads*/}
    <Modal className={``} show={login} onHide={loginShow} id="popup-login">
    <button className="close-loginModal" onClick={loginClose}>&times;</button>
      <div className="left">
      <img src={logoBranco} alt="icon"/>
    </div>

    <div className="right">
        <div className="title">
          <h2>Login</h2>
        </div>
        <div className="form">
          <form onSubmit={handleSubmit}>
            <div className="inputbox">
            <input 
                type="email" 
                placeholder="Username/Email" 
                required 
                value={email}
                onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="inputbox">
            <input 
                type="password" 
                placeholder="Password" 
                required 
                value={password}
                onChange={(e) => setPassword(e.target.value)}/>
            </div>

            <button type="submit" className="login-btn">Sign In</button>
          </form>
           <div className="links">
             <button className='switch' onClick={changeModal}>{t('login.conta')}<span>{t('login.regis')}</span></button>
             <button className='switch' onClick={handleShow}>{t('login.forgot')}</button>
           </div>
        </div>
      </div>
    </Modal>

    <Modal className={''} show={register}  onHide={registerShow} id="popup-register">
       <button className="close-loginModal" onClick={registerClose}>&times;</button>

      <div className="left">
      <img src={logoBranco} alt="icon"/>
    </div>

    <div className="right">
        <div className="title">
          <h2>Registro</h2>
        </div>
        <div className="form">
          <form ref={form} onSubmit={handleDownload} method='POST'>
            <div className="inputbox">
              <input type="text" placeholder="Nome empresa*" name="from_nome" required />
            </div>
            <div className="inputbox">
              <input type="email" placeholder="Email*" name="from_email" required />
            </div>
            <div className="inputbox">
              <input type="text" placeholder="NISS*" name="from_niss" required/>
            </div>
            <button type="submit"  className="login-btn">{t('login.regis')}</button>
          </form>
          <div className="links">
          <button className='switch' onClick={changeToLogin}>{t('login.temconta')}<span>Login in</span></button>
           </div>
        </div>
      </div>
    </Modal>

     <Modal show={show} onHide={handleClose}>
      <>
      <Modal.Header closeButton>
          <Modal.Title>Alterar palavra passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div className="panel panel-default">
        <div className="panel-body">
          <div className="text-center">
            <h3><i className="fa fa-lock fa-4x" /></h3>
            <h2 className="text-center">Forgot Password?</h2>
            <p>You can reset your password here.</p>
            <div className="panel-body">
              <form id="register-form" className="form" method="post" onSubmit={handleSub}>
                <div className="form-group">
                  <div className="input-group">
                    <span className="input-group-addon"><i className="glyphicon glyphicon-envelope color-blue" /></span>
                    <input type="email" className='form-control' placeholder="Digite seu email" value={Email} onChange={(e) => setEm(e.target.value)} required/>
                  </div>
                </div>
                <div className="form-group">
                  <input name="recover-submit" className="btn btn-lg btn-primary btn-block" defaultValue="Reset Password" type="submit" />
                   {message && <p>{message}</p>}
                </div>
                <input type="hidden" className="hide" name="token" id="token" defaultValue /> 
              </form>
            </div>
          </div>
        </div>
      </div>
        </Modal.Body>
      </>
      </Modal>
    </>
  )
}

export default LoginModal
