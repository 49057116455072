import React, { useEffect, useState } from 'react' 

import $ from 'jquery'
import '../layout/Filter.Module.css'
import '../layout/Portifolio.Module.css'
import { news } from '../noticias';
import { Link, Route, Routes } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next';
function Noticias() {

  useEffect(() =>{
    $(".list").on('click',function(e){
      $(".list").removeClass("active");
      $(this).addClass("active");
    })

    $('.list').on('click',function(){
      const value = $(this).attr('data-filter');
      if(value == 'all'){
        $('.news-card').show('1000');
      }
      else{
        $('.news-card').not('.'+value).hide('1000');
        $('.news-card').filter('.'+value).show('1000');
      }
    });
}, [])

const { t, i18n } = useTranslation('');

  return (
<div className='pag-noticias'>
<section id="gallery">
  
<h4>{t('noticias.tituloPage')}</h4>
<h1 className='title_head'>{t('homepage.titleNoticias')}</h1>

    <div className='filter-button'>
        <ul>
          <li className='list active' data-filter="all">{t('navbar.tudo')}</li>
          <li className='list' data-filter="news">{t('homepage.titleNoticias')}</li>
          <li className='list' data-filter="recru">{t('noticias.recrutamento')}</li>
        </ul>
    </div> 

  <ul className="content-wrapper ">
     
     {news.map((item, index)=>(
         <li className={`news-card ${item.type}`} key={index}>
         <Link to={`/noticia/${item.id}`}>
           <img src={item.imgP} width="100%" alt={item.title[i18n.language]} className='news-card__image '/>
            <div className="news-card__text-wrapper">
              <h2 className="news-card__title">{item.title[i18n.language]}</h2>
              <div className="news-card__post-date">{item.data}</div>
              <div className="news-card__details-wrapper">
                <p className="news-card__excerpt">{item.descri[i18n.language]}</p>
                <a href="#" className="news-card__read-more">{t('noticias.lermais')}<i className="fas fa-long-arrow-alt-right"></i></a>
              </div>
            </div>
            </Link>
         </li>
         ))}

        <li className={`news-card recru`}>
         <Link to={`/candidatura-espontanea/`}>
           <img src="https://static.vecteezy.com/ti/vetor-gratis/p1/8089753-departamento-de-recursos-humanos-escolha-uma-pessoa-para-equipe-pessoal-equipe-maos-use-uma-lupa-para-encontrar-a-melhor-pessoa-para-entrar-a-equipe-vetor.jpg" width="100%" alt="candidatura" className='news-card__image '/>
            <div className="news-card__text-wrapper">
              <h2 className="news-card__title">{t('noticias.candida')}</h2>
              <div className="news-card__post-date"></div>
              <div className="news-card__details-wrapper">
                <p className="news-card__excerpt">{t('recru.text')}</p>
                <a href="#" className="news-card__read-more">{t('noticias.lermais')}<i className="fas fa-long-arrow-alt-right"></i></a>
              </div>
            </div>
            </Link>
         </li>
     </ul>  

</section>
</div>
  )
}

export default Noticias