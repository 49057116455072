export const catalogos = [
    {
      id: 1,
      nameImg: { pt: `Tabela de preços`, en: `Price list`, es: `Lista de precios`,fr:`Liste de prix`, it:`Listino prezzi`, zhCN:`价格表`},
      download: 'https://drive.google.com/file/d/1c9aSATALlCETtg7wZsJkpq0oqtzC3cpt/view',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2024/04/capa-ad-764x1024.png',
    },
    {
      id: 2,
      nameImg:  { pt: `Carregadores de carro`, en: `Car chargers`, es: `Cargadores de coche`,fr:`Chargeurs de voiture`, it:`Caricabatterie per auto`, zhCN:`汽车充电器`},
      download: './pdfs/brochuras/catalogos/brochura-carregadores-v5-.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/11/capa-carregadores-auto-764x1024.png'
     },
  ];

  export const flyers = [
    {
      id: 1,
      nameImg: { pt: 'Perfis para Fita LED', en: `LED strip profiles`, es: `Perfiles de tiras LED`,fr:`Profils de bandes LED`, it:`Profili per strisce LED`, zhCN:`LED 灯条型材`},
       download: './pdfs/brochuras/ADVANCE_Desdobravel-Apliques-Exterior-PC.pdf',
       linkImg: 'https://advance-rr.com/wp-content/uploads/2023/10/perfil-led-capa-764x1024.png'
    },
    {
      id: 2,
      nameImg: { pt: 'Calhas Magnéticas', en: `Magnetic rails`, es: `Carriles magnéticos`,fr:`Rails magnétiques`, it:`Binari magnetici`, zhCN:`磁轨`},
      download: 'https://advance-rr.com/wp-content/uploads/2023/03/ADVANCE_Catalogo-Espelhos-LED.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/10/tabela-magnetica-2023-2-764x1024.png',
    
    },
    {
      id: 3,
      nameImg: 'Espelhos Led', 
      download: '../pdfs/ADVANCE_Catalogo-Espelhos-LED.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2022/08/Espelho-LED-764x1024.png',
    },
    {
      id: 4,
      nameImg: { pt: 'Fontes de alimentação LED', en: `LED power supplies`, es: `Fuentes de alimentación LED`,fr:`Alimentations LED`, it:`Alimentatori a LED`, zhCN:`LED 电源`},
      download: '../pdfs/ADVANCE_Desdobravel-Iluminacao-de-Emergencia.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/10/capa-fonte-alimentacao-764x1024.png',
    },
    {
      id: 5,
      nameImg: { pt: 'Controladores para Fita LED', en: `LED Strip Drivers`, es: `Controladores de tiras LED`,fr:`Pilotes de bandes LED`, it:`Driver per strisce LED`, zhCN:`LED 灯条驱动器`},
      download: '../pdfs/ADVANCE_Desdobravel-Fitas-LED.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/10/capa-controladores-764x1024.png'
    },
  ];

export const brochures = [
    {
      id: 1,
      nameImg: { pt: 'Desdobrável Calha Magnética', en: `Magnetic trough leaflet`, es:`Folleto de la cubeta magnética`,fr:`Brochure sur les cuvettes magnétiques`, it:`Opuscolo sulle cuvette magnetiche`, zhCN:`磁性槽宣传单`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Calha-Magnetica.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-calha-magnetica-764x1024.webp?updatedAt=1730909444416',
    },
    {
      id: 2,
      nameImg: { pt: 'Desdobrável Iluminação Exterior', en: `Leaflet Outdoor Lighting`, es:`Folleto Iluminación exterior`,fr:`Dépliant Éclairage extérieur`, it:`Volantino Illuminazione per esterni`, zhCN:`户外照明传单`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Iluminacao-Exterior.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/03/Desdobravel-iluminacao-exterior-764x1024.png',
    
    },
    {
      id: 5,
      nameImg:{ pt: 'Desdobrável Fitas LED', en: `Leaflet LED strip`, es:`Folleto Tira de LED`,fr:`Bande LED pour dépliants`, it:`Striscia LED a foglietto`, zhCN:`LED 宣传单条`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Fitas-LED.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-Fitas-LED-764x1024.webp?updatedAt=1730909403014'
    },
    {
      id: 6,
      nameImg:{ pt: 'Desdobrável Apliques Exterior', en: `Outdoor applique leaflet`, es:`Folleto de apliques para exteriores`,fr:`Brochure sur les applications extérieures`, it:`Opuscolo sulle applique per esterni`, zhCN:`户外贴花宣传单`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Apliques-Exterior-PC.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-Apliques-Exterior-764x1024.webp?updatedAt=1730909557883'
    },
    {
      id: 7,
      nameImg:{ pt: 'Desdobrável Campânulas LED', en: `LED Bell Leaflet`, es:`Folleto de la campana LED`,fr:`Feuillet de la cloche LED`, it:`Volantino della campana LED`, zhCN:`LED 大钟宣传单`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Campanulas-LED.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-Campanulas-LED-764x1024.webp?updatedAt=1730909558047',
    },
    {
      id: 8,
      nameImg: { pt: 'Desdobrável Produtos R-Electric', en: `R-Electric Product Leaflet`, es:`Folleto del producto R-Electric`,fr:`Fiche produit R-Electric`, it:` Opuscolo del prodotto R-Electric`, zhCN:`R-Electric 产品宣传册`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Produtos-R-Electric.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-Produtos-R-Electric-764x1024.webp?updatedAt=1730909558033',
    },
    
    {
      id: 10,
      nameImg: { pt: 'Desdobrável Iluminação de Piscina', en: `Pool Lighting Leaflet`, es:`Folleto sobre iluminación de piscinas`,fr:`Brochure sur l'éclairage des piscines`, it:`Opuscolo sull'illuminazione della piscina`, zhCN:`泳池照明小册子`},
      download: './pdfs/brochuras/Piscina-Maxled.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Ilu-Piscina.webp?updatedAt=1730909558109'
    },
    {
      id: 11,
      nameImg: { pt: 'Desdobrável Downlight TINO', en: `Downlight TINO leaflet`, es:`Downlight TINO folleto`,fr:`Brochure sur le projecteur TINO`, it:`Foglio illustrativo del Downlight TINO`, zhCN:`筒灯 TINO 传单`},
      download: './pdfs/brochuras/TINO.pdf',
      linkImg: './images/downloads/tino.png'
    },
  ];

  /*
  
{

    {
      id: 3,
      nameImg:{ pt: 'Desdobrável Iluminação Solar', en: `Leaflet Solar Illumination`, es:`Folleto Iluminación solar`,fr:`Dépliant Illumination solaire`, it:`Volantino Illuminazione solare`, zhCN:`太阳能照明传单`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Iluminacao-Solar.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-iluminacao-solar-764x1024.webp?updatedAt=1730909344310',
    },
    {
      id: 4,
      nameImg:{ pt: 'Desdobrável Iluminação de Emergência', en: `Emergency Lighting Leaflet`, es:`Folleto sobre alumbrado de emergencia`,fr:`Dépliant sur l'éclairage de secours`, it:`Opuscolo sull'illuminazione di emergenza`, zhCN:`应急照明小册子`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Iluminacao-de-Emergencia.pdf',
      linkImg: 'https://advance-rr.com/wp-content/uploads/2023/03/Desdobravel-iluminacao-de-emergencia-764x1024.png',
    },

      id: 9,
      nameImg:{ pt: 'Desdobrável Sensores de Movimento', en: `Foldable Motion Sensors`, es:`Sensores de movimiento plegables`,fr:`Capteurs de mouvement pliables`, it:`Sensori di movimento pieghevoli`, zhCN:`可折叠运动传感器`},
      download: './pdfs/brochuras/ADVANCE_Desdobravel-Sensores-de-Movimento.pdf',
      linkImg: 'https://ik.imagekit.io/advance/downloads/Desdobravel-Sensores-de-Movimento-764x1024.webp?updatedAt=1730909558002'
    },
  */