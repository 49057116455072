import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationPT from '../i18n/pt/global.json';
import translationEN from '../i18n/en/global.json';
import translationFR from '../i18n/fr/global.json';
import translationES from '../i18n/es/global.json';
import translationIT from '../i18n/it/global.json';
import translationCH from '../i18n/zhCN/global.json';

import './i18n';

const savedLanguage = localStorage.getItem('language') || 'pt';
  
const resources = {
  pt: {translation: translationPT, },
  en: { translation: translationEN,},
  es: { translation: translationES },
  it: { translation: translationIT },
  fr: { translation: translationFR },
  zhCN: { translation: translationCH },
};

i18n
.use(HttpApi) // Usa um backend HTTP para carregar traduções
.use(LanguageDetector) // Detecta o idioma do navegador automaticamente
.use(initReactI18next)
.init({
  detection: {
    caches: ['localStorage'], // Cache o idioma no local storage
    order: ['localStorage', 'navigator'], // Ordem de detecção
  },
  resources,
  lng: savedLanguage, // Defina o idioma padrão
  fallbackLng: 'pt',
  detection: {
    order: ['path', 'cookie', 'htmlTag'], // Ordem de detecção
    caches: ['cookie'],
  },
  interpolation: {
    escapeValue: false // para permitir HTML no texto
  }
});

export default i18n;