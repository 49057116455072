import logo from './logo.svg';
import './App.css';
import Navbar from './components/pages/Navbar'
import Footer from './components/pages/Footer';
import ReactGa from 'react-ga';
import { useEffect } from 'react';
import '../src/components/pages/AuthContext'; // Garanta que i18n é importado
import { AuthProvider } from './components/pages/AuthContext';
function App() {
 useEffect(() =>{
  ReactGa.initialize('G-6GFTR9EKYT')

  //to report page view 
  ReactGa.pageview(window.location.pathname + window.location.search)
 }, [])

  return (

   <div>
    <AuthProvider>
     <Navbar/>
    <Footer/>
    </AuthProvider>
   </div>

  )
}

export default App;



