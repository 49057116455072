export const news = [
    {
      id: 1,
      type: 'news',
      title: {
        pt: 'Os maiores mitos e verdades sobre o LED', en: 'The biggest myths and truths about LED', es: 'Los mayores mitos y verdades sobre el LED'
        ,fr: 'Les plus grands mythes et vérités sur le LED', it:'I principali miti e verità sui LED',  zhCN:`关于 LED 的最大迷思和真相`
      },
      imgP: '../images/noticias/1/lightbulb.jpg',
      descri: {
        pt: `Escolher por essa tecnologia, pode diminuir drasticamente o tempo de manutenção, elevar a qualidade da iluminação do seu ambiente 
      e ainda garantir uma redução significativa da conta no final do mês. Contudo, apesar da popularidade, as pessoas ainda tem algumas dúvidas 
      a respeito do seu funcionamento por se tratar de uma tecnologia recente. Por isso selecionamos alguns mitos e verdades para difundir ainda 
      mais o seu uso.`, 
        en: `Choosing this technology can drastically reduce maintenance time, increase the quality of the lighting in your room 
        and even guarantee a significant reduction in the bill at the end of the month. However, despite its popularity, people still have some doubts about its 
        about how it works because it's a new technology. That's why we've selected a few myths and truths to spread the word even more about its use.`, 
        es: `Elegir esta tecnología puede reducir drásticamente el tiempo de mantenimiento, aumentar la calidad de la iluminación de su habitación e incluso garantizar una reducción significativa de la factura a final de mes. 
        e incluso garantizar una reducción significativa de la factura a final de mes. Sin embargo, a pesar de su popularidad, la gente sigue teniendo algunas dudas sobre cómo funciona. 
        sobre cómo funciona porque es una tecnología nueva. Por eso hemos seleccionado algunos mitos y verdades para difundir aún más su uso.`,
        fr: `Le choix de cette technologie permet de réduire drastiquement le temps de maintenance, d'augmenter la qualité de l'éclairage de votre pièce et même de garantir une réduction significative de la facture à la fin du mois. 
        et même de garantir une réduction significative de votre facture à la fin du mois. Cependant, malgré sa popularité, des doutes subsistent sur son fonctionnement.  Nous avons des doutes sur son fonctionnement car il s'agit d'une nouvelle 
        technologie. C'est pourquoi nous avons sélectionné quelques mythes et vérités pour mieux faire connaître son utilisation. son utilisation.`, 
        it:`La scelta di questa tecnologia può ridurre drasticamente i tempi di manutenzione, aumentare la qualità dell'illuminazione dell'ambiente e garantire persino una significativa riduzione della bolletta a fine mese. 
        e persino garantire una significativa riduzione della bolletta alla fine del mese. Tuttavia, nonostante la sua popolarità, le persone hanno ancora qualche dubbio sul suo funzionamento perché si tratta di una tecnologia nuova. 
        Ecco perché abbiamo selezionato alcuni miti e verità per diffondere ancora di più la notizia.`,
        zhCN: "選擇此技術可大幅減少維護時間、提高房間的照明品質 甚至保證月底的帳單大幅減少。然而，儘管它很受歡迎，人們仍然對它有一些疑慮。因為它是一項新技術，所以對於它的工作原理仍有一些疑問。這就是為什麼我們選擇了幾個迷思和真相，以更廣泛地宣傳它的使用。"
      },

      data: '6/08/2024',
      content:
          [{
          title: {
              pt: '1) Os LEDs duram para sempre', en: '1) LEDs last forever', es: '1) Los LED duran para siempre',fr: '1) Les LED durent éternellement', it:'1) I LED durano per sempre',  zhCN: `1) LED 持久耐用 `
          }, 
          text: {
            pt: `MITO: Os produtos de iluminação LED duram muito mais tempo do que qualquer 
          outra tecnologia de iluminação, mas obviamente não durarão para sempre. No 
          entanto, quando as luzes LED atingem a sua esperança de vida, não significa 
          que deixarão de funcionar. Na maioria dos casos, continuarão a fornecer 60-70% da 
          luminosidade original.`,
           en: `MYTH: If there is an overvoltage or an increase in temperature, the LED can burn out. 
          Under normal operating conditions, its luminous intensity decreases over time, 
          and may even stop emitting light. LEDs have a lifespan of 20,000 to 50,000 hours, 
          and after this time, there is a loss of around 30% in brightness. LEDs also 
          do not emit any ultraviolet light.`, 
          es: `MITO: Si se produce una sobretensión o un aumento de la temperatura, el LED puede quemarse. 
          En condiciones normales de funcionamiento, su intensidad luminosa disminuye con el tiempo, 
          e incluso puede dejar de emitir luz. Los LED tienen una vida útil de 20.000 a 50.000 horas, 
          y después de este tiempo se produce una pérdida de luminosidad de alrededor del 30%. Además, los LED 
          no emiten luz ultravioleta.`,
           fr: `MYTHE : En cas de surtension ou d'augmentation de la température, la LED peut griller. 
          Dans des conditions de fonctionnement normales, son intensité lumineuse diminue avec le temps, 
          et peut même cesser d'émettre de la lumière. Les LED ont une durée de vie de 20 000 à 50 000 heures, 
          et après cette période, la perte de luminosité est d'environ 30 %. En outre, les LED 
          n'émettent pas de lumière ultraviolette.`, 
           it:`MITO: In caso di sovratensione o di aumento della temperatura, il LED può bruciarsi. 
          In condizioni di funzionamento normali, la sua intensità luminosa diminuisce nel tempo, 
          e può anche smettere di emettere luce. I LED hanno una durata di vita compresa tra 20.000 e 50.000 ore, 
          e dopo questo periodo la perdita di luminosità è di circa il 30%. I LED inoltre 
          non emettono luce ultravioletta.`,
          zhCN: `誤解：如果電壓過高或溫度升高，LED 就會燒壞。 在正常操作條件下，其發光強度會隨著時間的推移而降低、 甚至可能停止發光。LED 的壽命為 20,000 到 50,000 小時、 
          之後，亮度會降低約 30%。LED 也 
          不會發出任何紫外光`
          }
          },

          {
          title: {
            pt: '2) Os LEDs não queimam', en: "2) LEDs don't burn out", es: '2) Los LED no se queman',fr: '2) Les LED ne grillent pas', it:'2) I LED non si bruciano', zhCN: `2) LED 不會燒壞 `
          },
          text: {
            pt: `MITO: Se houver sobretensão ou aumento de temperatura, o LED pode queimar. 
          Em condições normais de operação sua intensidade luminosa vai caindo com o tempo, 
          podendo até mesmo, deixar de emitir luz. O LED tem vida útil de 20 a 50 mil horas, 
          e depois deste tempo, há perda de cerca de 30% na luminosidade. Os LEDs também 
          não emitem qualquer luz ultravioleta.`,
           en: `MYTH: If there is an overvoltage or an increase in temperature, the LED can burn out. 
          Under normal operating conditions, its luminous intensity decreases over time, 
          and may even stop emitting light. LEDs have a lifespan of 20,000 to 50,000 hours, 
          and after this time, there is a loss of around 30% in brightness. LEDs also 
          do not emit any ultraviolet light.`, es: `MITO: Si se produce una sobretensión o un aumento de la temperatura, el LED puede quemarse. 
          En condiciones normales de funcionamiento, su intensidad luminosa disminuye con el tiempo, 
          e incluso puede dejar de emitir luz. Los LED tienen una vida útil de 20.000 a 50.000 horas, 
          y después de este tiempo se produce una pérdida de luminosidad de alrededor del 30%. Además, los LED 
          no emiten luz ultravioleta.`,
           fr: `MYTHE : En cas de surtension ou d'augmentation de la température, la LED peut griller. 
          Dans des conditions de fonctionnement normales, son intensité lumineuse diminue avec le temps, 
          et peut même cesser d'émettre de la lumière. Les LED ont une durée de vie de 20 000 à 50 000 heures, 
          et après cette période, la perte de luminosité est d'environ 30 %. En outre, les LED 
          n'émettent pas de lumière ultraviolette.`, 
           it:`MITO: In caso di sovratensione o di aumento della temperatura, il LED può bruciarsi. 
          In condizioni di funzionamento normali, la sua intensità luminosa diminuisce nel tempo, 
          e può anche smettere di emettere luce. I LED hanno una durata di vita compresa tra 20.000 e 50.000 ore, 
          e dopo questo periodo la perdita di luminosità è di circa il 30%. I LED inoltre 
          non emettono luce ultravioletta.`,
          zhCN: `誤解：如果電壓過高或溫度升高，LED 就會燒壞。在正常操作條件下，其發光強度會隨著時間的推移而降低、甚至可能停止發光。LED 的壽命為 20,000 到 50,000 小時、 
          之後，亮度會降低約 30%。LED 也 不會發出任何紫外光。`
          }
          },

          {
          title: {
            pt: '3) A mudança para LEDs é muito dispendiosa', en: "3) Switching to LEDs is very expensive", es: '3) Cambiar a LED es muy caro',
            fr: '3) Le passage aux diodes électroluminescentes est très coûteux', it:'3) Il passaggio ai LED è molto costoso', zhCN: `3) 轉用 LED 的成本非常昂貴 `
          },

          text: {
            pt: `VERDADE:  A diferença de preço de uma lâmpada LED para sua equivalente fluorescente, 
          pode ser mais de 100%, mas sua economia pode chegar a 90% ao ano na sua conta de luz. Como 
          visto acima, as lâmpadas LED tem durabilidade excepcionalmente maior, fazendo o investimento 
          valer a pena no médio e longo prazo.`,
           en: `TRUTH: The price difference between an LED bulb and its fluorescent counterpart, 
          can be more than 100%, but you can save up to 90% a year on your electricity bill. As 
          seen above, LED bulbs have an exceptionally longer lifespan, making the investment 
          worthwhile in the medium and long term.`, 
          es: `VERDAD: La diferencia de precio entre una bombilla LED y su equivalente fluorescente 
          puede ser superior al 100%, pero se puede ahorrar hasta un 90% al año en la factura de la luz. Como puede verse en 
          las bombillas LED tienen una vida útil excepcionalmente más larga, por lo que la inversión merece la pena a medio y largo plazo. 
          merecen la pena a medio y largo plazo.`,
           fr: `VÉRITÉ : La différence de prix entre une ampoule LED et son équivalent fluorescent, 
          peut être supérieure à 100 %, mais vous pouvez économiser jusqu'à 90 % par an sur votre facture d'électricité. Comme nous l'avons vu plus haut, les ampoules 
          Comme nous l'avons vu plus haut, les ampoules LED ont une durée de vie exceptionnellement longue, ce qui rend l'investissement rentable à moyen et long terme. 
          L'investissement est donc rentable à moyen et à long terme.`, 
           it:`VERITÀ: La differenza di prezzo tra una lampadina LED e la sua equivalente fluorescente, 
          può essere superiore al 100%, ma si può risparmiare fino al 90% all'anno sulla bolletta dell'elettricità. Come 
          come si è visto sopra, le lampadine LED hanno una durata di vita eccezionalmente più lunga, rendendo l'investimento conveniente a medio e lungo termine. 
          conveniente a medio e lungo termine.`,
          zhCN: ` 真相：LED 燈泡與螢光燈泡之間的價格差異、 可能超過 100%，但您每年可節省高達 90% 的電費。如 LED燈泡的壽命特別長，從中長期來看，這項投資是值得的。值得中長期投資。`
          }
          },
          {
          title: {
            pt: '4) As luzes LED não funcionam em ambientes muito frios.', en: "4) LED lights do not work in very cold environments.", es: '4) Las luces LED no funcionan en ambientes muy fríos.',
            fr: '4) Les lampes LED ne fonctionnent pas dans les environnements très froids.',  it:'4) Le luci a LED non funzionano in ambienti molto freddi.', zhCN: `4) LED 燈在非常寒冷的環境中無法工作。 `
          },
          text: {
            pt: `MITO: Pelo contrário. A iluminação LED é mais eficiente e pode durar mais tempo quando 
          utilizada em temperaturas frias. Na realidade, o calor excessivo reduz a vida útil dos componentes 
          electrónicos e não apenas dos produtos de iluminação.`,
          en: `MYTH: On the contrary. LED lighting is more efficient and can last longer when 
          used in cold temperatures. In reality, excessive heat reduces the lifespan of electronic components, not just lighting products. 
          not just lighting products.`, 
          es: `MITO: Al contrario. La iluminación LED es más eficiente y puede durar más cuando 
          a bajas temperaturas. En realidad, el calor excesivo reduce la vida útil de los componentes electrónicos, no sólo de los productos de iluminación. 
          no sólo los productos de iluminación.`,
           fr: `MYTHE : Au contraire. L'éclairage LED est plus efficace et peut durer plus longtemps lorsqu'il est 
          plus longtemps lorsqu'elles sont utilisées à des températures froides. En réalité, la chaleur excessive réduit la durée de vie des composants électroniques, et pas seulement des produits d'éclairage. 
          pas seulement les produits d'éclairage.`, 
           it:`MITO: Al contrario. L'illuminazione a LED è più efficiente e può durare più a lungo quando viene 
          temperature fredde. In realtà, il calore eccessivo riduce la durata di vita dei componenti elettronici, non solo dei prodotti di illuminazione. 
          non solo dei prodotti di illuminazione.`,
          zhCN: ` 誤解：恰恰相反。LED 照明更有效率，在低溫環境下使用時壽命更長。在低溫環境下使用會更高效，壽命更長。事實上，過熱會降低電子元件的壽命，而不僅僅是照明產品。不只是照明產品。`
          }
          },
          {
            title: {
              pt: '5) Lâmpadas de LED são mais eficientes do que as outras', en: "5) LED bulbs are more efficient than other bulbs", es: '5) Las lámparas LED son más eficientes que otras',
              fr: '5) Les lampes LED sont plus efficaces que les autres', it:'5) Le lampade a LED sono più efficienti di altre', zhCN: `5) LED 燈泡比其他燈泡更有效率 `
            },
          text: {
            pt: `VERDADE: A eficiência do LED em transformar-se em energia elétrica é de mais de 95%.\n Comparando com uma lâmpada incandescente, 
          como referência, apenas 5% da energia elétrica é convertida em luz, sendo o restante da energia dissipada em perdas por calor. Eles podem durar até 100.000 horas, 
          o que é muito mais do que as lâmpadas fluorescentes ou incandescentes.
          Embora as luzes de cultivo LED possam inicialmente custar mais do que as luzes de cultivo HSP, com o tempo, 
          suas despesas são significativamente  mais baixas do que as luzes de cultivo HPS. Para regular a umidade e a 
          temperatura nas salas HPS, é necessário HVAC adicional. Isto significa que é necessário adicionar novas infraestruturas à sala de cultivo, aumentando o custo do arrefecimento em cada operação. No entanto, os LEDs 
          frequentemente precisam de pouco ou nenhum sistema HVAC. Além disso, o aumento do custo inicial das luzes LED para cultivo será rapidamente compensado pela eletricidade que economizam.`,
          en: `TRUTH: The efficiency of LEDs in converting into electrical energy is over 95%.\n Compared to an incandescent bulb, 
          as a reference, only 5% of the electrical energy is converted into light, the rest being dissipated in heat losses. They can last up to 100,000 hours, 
          which is much longer than fluorescent or incandescent bulbs. Although LED grow lights may initially cost more than HSP grow lights, over time, 
          their costs are significantly lower than HPS grow lights. To regulate humidity and  temperature in HPS rooms, additional HVAC is required. This means that new 
          infrastructure to the grow room, increasing the cost of cooling in each operation. However, LEDs  often need little or no HVAC system. What's more, the increased initial cost of LED grow lights 
          grow lights will quickly be offset by the electricity they save.`, 
          es: `VERDAD: La eficiencia de los LED en la conversión en energía eléctrica es superior al 95 por ciento.\n Comparada con una bombilla incandescente 
          como referencia, sólo el 5% de la energía eléctrica se convierte en luz, el resto se disipa en pérdidas de calor. Pueden durar hasta 100.000 horas, 
          mucho más que las bombillas fluorescentes o incandescentes. Aunque las luces de cultivo LED pueden costar inicialmente más que las HSP, con el tiempo 
          sus costes son significativamente inferiores a los de las luces de cultivo HPS. Para regular la humedad y la 
          para regular la humedad y la temperatura en las salas HPS, se necesita un sistema HVAC adicional. Esto significa que se necesita una nueva 
          infraestructura al cuarto de cultivo, lo que aumenta el coste de la refrigeración con cada operación. Sin embargo, los LED 
          suelen necesitar poco o ningún sistema HVAC. Es más, el mayor coste inicial de las luces de cultivo LED 
          se compensará rápidamente con la electricidad que ahorran.`,
           fr: `VÉRITÉ : L'efficacité des LED dans la conversion de l'énergie électrique est supérieure à 95 %.n Par rapport à une ampoule à incandescence, 
          à titre de référence, seuls 5 % de l'énergie électrique sont convertis en lumière, le reste étant dissipé en pertes de chaleur. Elles peuvent durer jusqu'à 100 000 heures, 
          ce qui est beaucoup plus long que les ampoules fluorescentes ou à incandescence.
          Bien que les lampes de culture à LED coûtent initialement plus cher que les lampes de culture à HPS, au fil du temps, leur coût est nettement inférieur à celui des lampes à HPS, 
          au fil du temps, leur coût est nettement inférieur à celui des lampes de culture HPS. Pour réguler l'humidité et la 
          l'humidité et la température dans les salles HPS, des systèmes de chauffage, de ventilation et de climatisation supplémentaires sont nécessaires. Cela signifie que de nouvelles 
          Cela signifie qu'il faut ajouter une nouvelle infrastructure à la chambre de culture, ce qui augmente le coût du refroidissement à chaque opération. Cependant, les LED 
          ne nécessitent souvent que peu ou pas de système CVC. De plus, le coût initial plus élevé des lampes de culture à LED 
          sera rapidement compensé par les économies d'électricité qu'elles permettent de réaliser.`, 
           it:`VERITÀ: L'efficienza dei LED nel convertire l'energia elettrica è superiore al 95%, 
          come riferimento, solo il 5% dell'energia elettrica viene convertita in luce, mentre il resto viene dissipato in perdite di calore. Possono durare fino a 100.000 ore, 
          che è molto più lunga di quella delle lampadine fluorescenti o a incandescenza. Sebbene le luci di coltivazione a LED possano inizialmente costare di più rispetto alle luci di coltivazione HSP, nel tempo, 
          i loro costi sono significativamente inferiori a quelli delle lampade HPS. Per regolare l'umidità e la 
          temperatura nelle stanze HPS, è necessario un sistema HVAC aggiuntivo. Ciò significa che è necessaria una nuova 
          infrastrutture alla grow room, aumentando il costo del raffreddamento a ogni operazione. Tuttavia, i LED 
          spesso necessitano di un sistema HVAC minimo o nullo. Inoltre, il maggiore costo iniziale delle luci di coltivazione a LED 
          saranno rapidamente compensati dall'elettricità risparmiata.`,
          zhCN:`真相：LED 轉換為電能的效率超過 95%。與白熾燈泡相比，只有 5% 的電能轉換為光，其餘的都散失在熱能損失中。它們的壽命可長達 100,000 小時，遠遠超過螢光燈或白熾燈泡。
          雖然 LED 種植燈最初的成本可能高於 HSP 種植燈，但隨著時間的推移，其成本會明顯低於 HPS 種植燈。為了調節 HPS 室內的濕度和溫度，需要額外的 HVAC。這意味著種植室需要新的基礎設施，增加每次作業的冷卻成本。
          然而，LED 通常只需要很少或根本不需要 HVAC 系統。更重要的是，LED 生長燈所增加的初始成本很快就會被其節省的電力所抵消。`
          }},
          {
          title: {
              pt: '6) Só existe LED na cor branca?', en: "6) Is there only white LED?", es: '6) ¿Sólo hay LED blancos?',
              fr: '6) Y a-t-il seulement une DEL blanche ?', it:'6) Esiste solo il LED bianco?', zhCN: `6) 是否只有白色 LED？`
          },
          text: {
            pt: `MITO: As temperaturas de cor das lâmpadas de LED são medidas em Kelvin (K), que expressa a aparência de cor da luz. 
          Ela varia entre 2700K e 6500K, sendo o menor número a luz quente e o maior, a luz fria. Usando esta variação, temos 
          cores que vão do branco, passando pelo neutro, pelo âmbar, até chegar em uma cor mais laranja/amarelada. Tem a cor ideal 
          para cada momento da sua vida! Além de econômica, é um tipo de lâmpada que se adapta ao seu ambiente trazendo iluminação 
          adequada e aquela economia que a gente ama!`,
          en: `MYTH: The color temperatures of LED bulbs are measured in Kelvin (K), which expresses the color appearance of light. 
          It varies between 2700K and 6500K, the lower number being warm light and the higher, cold light. Using this variation, we have 
          colors ranging from white, to neutral, to amber, to a more orange/yellowish color. There is the ideal color 
          for every moment of your life! As well as being economical, it's a type of light bulb that adapts to your environment, providing 
          and the savings we love!`, 
          es: `MITO: Las temperaturas de color de las bombillas LED se miden en Kelvin (K), que expresa el aspecto cromático de la luz. 
          Varía entre 2700K y 6500K, siendo el número más bajo luz cálida y el más alto, luz fría. Utilizando esta variación, tenemos 
          colores que van del blanco al neutro, pasando por el ámbar y un tono más anaranjado/amarillento. Existe el color ideal 
          para cada momento de tu vida. Además de ser económica, es un tipo de bombilla que se adapta a tu entorno, proporcionando la iluminación adecuada 
          ¡y el ahorro que tanto nos gusta!`,
           fr: `MYTHE : Les températures de couleur des ampoules LED sont mesurées en Kelvin (K), qui exprime l'apparence de la couleur de la lumière. 
          Elle varie entre 2700K et 6500K, le chiffre le plus bas correspondant à une lumière chaude et le plus élevé à une lumière froide. En utilisant cette variation, nous avons 
          couleurs allant du blanc au neutre, en passant par l'ambre et une couleur plus orangée/jaunâtre. Il existe une couleur idéale 
          pour chaque moment de votre vie ! En plus d'être économique, c'est un type d'ampoule qui s'adapte à votre environnement pour vous apporter le bon éclairage et les économies que nous aimons ! 
          et les économies que l'on aime !`, 
           it:`MITO: Le temperature di colore delle lampadine LED si misurano in Kelvin (K), che esprime l'aspetto cromatico della luce. 
          Varia tra 2700K e 6500K: il numero più basso corrisponde alla luce calda e quello più alto alla luce fredda. Utilizzando questa variazione, abbiamo 
          colori che vanno dal bianco, al neutro, all'ambra, a un colore più arancione/giallastro. C'è il colore ideale 
          per ogni momento della vostra vita! Oltre a essere economica, è un tipo di lampadina che si adatta all'ambiente, fornendo la giusta illuminazione e il risparmio che amiamo! 
          e il risparmio che amiamo!`,
          zhCN: `誤解：LED燈泡的色溫是以開爾文(K)來量度的，K表示光的顏色外觀。 它在 2700K 和 6500K 之間變化，數字越小代表暖光，數字越大代表冷光。利用這個變化，我們可以得到  顏色範圍從白色、中性色、琥珀色到偏橙/偏黃的顏色。有一種理想的顏色 適合您生活中的每一刻！除了經濟實惠之外，這種燈泡還可以適應您的環境，提供 和我們喜愛的節約！`
          }
          },
          {title: {
              pt: '7) LED não tem brilho forte?', en: "7) LEDs don't shine brightly?", es: '7) ¿Los LED no brillan?',
              fr: '7) Les diodes électroluminescentes ne brillent pas fort ?', it:'7) I LED non brillano?', zhCN: `7) LED 不發光？`
           },
            text: {
              pt: `Muitas pessoas confundem a capacidade de iluminação das lâmpadas de LED olhando a quantidade de watts ao invés de 
            lúmens. As lâmpadas LED consomem poucos watts e podem chegar a 18.000 lúmens. Hoje a iluminação LED é usada até em show, 
            ginásios e estádios de futebol, garantindo que o público veja tudo o que acontece durante o espetáculo!`,
            en: `Many people confuse the lighting capacity of LED bulbs by looking at the number of watts instead of 
            lumens. LED bulbs consume very few watts and can reach 18,000 lumens. Today LED lighting is even used in concerts, 
            gyms and soccer stadiums, ensuring that the audience can see everything that's happening during the show!`, 
            es: `Mucha gente confunde la capacidad de iluminación de las bombillas LED fijándose en el número de vatios en lugar de en los 
            lúmenes. Las bombillas LED consumen muy pocos vatios y pueden alcanzar los 18.000 lúmenes. Hoy en día, la iluminación LED se utiliza incluso en conciertos 
            gimnasios y estadios de fútbol, garantizando que el público pueda ver todo lo que ocurre durante el espectáculo.`,
             fr: `De nombreuses personnes confondent la capacité d'éclairage des ampoules LED en regardant le nombre de watts au lieu des 
            lumens. Les ampoules LED consomment très peu de watts et peuvent atteindre 18 000 lumens. Aujourd'hui, l'éclairage LED est même utilisé dans les concerts, les gymnases et les stades de football, 
            Aujourd'hui, l'éclairage LED est même utilisé dans les concerts, les gymnases et les stades de football, afin que le public puisse voir tout ce qui se passe pendant le spectacle !`, 
             it:`Molte persone confondono la capacità di illuminazione delle lampadine LED guardando al numero di watt invece che ai lumen. 
            lumen. Le lampadine LED consumano pochissimi watt e possono raggiungere i 18.000 lumen. Oggi l'illuminazione a LED è utilizzata persino nei concerti, 
            palestre e stadi di calcio, assicurando che il pubblico possa vedere tutto ciò che accade durante lo spettacolo!`,
            zhCN: `很多人會混淆 LED 燈泡的照明能力，只看瓦特數，而不是流明數。流明。LED 燈泡消耗的瓦數很少，流明卻可以達到 18,000 流明。如今，LED 照明甚至用於音樂會、健身房和足球場、 體育館和足球場，以確保觀眾能夠看清表演過程中發生的一切！`
            }
          },
            ],
  },

{
  id: 2,
  type: 'news',
  title: {
    pt: 'IP67 e IP69k: entenda o que significam os graus de proteção IP', en: 'IP67 and IP69k: understand what the IP degrees of protection mean', 
    es: 'IP67 e IP69k: entienda qué significan los grados de protección IP',fr: 'IP67 et IP69k : comprendre la signification des degrés de protection IP', 
    it:'IP67 e IP69k: capire il significato dei gradi di protezione IP', zhCN: `IP67 與 IP69k：瞭解 IP 防護等級的意義`
  },

  imgP: '../images/noticias/2/imgIP.png',
    descri: {
      pt: `Escolher por essa tecnologia, pode diminuir drasticamente o tempo de manutenção, elevar a qualidade da iluminação do seu ambiente 
    e ainda garantir uma redução significativa da conta no final do mês. Contudo, apesar da popularidade, as pessoas ainda tem algumas dúvidas 
    a respeito do seu funcionamento por se tratar de uma tecnologia recente. Por isso selecionamos alguns mitos e verdades para difundir ainda 
    mais o seu uso.`, 
      en: `Do IP22, IP44, etc. codes confuse you? Is it a new Morse code or the same IT IP? Don't worry, 
    you're not the only one who thinks so. Many people buy new lights for a kitchen or living room and often don't know what they mean, 
    don't know what they mean, including a lot of salespeople and hypermarkets. But let me try to explain in the most understandable way.`, 
      es: `¿Le confunden los códigos IP22, IP44, etc.? ¿Se trata de un nuevo código Morse o de la misma IP informática? No se preocupe, 
    no eres el único que piensa así. Muchas personas compran luces nuevas para la cocina o el salón y a menudo no saben lo que significan, 
    no saben lo que significan, incluidos muchos vendedores e hipermercados. Pero permítanme que intente explicarlo de la forma más comprensible..`,
      fr: `Les codes IP22, IP44, etc. vous déroutent-ils ? S'agit-il d'un nouveau code Morse ou du même IT IP ? Ne vous inquiétez pas, 
    vous n'êtes pas le seul à le penser. De nombreuses personnes achètent de nouvelles lampes pour la cuisine ou le salon et ne savent souvent pas ce qu'elles signifient, 
    Beaucoup de gens achètent de nouvelles lampes pour la cuisine ou le salon et souvent ne savent pas ce que cela signifie, y compris beaucoup de vendeurs et d'hypermarchés. Mais laissez-moi essayer de vous l'expliquer de la manière la plus compréhensible possible.`, 
      it:`I codici IP22, IP44, ecc. vi confondono? Si tratta di un nuovo codice Morse o dello stesso IP IT? Non preoccupatevi, 
    non siete gli unici a pensarla così. Molte persone acquistano nuove luci per la cucina o il soggiorno e spesso non ne conoscono il significato, 
    non sanno cosa significhino, compresi molti venditori e ipermercati. Ma cerchiamo di spiegarlo nel modo più comprensibile possibile.`,
    zhCN: `IP22、IP44 等代碼是否讓您感到困惑？是新的 Morse 代碼還是相同的 IT IP？別擔心、 您不是唯一這麼想的人。很多人為廚房或客廳買了新燈，卻往往不知道它們的意思、 不知道它們的意思，包括很多銷售人員和大賣場。但讓我試著用最容易理解的方式來解釋。`
    },
  data: '6/08/2024',
  content:
  [{
  title: {
    pt: 'Afinal o que significa IP?', en: 'What does IP mean anyway?', 
    es: '¿Qué significa IP?',fr: "Que signifie d'ailleurs IP ?", it:"Che cosa significa IP?",  zhCN: `IP 到底是什麼意思？`
  },

  text1: {
    pt: `A sigla IP, que corresponde às palavras inglesas “Ingress Protection”, são padrões internacionais definidos pela norma IEC 60529 para classificar 
  e avaliar o grau de proteção de produtos eletrônicos fornecidos contra intrusão (partes do corpo como mãos e dedos), poeira, contato acidental 
  e água. É publicada pela Comissão Eletrotécnica Internacional (IEC).`, 
    en: `The acronym IP, which stands for ‘Ingress Protection’, is an international standard defined by IEC 60529 to classify and evaluate the degree of protection that electronic products provide against 
  electronic products against intrusion (body parts such as hands and fingers), dust, accidental contact and water. 
  and water. It is published by the International Electrotechnical Commission (IEC).`, 
    es: `El acrónimo IP, que significa «Ingress Protection», es una norma internacional definida por la CEI 60529 para clasificar y evaluar el grado de protección que ofrecen los productos electrónicos contra 
  productos electrónicos contra la intrusión (partes del cuerpo como manos y dedos), el polvo, el contacto accidental y el agua. 
  y el agua. Está publicada por la Comisión Electrotécnica Internacional (CEI).`,
    fr: `L'acronyme IP, qui signifie « Ingress Protection », est une norme internationale définie par la norme IEC 60529 pour classer et évaluer le degré de protection que les produits électroniques offrent contre les éléments suivants 
  Les produits électroniques sont protégés contre l'intrusion (parties du corps telles que les mains et les doigts), la poussière, le contact accidentel et l'eau. 
  et l'eau. Elle est publiée par la Commission électrotechnique internationale (CEI).`, 
    it:` L'acronimo IP, che sta per “Ingress Protection”, è uno standard internazionale definito da IEC 60529 per classificare e valutare il grado di protezione che i prodotti elettronici offrono nei confronti di 
    prodotti elettronici contro l'intrusione (parti del corpo come mani e dita), la polvere, il contatto accidentale e l'acqua. 
    e acqua. È pubblicato dalla Commissione Elettrotecnica Internazionale (IEC).`,
    zhCN: `縮寫 IP 是「侵入保護」的縮寫，是 IEC 60529 定義的國際標準，用來分類和評估電子產品對以下情況的保護程度 IP 是 IEC 60529 定義的國際標準，用來分級和評估電子產品對侵入（手和手指等身體部位）、灰塵、意外接觸和水的保護程度。和水。它由國際電工委員會 (IEC) 發布。`
  },

  text2: {
  pt: `É obrigatório que os produtos sejam marcados com o grau de proteção IP, para que saibamos se um foco, um candeeiro ou uma campânula são apropriados 
  para instalação num determinado ambiente.`, 
  en: `It is compulsory for products to be labelled with the IP degree of protection, so that we know whether a spotlight, lamp or hood is suitable 
  for installation in a particular environment.`, 
  es: `Es obligatorio que los productos lleven en la etiqueta el grado de protección IP, para saber si un foco, una lámpara o una campana son adecuados 
  para su instalación en un entorno determinado.`,
  fr: `Les produits doivent obligatoirement porter une étiquette indiquant l'indice de protection IP, ce qui permet de savoir si un projecteur, une lampe ou une hotte peut être installé dans un environnement donné. 
  dans un environnement particulier.`, it:`È obbligatorio che i prodotti siano etichettati con il grado di protezione IP, in modo da sapere se un faretto, una lampada o una cappa sono adatti all'installazione in un determinato ambiente. 
  per l'installazione in un determinato ambiente.`,
   zhCN: `產品必須標示 IP 防護等級，以便我們知道聚光燈、燈飾或罩是否適合安裝在特定環境中。
  是否適合安裝在特定的環境中。`
  },

  txt3: {
    pt: 'Vejamos agora como ', en: "Now let's see how ", 
    es: 'Ahora veamos cómo ',fr: "Voyons maintenant comment ", it:"Vediamo ora come ",
    zhCN: `現在讓我們看看。`
  },

  strongtxt3: {
    pt: 'interpretar os dados sobre a classificação de proteção IP de um produto:', en: "interpret data on a product's IP protection rating:", 
    es: 'interpretar los datos sobre el grado de protección IP de un producto:',fr: "interpréter les données relatives à l'indice de protection IP d'un produit :",
    it:"interpretare i dati relativi al grado di protezione IP di un prodotto:", zhCN: `解釋產品 IP 防護等級的資料 :`
  },

  img1:'../images/noticias/2/tabelaIP.png',
  },
],
},
{
      id: 3,
      type: 'news',
      title: {
        pt: 'Lâmpada inteligente: o que é e como funciona ?', en: 'Smart light bulb: what it is and how it works ?', 
        es: 'Bombilla inteligente: qué es y cómo funciona ?',fr: "Ampoule intelligente : qu'est-ce que c'est et comment ça marche ?",
       it:"Lampadina intelligente: cos'è e come funziona ?",  zhCN: `智慧型燈泡： 它是什麼，如何運作？`
      },
      imgP:  '../images/noticias/4/imgP.png',
      descri: {
        pt: `Em um mundo mais moderno, todos os dias surgem novas tecnologias e com isso muitos produtos inteligentes. E a lâmpada inteligente é uma dessas 
      tecnologias que chegou no setor de iluminação e quem curte inovação e economia, esse conteúdo é para você.`, 
        en: `In a more modern world, new technologies are emerging every day, and with them many smart products. And the smart light bulb is one of these 
      technologies that have arrived in the lighting sector and if you like innovation and savings, this content is for you.`, 
        es: `En un mundo más moderno, cada día surgen nuevas tecnologías, y con ellas muchos productos inteligentes. Y la bombilla inteligente es una de estas 
      tecnologías que han llegado al sector de la iluminación y si te gusta la innovación y el ahorro, este contenido es para ti.`,
        fr: `Dans un monde plus moderne, de nouvelles technologies apparaissent chaque jour, et avec elles de nombreux produits intelligents. L'ampoule 
        intelligente est l'une de ces technologies qui ont fait leur apparition dans le secteur de l'éclairage. Si vous aimez l'innovation et les économies, ce contenu est fait pour vous.`, 
        it:`In un mondo più moderno, ogni giorno emergono nuove tecnologie e con esse molti prodotti intelligenti. E la lampadina intelligente è una di queste tecnologie che sono arrivate 
        nel settore dell'illuminazione e se vi piacciono l'innovazione e il risparmio, questo contenuto fa per voi.`,
        zhCN: `在更現代化的世界裡，每天都有新的科技出現，許多智慧型產品也隨之而來。智慧型燈泡就是其中之一。如果您喜歡創新和節約，這個內容就是為您準備的。`
      },
      data: '6/08/2024',
content:
  [
    {title:'O que é uma lâmpada inteligente e como funciona?',
   text: {
    pt: `As lâmpadas  inteligentes são dispositivos controlados por aplicativo ou comando de voz, por meio de smartphones, smart
   speakers, Wi-Fi ou Zigbee. Elas possuem conectividade sem fio, além de funções como regular a cor e a intensidade da luz,
   a principal diferença em relação à lâmpada comum é sua conectividade, variedade de cores, intensidades e funções. Antes um item de luxo para se tornar realidade no cotidiano.`,
  en: `Smart light bulbs are devices controlled by app or voice command, via smartphones, smart
   speakers, Wi-Fi or Zigbee. They have wireless connectivity, as well as functions such as regulating the color and intensity of the light,
   the main difference with ordinary light bulbs is their connectivity, variety of colors, intensities and functions. Once a luxury item, they are now a reality in everyday life.`, 
  es: `Las bombillas inteligentes son dispositivos controlados mediante una aplicación o un comando de voz, a través de smartphones, altavoces inteligentes, Wi-Fi o Zigbee.
   altavoces inteligentes, Wi-Fi o Zigbee. Tienen conectividad inalámbrica, además de funciones como regular el color y la intensidad de la luz,
   la principal diferencia con las bombillas normales es su conectividad, variedad de colores, intensidades y funciones. Antaño un artículo de lujo, ahora son una realidad en la vida cotidiana.`,
   fr: `Les ampoules intelligentes sont des dispositifs contrôlés par une application ou une commande vocale, via des smartphones, des haut-parleurs intelligents, Wi-Fi ou Zigbee.
   Wi-Fi ou Zigbee. Elles disposent d'une connectivité sans fil, ainsi que de fonctions telles que la régulation de la couleur et de l'intensité de la lumière,
   la principale différence avec les ampoules ordinaires est leur connectivité, leur variété de couleurs, d'intensités et de fonctions. Autrefois objet de luxe, elles sont aujourd'hui une réalité dans la vie de tous les jours.`, 
   it:`Le lampadine intelligenti sono dispositivi controllati tramite app o comando vocale, tramite smartphone, smart speaker, Wi-Fi o Zigbee.
   altoparlanti, Wi-Fi o Zigbee. Dispongono di connettività wireless e di funzioni quali la regolazione del colore e dell'intensità della luce,
   la differenza principale con le normali lampadine è la connettività, la varietà di colori, intensità e funzioni. Un tempo oggetto di lusso, oggi sono una realtà nella vita di tutti i giorni.`,
   zhCN: `智慧型燈泡是透過智慧型手機、智慧型喇叭、Wi-Fi 或 Zigbee，以應用程式或語音指令控制的裝置。它們具有無線連線功能，以及調節燈光顏色和強度等功能，與普通燈泡的主要差異在於它們的連線性、顏色種類、強度和功能。曾經是奢侈品的它們，如今已成為日常生活中的現實。`
  },
   txt3: {
    pt: `Por ser um recurso com alta tecnologia, muitas pessoas acreditam que a instalação desse dispositivo é um processo complicado. No entanto,
   a instalação da lâmpada inteligente é semelhante à comum:`,
  en: `Being a high-tech feature, many people believe that installing this device is a complicated process. However,
   the installation of the smart bulb is similar to the ordinary one:`, 
  es: `Al tratarse de un dispositivo de alta tecnología, mucha gente cree que instalarlo es un proceso complicado. Sin embargo
   la instalación de la bombilla inteligente es similar a la ordinaria:`,
   fr: `Comme il s'agit d'un dispositif de haute technologie, de nombreuses personnes pensent que l'installation de ce dispositif est un processus compliqué. Or, l'installation de l'ampoule intelligente est similaire à celle d'une ampoule ordinaire,
   l'installation d'une ampoule intelligente est similaire à celle d'une ampoule ordinaire :`, 
   it:`Essendo un dispositivo ad alta tecnologia, molti pensano che l'installazione sia un processo complicato. Tuttavia,
   l'installazione della lampadina intelligente è simile a quella ordinaria:`,
   zhCN: `作為一項高科技功能，許多人認為安裝此裝置是一個複雜的過程。然而，智能燈泡的安裝過程與普通燈泡相似 :`
  },

  strongtxt3: {
  pt: `basta rosquear o dispositivo no lugar da lâmpada que você já tem, seja no teto, em uma luminária ou abajur.`,
  en: `simply screw the device in place of the light bulb you already have, whether on the ceiling, in a lamp or lampshade.`, 
  es: `basta con enroscar el dispositivo en lugar de la bombilla que ya tengas, ya sea en el techo, en una lámpara o en una pantalla.`,
  fr: `il suffit de visser l'appareil à la place de l'ampoule que vous possédez déjà, que ce soit au plafond, dans une lampe ou un abat-jour.`, 
  it:`è sufficiente avvitare il dispositivo al posto della lampadina già presente, sia essa a soffitto, in una lampada o in un paralume.`,
  zhCN: `無論是在天花板上、燈飾或燈罩中，只要將裝置擰到您已有的燈泡上即可。`
  },

   listTitle: {
    pt: `Quais as vantagens da lâmpada inteligente?`,
    en: `What are the advantages of the smart bulb?`, 
    es: `¿Cuáles son las ventajas de la bombilla inteligente?`,
    fr: `Quels sont les avantages de l'ampoule intelligente ?`, 
    it:`Quali sono i vantaggi della lampadina intelligente?`,
    zhCN: `智慧型燈泡有哪些優點？`
    },

  img2: '../images/noticias/4/img2.png',
    list:[
      {element: {
        pt: `Acender ou apagar em determinados horários de forma automática todos os dias, sem risco de se esquecer de apagar a luz ou deixar o seu pet no escuro`,
        en: `Automatic switching on or off at certain times every day, with no risk of forgetting to switch off the light or leaving your pet in the dark`, 
        es: `Encendido o apagado automático a determinadas horas cada día, sin riesgo de olvidar apagar la luz o dejar a su mascota en la oscuridad`,
        fr: `Allumage ou extinction automatique à certaines heures de la journée, sans risque d'oublier d'éteindre la lumière ou de laisser votre animal dans l'obscurité.`, 
        it:`Accensione o spegnimento automatico in determinati momenti della giornata, senza il rischio di dimenticare di spegnere la luce o di lasciare il vostro animale domestico al buio`,
        zhCN: `每天特定時間自動開關，不會有忘記關燈或讓寵物處於黑暗中的風險`
        }, },
      {element: {
        pt: `Gastam menos energia, já que as lâmpadas inteligentes são fabricadas com a tecnologia LED;`,
        en: `They use less energy, since smart bulbs are made using LED technology;`, 
        es: `Consumen menos energía, ya que las bombillas inteligentes se fabrican con tecnología LED;`,
        fr: `Elles consomment moins d'énergie, car les ampoules intelligentes sont fabriquées à l'aide de la technologie LED ;`, 
        it:`Consumano meno energia, poiché le lampadine intelligenti sono realizzate con tecnologia LED;`,
        zhCN: `由於智慧型燈泡使用 LED 技術製造，因此能耗較低；`
        },},
      {element:{
        pt: `Preço acessível e qualquer pessoa é capaz de instalar. Basta encaixar num soquete correspondente;`,
        en: `Affordable and anyone can install it. Just plug it into the corresponding socket;`, 
        es: `Asequible y cualquiera puede instalarlo. Basta con conectarlo a la toma correspondiente;`,
        fr: `Abordable, il peut être installé par n'importe qui. Il suffit de le brancher dans la prise correspondante ;`, 
        it:`È conveniente e chiunque può installarlo. Basta collegarlo alla presa corrispondente;`,
        zhCN: `經濟實惠，任何人都可以安裝。只要將它插入相應的插座即可；`
        },},
      {element: {
        pt: `É possível controlar intensidade e configurar uma infinidade de cores (são milhões de tons disponíveis) pelo aplicativo ou comando de voz`,
        en: `You can control intensity and configure a multitude of colours (there are millions of shades available) via the app or voice comman`, 
        es: `Puedes controlar la intensidad y configurar multitud de colores (hay millones de tonos disponibles) a través de la app o de comandos de voz`,
        fr: `Vous pouvez contrôler l'intensité et configurer une multitude de couleurs (des millions de nuances sont disponibles) via l'application ou la commande vocale.`, 
        it: `È possibile controllare l'intensità e configurare una moltitudine di colori (sono disponibili milioni di tonalità) tramite l'app o il comando vocale.`,
        zhCN: `您可以透過應用程式或語音指令控制強度，並配置多種顏色（有數百萬種顏色可供選擇）。`
        },},
      {element: {
        pt: `Pode ser usada para tratamento de cromoterapia;`,
        en: `Can be used for chromotherapy treatment;`, 
        es: `Puede utilizarse para tratamientos de cromoterapia;`,
        fr: `Peut être utilisé pour les traitements de chromothérapie ;`, 
        it: `Può essere utilizzato per il trattamento di cromoterapia;`,
        zhCN: `可用於色光治療；`
        },},
    ],
  },
],
},
    {
      id: 4,
      type: 'news',
      title: {
        pt: 'Grau de proteção (IP) e resistência de impacto (IK) diferenças', en: 'Degree of protection (IP) and impact resistance (IK) differences', 
        es: 'Diferencias en el grado de protección (IP) y la resistencia a los impactos (IK)',fr: "Différences de degré de protection (IP) et de résistance aux chocs (IK)", 
        it:"Differenze nel grado di protezione (IP) e nella resistenza agli urti (IK)", zhCN: `防護等級 (IP) 和耐衝擊性 (IK) 差異`
      },
      imgP:  '../images/noticias/5/imgP.png',
      descri: {
        pt: `Conheça neste artigo a importância Índice de proteção mecânica(IK), diferenças entre IP e IK e , além de conhecer exemplos de luminárias com 
      excelente índice de proteção para fazer o seu projeto de iluminação mais assertivo e com os produtos certos., 
      também recomendamos que leiam a matéria sobre grau de Proteção IP`, 
        en: `In this article, you'll learn about the importance of the mechanical protection index (IK), the differences between IP and IK and examples of luminaires with an 
        excellent protection index to make your lighting project more assertive and with the right products. We also recommend that you read the article on the IP degree of protection.`, 
        es: `En este artículo, conocerá la importancia del índice de protección mecánica (IK), las diferencias entre IP e IK y ejemplos de luminarias con un excelente 
        índice de protección para que su proyecto de iluminación sea más asertivo y con los productos adecuados. También le recomendamos que lea el artículo sobre el grado de protección IP.`,
        fr: `Dans cet article, vous découvrirez l'importance de l'indice de protection mécanique (IK), les différences entre IP et IK et des exemples de luminaires 
        avec un excellent indice de protection pour que votre projet d'éclairage soit plus affirmé et avec les bons produits. Nous vous recommandons également de lire l'article sur le degré de protection IP.`, 
        it:`Conheça neste artigo a importância Índice de proteção mecânica(IK), diferenças entre IP e IK e , além de conhecer exemplos de luminárias com excelente índice de proteção para 
        fazer o seu projeto de iluminação mais assertivo e com os produtos certos., também recomendamos que leiam a matéria sobre grau de Proteção IP`,
        zhCN: `在這篇文章中，您將學習到機械防護指數 (IK) 的重要性、IP 與 IK 的差異，以及具有出色防護指數的燈具實例。 優異的防護指數，讓您的照明專案更有自信，並使用正確的產品。我們也建議您閱讀有關 IP 防護等級的文章。`
      },
      data: '6/08/2024',
      content:
      [
      {title: {pt: `O que é índice de resistência a impacto?`,en: `What is the impact resistance index?`, es: `¿Qué es el índice de resistencia al impacto?`,
        fr: `Quel est l'indice de résistance aux chocs ?`, it:`Qual è l'indice di resistenza agli urti?`, zhCN: `什麼是耐衝擊指數？`},

      text: {
        pt: `O IK (índice de proteção mecânica) considera que o equipamento é resistente contra impactos e vibrações, ou seja, aquilo que não se pode prever. 
      É eficiente para determinar a força da luminária contra quedas, marretadas ou atos de vandalismo, por exemplo.`,
        en: `The IK (mechanical protection index) considers how resistant the equipment is to impacts and vibrations, i.e. what cannot be predicted. 
      It is effective in determining the strength of the luminaire against falls, smashes or acts of vandalism, for example.`, 
        es: `El IK (índice de protección mecánica) tiene en cuenta la resistencia del equipo a impactos y vibraciones, es decir, lo que no se puede predecir. 
      Es eficaz para determinar la resistencia de la luminaria frente a caídas, aplastamientos o actos vandálicos, por ejemplo.`,
        fr: `L'IK (indice de protection mécanique) prend en compte la résistance de l'équipement aux chocs et aux vibrations, c'est-à-dire à ce qui n'est pas prévisible. 
      Il est efficace pour déterminer la résistance du luminaire aux chutes, aux chocs ou aux actes de vandalisme, par exemple.`, 
        it:`L'IK (indice di protezione meccanica) considera la resistenza dell'apparecchio agli urti e alle vibrazioni, cioè a ciò che non può essere previsto. 
      È efficace per determinare la resistenza dell'apparecchio a cadute, urti o atti di vandalismo, ad esempio.`,
      zhCN: `IK（機械保護指數）考慮設備對撞擊和震動的抵抗力，也就是無法預測的情況。舉例來說，它能有效判斷燈具對墜落、砸碎或惡意破壞行為的抵抗力。`
      },
      },
      {
      title: {
        pt: `IK e IP: diferenças?`,
        en: `IK and IP: differences?`, 
        es: `IK e IP: ¿diferencias?`,
        fr: `IK et IP : des différences ?`, 
        it:`IK e IP: differenze?`,
        zhCN: `IK 和 IP：差異？`
      },

      txt3: {
      pt: `Enquanto o grau de IK informa o grau de resistência a impactos e vibrações o`,
      en: `While the degree of IK informs about the degree of resistance to impacts and vibrations, the`, 
      es: `Mientras que el grado IK indica el grado de resistencia a impactos y vibraciones, el`,
      fr: `Alors que l'indice IK indique le degré de résistance aux chocs et aux vibrations`, 
      it:`Mentre il grado IK indica il grado di resistenza agli urti e alle vibrazioni`,
      zhCN: `IK 值可提供耐衝擊和振動的程度，而 IK 值則可提供耐衝擊和振動的程度。`
      },
      strongtxt3: {pt:`IP indica o quão resistente é uma luminária contra sólidos ou líquidos.`,
        en:` indicates how resistant a luminaire is to solids or liquids.`, es:`IP indica la resistencia de una luminaria a sólidos o líquidos.`,
         fr:`L'indice de protection IP indique le degré de résistance d'un luminaire aux solides ou aux liquides.`, 
         
        it:`L'IP indica la resistenza di un apparecchio di illuminazione a solidi o liquidi.`},
      },
      { title:
      {pt:`Escala de grau IK`,en:`IK grade scale`, es:`Escala de grados IK`, fr:`Échelle de grades IK`,  it:`Scala di valutazione IK`,zhCN: `IK 等級表` },

      img2: '../images/noticias/5/tabelaIP.png',
      },
      {
      title:
      {
        pt:`Quais as diferenças de aplicações de luminárias com IK?`,
        en:`What are the differences between IK luminaire applications?`, 
        es:`¿Qué diferencias hay entre las aplicaciones de luminarias IK?`,
        fr:`Quelles sont les différences entre les applications des luminaires IK ?`, 
        it:`Quali sono le diverse applicazioni degli apparecchi di illuminazione con IK?`,
        zhCN: `IK 燈具應用之間有何差異？`
      },
      txt3:{
        pt: `Tendo em vista que o índice IK avalia a resistência física dos equipamentos LED a impactos mecânicos,`,
        en:`Given that the IK index assesses the physical resistance of LED equipment to mechanical impacts,`, 
        es:`Dado que el índice IK evalúa la resistencia física de los equipos LED a los impactos mecánicos,`,
        fr:`L'indice IK évalue la résistance physique de l'équipement LED aux impacts mécaniques,`, 
        it:`L'indice IK valuta la resistenza fisica delle apparecchiature LED agli impatti meccanici,`,
        zhCN: `鑑於 IK 指數可評估 LED 設備對機械衝擊的物理抵抗力、`
      },

      strongtxt3: {pt:`sua aplicabilidade varia de acordo com o ambiente e as finalidades.`,
        en:`its applicability varies according to the environment and purpose.`, es:`su aplicabilidad varía según el entorno y la finalidad.`,
        fr:`son applicabilité varie en fonction de l'environnement et de l'objectif.`,  it:`la sua applicabilità varia a seconda dell'ambiente e dello scopo.`,
        zhCN: `其適用性因環境和目的而異`},

      txt3:{
        pt: `Por exemplo, em estudo recente, pesquisadores da Universidade de Málaga (Espanha) avaliam que produtos 
      fabricados em policarbonato são ideais para utilização em projetos luminotécnicos urbanos.`,
        en:`For example, in a recent study, researchers from the University of Malaga (Spain) found that products made from polycarbonate are ideal for use in urban lighting projects.`, 
        es:`Por ejemplo, en un estudio reciente, investigadores de la Universidad de Málaga (España) evaluaron que los productos fabricados con policarbonato son ideales para su uso en proyectos de iluminación urbana.`,
        fr:`Par exemple, dans une étude récente, des chercheurs de l'université de Malaga (Espagne) ont estimé que les produits en polycarbonate étaient idéaux pour les projets d'éclairage urbain.`, 
        it:`Ad esempio, in un recente studio, i ricercatori dell'Università di Malaga (Spagna) hanno valutato che i prodotti in policarbonato sono in policarbonato sono ideali per l'utilizzo in progetti di illuminazione urbana.`,
        zhCN: `例如，在最近的一項研究中，馬拉加大學（西班牙）的研究人員發現，由聚碳酸酯製成的產品非常適合用於城市照明專案。`
      },

      txt4:{
        pt: `Sobretudo, por apresentarem boa resistência e índices de IK resistentes a climas adversos e possíveis 
      danos de natureza mecânica.  De todo modo, algumas recomendações são valiosas na hora de avaliar a aplicabilidade 
      de luminárias de diferentes índices de IK.`,
        en:` Above all, because they have good resistance and IK indices that are resistant to adverse climates and possible mechanical damage.  In any case, some recommendations are valuable when assessing the applicability of luminaires with different IK ratings.`, 
        es:`Sobre todo, porque tienen buena resistencia e índices IK resistentes a climas adversos y a posibles daños mecánicos.  En cualquier caso, algunas recomendaciones son valiosas a la hora de evaluar la aplicabilidad de luminarias con diferentes índices IK.`,
        fr:`Avant tout, parce qu'ils ont une bonne résistance et des indices IK qui résistent aux climats défavorables et à d'éventuels dommages mécaniques.  Quoi qu'il en soit, certaines recommandations sont utiles pour évaluer l'applicabilité des luminaires ayant des indices IK différents.`, 
        it:`Soprattutto, perché hanno buoni indici di resistenza e IK resistenti a climi avversi e a possibili danni meccanici. danni meccanici.  In ogni caso, alcune raccomandazioni sono preziose quando si valuta l'applicabilità di di apparecchi di illuminazione con diversi indici IK.`
      , zhCN: `最重要的是，因為它們具有良好的耐性和 IK 指數，可以抵抗惡劣的氣候和可能的機械損壞。 無論如何，在評估具有不同 IK 等級的燈具的適用性時，一些建議是很有價值的。`
      },

      list:[
        {element: {
          pt: `Área interna e externa de risco: alta suscetibilidade a impactos mecânicos, por exemplo, em quadras esportivas ou ambientes industriais;`,
          en:`Indoor and outdoor areas at risk: high susceptibility to mechanical impacts, for example on sports courts or in industrial environments;`, 
          es:`Zonas interiores y exteriores de riesgo: alta susceptibilidad a los impactos mecánicos, por ejemplo en pistas deportivas o en entornos industriales;`,
          fr:`Zones intérieures et extérieures à risque : forte sensibilité aux impacts mécaniques, par exemple sur les terrains de sport ou dans les environnements industriels ;`, 
          it:`Aree interne ed esterne a rischio: elevata suscettibilità agli impatti meccanici, ad esempio nei campi sportivi o negli ambienti industriali;`,
         zhCN: `風險的室內和室外區域：極易受到機械衝擊，例如運動場或工業環境；`
        },},
        {element:{
          pt:`Área interna e externa comercial: suscetibilidade moderada a impactos mecânicos, como áreas comerciais ou de fluxo moderado de pessoas.`,
          en:`Indoor and outdoor commercial areas: moderate susceptibility to mechanical impacts, such as commercial areas or areas with a moderate flow of people.`, 
          es:`Zonas comerciales interiores y exteriores: susceptibilidad moderada a impactos mecánicos, como zonas comerciales o zonas con un flujo moderado de personas.`,
          fr:`Zones commerciales intérieures et extérieures : susceptibilité modérée aux impacts mécaniques, telles que les zones commerciales ou les zones avec un flux modéré de personnes.`, 
          it:`Aree commerciali interne ed esterne: moderata suscettibilità agli impatti meccanici, come le aree commerciali o le aree con un moderato flusso di persone.`,
          zhCN: `室內和室外商業區：中度易受機械衝擊影響，例如商業區或中度人流區。`
        },},
      ],
      },
      {

      title:{
        pt: `Como são realizados os testes de índice de resistência a impacto (IK)?`,
        en:`How are impact resistance index (IK) tests carried out?`, 
        es:`¿Cómo se realizan las pruebas del índice de resistencia al impacto (IK)?`,
        fr:`Comment les tests d'indice de résistance aux chocs (IK) sont-ils effectués ?`, 
        it:`Come vengono eseguiti i test sull'indice di resistenza agli urti (IK)?`,
        zhCN: `如何進行耐衝擊指數 (IK) 測試？`
      },

      txt3:{
        pt: `Todas as faces do produto que podem sofrer um impacto devem ser testadas. Vamos pegar como exemplo uma luminária LED fixada na parede. 
      Todas as partes da carcaça do produto devem passar pelo teste.`,
        en:`Given that the IK index assesses the physical resistance of LED equipment to mechanical impacts,`, 
        es:`Dado que el índice IK evalúa la resistencia física de los equipos LED a los impactos mecánicos,`,
        fr:`Toutes les faces du produit susceptibles de subir un impact doivent être testées. Prenons l'exemple d'un luminaire LED mural. 
      Toutes les parties du boîtier du produit doivent passer le test.`, 
        it:`Devono essere testati tutti i lati del prodotto che potrebbero subire un impatto. Prendiamo ad esempio un apparecchio LED a parete. 
      Tutte le parti dell'alloggiamento del prodotto devono superare il test.`,
      zhCN: `鑑於 IK 指數可評估 LED 設備對機械衝擊的物理抵抗力、`
      },

      text:{
        pt: `Depois de todos os impactos, a luminária LED em questão ainda deve estar totalmente operacional. Isto significa que podem aparecer sinais dos impactos, 
      como amassados, trincas e outros sinais, porém o produto deve atender todas as características de sua especificação técnica. Por exemplo: tudo deve funcionar 
      normalmente, seu grau de proteção IP deve ser o mesmo e as funções elétricas não podem ter sido alteradas`,
        en:`After all the impacts, the LED luminaire in question should still be fully operational. This means that signs of impact may appear, 
      such as dents, cracks and other signs, but the product must meet all the characteristics of its technical specification. For example: everything must work 
      normally, its IP protection rating must be the same and the electrical functions must not have been altered`, 
        es:`Después de todos los impactos, la luminaria LED en cuestión debe seguir siendo plenamente operativa. Esto significa que pueden aparecer signos de impacto 
      como abolladuras, grietas y otros signos, pero el producto debe cumplir todas las características de su especificación técnica. Por ejemplo: todo debe funcionar 
      normalmente, su grado de protección IP debe ser el mismo y las funciones eléctricas no deben haberse alterado`,
        fr:`Après tous les impacts, le luminaire à LED en question devrait encore être pleinement opérationnel. Cela signifie que des signes d'impact peuvent apparaître, 
      comme des bosses, des fissures et d'autres signes, mais le produit doit répondre à toutes les caractéristiques de sa spécification technique. Par exemple : tout doit fonctionner 
      fonctionner normalement, l'indice de protection IP doit être le même et les fonctions électriques ne doivent pas avoir été altérées`, 
        it:`Dopo tutti gli impatti, l'apparecchio LED in questione dovrebbe essere ancora pienamente operativo. Ciò significa che possono comparire segni di impatto, 
      come ammaccature, crepe e altri segni, ma il prodotto deve soddisfare tutte le caratteristiche delle sue specifiche tecniche. Ad esempio: tutto deve funzionare 
      tutto deve funzionare normalmente, il grado di protezione IP deve essere lo stesso e le funzioni elettriche non devono essere state alterate.`,
      zhCN: `經過所有撞擊之後，相關的 LED 燈具應該仍可完全運作。這表示可能會出現撞擊的跡象、 如凹痕、裂紋和其他跡象，但產品必須符合其技術規格的所有特性。例如：所有功能都必須正常運作 正常運作，其 IP 防護等級必須相同，且電氣功能不得有任何改變。`
      },
    
     },
      {
      title:{pt:"Conclusão",en: `Conclusion`, es: `Conclusión`,fr: `Conclusion`,  it:`Conclusione`},
      txt3: {
        pt: `A Advance é especialista em projectos de iluminação LED nos mais variados ambientes. Por isso os índices de IP e Ik são analisados com cuidado
      de acordo com a aplicação e finalidade de projeto de iluminação.`, 
        en: `Advance specializes in LED lighting projects in a wide variety of environments. That's why the IP and Ik indices are carefully analyzed
      according to the application and purpose of the lighting project.`, 
        es: `Advance está especializada en proyectos de iluminación LED en una amplia variedad de entornos. Por ello, los índices IP e Ik se analizan cuidadosamente
      en función de la aplicación y finalidad del proyecto de iluminación.`,
        fr: `Advance est spécialisé dans les projets d'éclairage LED dans une grande variété d'environnements. C'est pourquoi les indices IP et Ik sont analysés avec soin en fonction de l'application et de l'objectif du projet d'éclairage.
      en fonction de l'application et de l'objectif du projet d'éclairage.`, 
        it:`Advance è specializzata in progetti di illuminazione a LED in un'ampia varietà di ambienti. Per questo motivo gli indici IP e Ik vengono analizzati con attenzione
      in base all'applicazione e allo scopo del progetto di illuminazione.`,
       zhCN: `Advance 專精於各種環境下的 LED 照明專案。因此，我們會根據照明專案的應用和目的，仔細分析 IP 和 Ik 指數。`
      }
      },
    ],
    },
    {
      id: 5,
      type: 'news',
      title: {
        pt: 'Como escolher lâmpadas LED?', en: 'How to choose LED bulbs?', 
        es: '¿Cómo elegir las bombillas LED?',
        fr: "Comment choisir les ampoules LED ?", 
        it:"Come scegliere le lampadine LED?",
        zhCN: `如何選擇 LED 燈泡？`
      },
      imgP:  '../images/noticias/6/imgP.jpg',
      descri: {
        pt: `Neste post falaremos sobre os fatores que fazem a diferença naquela dúvida para escolhera melhor para determinado ambiente , para usufruir ao máximo de todas as vantagens da tecnologia LED.`, 
        en: `In this post, we'll talk about the factors that make the difference when it comes to choosing the best one for a particular room, to make the most of all the advantages of LED technology.`, 
        es: `En este post, hablaremos de los factores que marcan la diferencia a la hora de elegir el
      la mejor para una habitación concreta, para aprovechar al máximo todas las ventajas de la tecnología LED.`,
        fr: `Dans cet article, nous aborderons les facteurs qui font la différence lorsqu'il s'agit de choisir la meilleure lampe pour une pièce donnée, afin de profiter au maximum de tous les avantages de la technologie LED.
      la meilleure pour une pièce donnée, afin de profiter au maximum de tous les avantages de la technologie LED.`, 
        it:`In questo post, parleremo dei fattori che fanno la differenza quando si tratta di scegliere il migliore
      per una determinata stanza, per sfruttare al meglio tutti i vantaggi della tecnologia LED.`,
       zhCN: `在這篇文章中，我們將談到為特定房間選擇最佳 LED 時的差異因素，以便充分發揮 LED 技術的所有優點。`
      },
      data: '6/08/2024',
      content:
      [
      { title: {
        pt: `A regulação das lâmpadas`,
        en: `Adjusting light bulbs`, 
        es: `Ajuste de las bombillas`,
        fr: `Réglage des ampoules`, 
        it:`Regolazione delle lampadine`,
        zhCN: `調整燈泡`
      },

      text: {
          pt: `Na maioria dos casos, os reguladores de intensidade das lâmpadas de halogéneo, não são compatíveis com os das lâmpadas LED. 
      Isto acontece porque as lâmpadas incandescentes e de halogéneo têm uma carga muito superior à das lâmpadas LED. Ou seja, como estas
        lâmpadas consomem menos energia, não conseguem atingir a carga mínima necessária para o regulador funcionar corretamente.`,
        en: `In most cases, the dimmers on halogen bulbs are not compatible with those on LED bulbs. 
      This is because incandescent and halogen bulbs have a much higher load than LED bulbs. In other words, as these
        consume less energy, they are unable to reach the minimum load required for the dimmer to work properly.`, 
          es: `En la mayoría de los casos, los reguladores de las bombillas halógenas no son compatibles con los de las bombillas LED. 
      Esto se debe a que las bombillas incandescentes y halógenas tienen una carga mucho mayor que las bombillas LED. En otras palabras, como éstas
        consumen menos energía, no pueden alcanzar la carga mínima necesaria para que el dimmer funcione correctamente.`,
          fr: `Dans la plupart des cas, les variateurs des ampoules halogènes ne sont pas compatibles avec ceux des ampoules LED. 
      En effet, les ampoules incandescentes et halogènes ont une charge beaucoup plus élevée que les ampoules LED. En d'autres termes, comme ces dernières
        consomment moins d'énergie, elles ne peuvent pas atteindre la charge minimale requise pour que le variateur fonctionne correctement.`, 
          it:`Nella maggior parte dei casi, i dimmer delle lampadine alogene non sono compatibili con quelli delle lampadine LED. 
      Questo perché le lampadine a incandescenza e alogene hanno un carico molto più elevato rispetto alle lampadine a LED. In altre parole, poiché queste
        consumano meno energia, non riescono a raggiungere il carico minimo necessario per il corretto funzionamento del dimmer.`
        },
        
      },
      {
    title: {
      pt: `A escolha da temperatura de cor`, en: `The choice of color temperature`,  es: `La elección de la temperatura de color`,fr: `Le choix de la température de couleur`,  it:`La scelta della temperatura di colore`
    },

      text: {
      pt: `Antigamente tínhamos as lâmpadas convencionais que se dividiam em lâmpadas incandescentes e de halogéneo que
      proporcionavam uma luz quente, e as lâmpadas fluorescentes que geravam uma luz fria. Atualmente tempos as lâmpadas 
      LED com variadas hipóteses de temperaturas de cor, o que gera por vezes alguma confusão no momento da compra.`,
      en: `In the past, we had conventional light bulbs, which were divided into incandescent and halogen bulbs that provided a warm light, and
      light, and fluorescent lamps that generated a cold light. Today we have 
      LED light bulbs come in a variety of color temperatures, which sometimes leads to confusion when buying.`, 
      es: ` Antes teníamos bombillas convencionales, que se dividían en incandescentes y halógenas, que proporcionaban luz cálida, y fluorescentes,
      que generaban luz fría. Hoy disponemos de bombillas LED con una amplia gama de temperaturas de color, lo que a veces genera confusión a la hora de comprar.`,
      fr: `Dans le passé, nous avions des ampoules classiques, divisées en ampoules à incandescence et ampoules halogènes qui fournissaient une lumière chaude, 
      et en ampoules fluorescentes qui généraient une lumière froide. Aujourd'hui, nous avons des ampoules LED avec une large gamme de températures de couleur, ce qui entraîne parfois une certaine confusion lors de l'achat.`, 
      it: `In passato esistevano lampadine convenzionali, suddivise in lampadine a incandescenza e alogene che fornivano luce calda e lampadine fluorescenti che generavano luce fredda. 
      Oggi abbiamo lampadine a LED con un'ampia gamma di temperature di colore, il che a volte genera confusione al momento dell'acquisto.`,
      zhCN: `在過去，我們有傳統的燈泡，分為提供暖光的白熾燈泡和鹵素燈泡，以及產生冷光的螢光燈。今天，我們有 LED 燈泡有各種不同的色溫，這有時會讓人在購買時感到困惑。`
      },
      },
      {
      title: {
       pt: `A escolha do ângulo de iluminação`, en: `The choice of lighting angle`,  es: `La elección del ángulo de iluminación`, fr: `Le choix de l'angle d'éclairage`, it: `La scelta dell'angolo di illuminazione`,
       zhCN: `照明角度的選擇` },

      text: {
        pt: `O valor do ângulo de abertura da lâmpada fornece uma importante informação sobre a quantidade de 
      distribuição da luz. Se tivermos duas lâmpadas com a mesma quantidade de luz (lúmens) e uma delas tiver um 
      ângulo de abertura maior, isso quer dizer que esta iluminará mais área, mas com uma intensidade menor. Passamos 
      a indicar-lhe alguns exemplos de área de distribuição consoante o valor do ângulo de abertura:`,
        en: `The value of the lamp's opening angle provides important information about the amount of 
      light distribution. If we have two lamps with the same amount of light (lumens) and one of them has a larger opening angle, this means that it will illuminate more area but with less intensity. 
      angle, this means that it will illuminate more area, but with less intensity. Here are 
      to give you some examples of the distribution area depending on the value of the opening angle:`, 
        es: `El valor del ángulo de apertura de la lámpara proporciona información importante sobre la cantidad de 
      distribución de la luz. Si tenemos dos lámparas con la misma cantidad de luz (lúmenes) y una de ellas tiene un ángulo de apertura mayor, esto significa que iluminará más área, pero con menos intensidad. 
      Si tenemos dos lámparas con la misma cantidad de luz (lúmenes) y una de ellas tiene un ángulo de apertura mayor, significa que iluminará más superficie, pero con menos intensidad. A continuación 
      algunos ejemplos del área de distribución en función del valor del ángulo de apertura:`,
        fr: `La valeur de l'angle d'ouverture de la lampe fournit des informations importantes sur le degré de 
      distribution de la lumière. Si nous avons deux lampes ayant la même quantité de lumière (lumens) et que l'une d'entre elles a un angle d'ouverture plus grand, cela signifie qu'elle éclairera une plus grande surface, mais avec moins d'intensité. 
      Si l'angle d'ouverture est plus grand, cela signifie qu'elle éclairera une plus grande surface, mais avec moins d'intensité. Voici quelques exemples de répartition 
      pour vous donner quelques exemples de la zone de distribution en fonction de la valeur de l'angle d'ouverture:`, 
        it: `Il valore dell'angolo di apertura della lampada fornisce importanti informazioni sulla quantità di luce distribuita. 
      distribuzione della luce. Se abbiamo due lampade con la stessa quantità di luce (lumen) e una di esse ha un angolo di apertura maggiore, significa che illuminerà una superficie maggiore, ma con un'intensità minore. 
      Se abbiamo due lampade con la stessa quantità di luce (lumen) e una delle due ha un angolo di apertura maggiore, significa che illuminerà un'area maggiore, ma con un'intensità minore. Ecco 
      esempi di distribuzione dell'area in funzione del valore dell'angolo di apertura:`,
       zhCN: `燈管開角的值提供了關於光量分配的重要資訊。光分布的重要資訊。如果我們有兩盞光量（流明）相同的燈，其中一盞的開角較大，這表示它會照亮更多的區域，但強度較低。如果我們有兩盞光量（流明）相同的燈，其中一盞的開角較大，這表示它會照亮更多的區域，但強度較低。以下是 舉例說明開啟角度值所造成的分佈區域：`
      },

      list:[
        {element: {
          pt: 'Ângulo de 36º: distribuição de luz por 1,9m', en: "36º angle: light distribution over 1.9m", 
          es: 'Ángulo de 36º: distribución de la luz en 1,9 m',fr: "Angle de 36º : répartition de la lumière sur 1,9 m",
          it:"Angolo di 36º: distribuzione della luce su 1,9 m", zhCN: `36º 角：光線分佈超過 1.9 公尺`
        },},
        {element:{
          pt: 'Ângulo de 60º: distribuição de luz por 3,5m', en: "60º angle: light distribution over 3.5m", 
          es: 'Ángulo de 60º: distribución de la luz en 3,5 m',fr: "Angle de 60º : répartition de la lumière sur 3,5 m",
          it:"Angolo di 60º: distribuzione della luce su 3,5 m", zhCN: `60º 角：光線分佈範圍超過 3.5 公尺`
        }, },
        {element:{
          pt: 'Ângulo de 90º: distribuição de luz por 6m', en: "90° angle: light distribution over 6m", 
          es: 'Ángulo de 90°: distribución de la luz en 6 m',fr: "Angle de 90° : répartition de la lumière sur 6 m", 
          it:"Angolo di 90°: distribuzione della luce su 6 m", zhCN: `90° 角：光線分佈超過 6 公尺`
        },},
      ],    
      },
    ],
    },
    {
      id: 6,
      type: 'recru',
      title: {
        pt: 'Estamos a contratar!', en: "We're hiring!", 
        es: '¡Estamos contratando!',fr: "Nous recrutons !", 
        it:"Stiamo assumendo!", zhCN: `我們正在招募！`
      },
      dispo: false,
      descri: {
        pt: `Junte-se à nossa equipa! \n Procuramos um comissionista para o mercado espanhol com os seguintes requisitos`, 
        en: `Join our team! \n We are looking for a commission agent for the Spanish market with the following requirements`, 
        es: `¡Únete a nuestro equipo! |n Buscamos un comisionista para el mercado español con los siguientes requisitos`,
        fr: `Rejoignez notre équipe ! \nNNous recherchons un agent commissionnaire pour le marché espagnol avec les exigences suivantes`, 
        it:`Unisciti al nostro team! \Cerchiamo un commissionario per il mercato spagnolo con i seguenti requisiti`,
        zhCN: `加入我們的團隊 \我們正在為西班牙市場尋找一名佣金經紀人，要求如下`
      },

      imgP: 'https://advance-rr.com/wp-content/uploads/2023/03/778-2048x2048.jpg',
      data: '08/08/2024',
      perfil:[
      {name:
        {
          pt: 'Residência no Porto', en: "Residence in Porto", 
          es: 'Residencia en Oporto',fr: "Résidence à Porto", 
          it:"Residenza a Porto", zhCN: `波爾圖住宅`
        },},
      {name: {
        pt: 'Experiência na área comercial de iluminação', en: "Experience in lighting sales", 
        es: 'Experiencia en ventas de iluminación',fr: "Expérience dans la vente d'éclairage", 
        it:"Esperienza di vendita nel settore dell'illuminazione", zhCN: `照明销售经验`
      },},
      {name: {
        pt: 'Bons conhecimentos de ferramentas informáticas (Utilizador)', en: "Good knowledge of computer tools (user)", 
        es: 'Buen conocimiento de las herramientas informáticas (usuario)',fr: "Bonne connaissance des outils informatiques (utilisateur)", 
        it:"Buona conoscenza degli strumenti informatici (utente)", zhCN: `熟练掌握计算机工具（用户）`
      },},
      {name: {
        pt: 'Capacidade de organização', en: "Organisational skills", 
        es: 'Capacidad de organización',fr: "Compétences organisationnelles", 
        it:" Capacità organizzative" , zhCN: `组织技能`
      },},
      {name: {
        pt: 'Boa capacidade de comunicação', en: "Good communication skills", 
        es: 'Buenas dotes de comunicación',fr: "Bonnes aptitudes à la communication", 
        it:"Buone capacità di comunicazione" , zhCN: `良好的沟通技巧`
      }},
      {name:{
        pt: 'Dinamismo e ambição', en: "Dynamism and ambition", 
        es: 'Dinamismo y ambición',fr: "Dynamisme et ambition", 
        it:"Dinamismo e ambizione", zhCN: `活力与雄心`
      }},
      {name:{
        pt: 'Disponibilidade imediata', en: "Immediate availability", 
        es: 'Disponibilidad inmediata',fr: "Disponibilité immédiate", 
        it:"Disponibilità immediata", zhCN: `立即可用`
      }},
     ],
     functions:[

      {name: {
        pt: 'Clientes. Pesquisa de mercado e aquisição de novos clientes;', en: "Clients. Market research and acquiring new clients;", 
        es: 'Clientes. Estudios de mercado y captación de nuevos clientes;',fr: "Clients. Études de marché et acquisition de nouveaux clients ;", 
        it:"Clienti. Ricerche di mercato e acquisizione di nuovi clienti;", zhCN: `客户 进行市场调研，争取新客户；`
      },},
      {name: {
        pt: 'Vendas. Distribuir as categorias de produtos marcadas como prioritárias aos clientes', en: "Sales. Distributing prioritised product categories to customers", 
        es: 'Ventas. Distribución a los clientes de las categorías de productos priorizadas',fr: "Ventes. Distribution aux clients des catégories de produits classées par ordre de priorité", 
        it:"Vendite. Distribuzione ai clienti delle categorie di prodotti prioritari" ,zhCN: `销售 向客户分发优先产品类别`
      }},
      {name:{
        pt: 'Apresentação do produto', en: "Product presentation", 
        es: 'Presentación de productos.',fr: "Présentation des produits", 
        it:"Presentazione dei prodotti", zhCN: `产品介绍`
      }},
      {name:{
        pt: 'Promoção. Novos produtos, lançamentos de produtos e ofertas', en: "Promotion. New products, product launches and offers", 
        es: 'Promoción. Nuevos productos, lanzamiento de productos y ofertas',fr: "Promotion. Nouveaux produits, lancements de produits et offres", 
        it:"Promozione. Nuovi prodotti, lancio di prodotti e offerte" , zhCN: `促销。新产品、产品发布和优惠`
      }},
     ],
     offers:[
      {name: {
        pt: 'Comissões e incentivos acima da média;', en: "Above-average commissions and incentives;", 
        es: 'Comisiones e incentivos por encima de la media;',fr: "Commissions et incitations supérieures à la moyenne ;", 
        it:"Provvigioni e incentivi superiori alla media;" , zhCN: `高于平均水平的佣金和奖励；`
      }},
      {name:{
        pt: 'Apoio e acompanhamento comercial e administrativo;', en: "Commercial and administrative support;", 
        es: 'Apoyo comercial y administrativo;',fr: "Soutien commercial et administratif ;", 
        it:"Supporto commerciale e amministrativo;" , zhCN: `商务和行政支持`
      }},
      {name:{
        pt: 'Orientados para trabalhar num contexto de comissões a 100%;', en: "Aimed at working in a 100 per cent commission environment;", 
        es: 'Dirigido a trabajar en un entorno de comisiones del 100%;',fr:"Vise à travailler dans un environnement où les commissions sont de 100 % ;", 
        it:"Obiettivo: lavorare in un ambiente con commissioni al 100%;", zhCN: `目标是在 100% 佣金的环境中工作`
      }},
     ],
    }
  ];