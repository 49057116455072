import React, { useEffect, useState, useRef, useCallback} from 'react' 
import { Link, Route, Routes } from 'react-router-dom'
import $ from 'jquery'
import  '../layout/ProdDetail.Module.css'
import  '../layout/Loading.Module.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useParams} from 'react-router-dom';
import Slider from "react-slick";
import { prods } from '../prods'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import {tabela, material, acabamento, ip } from '../legenda'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';

const ProdDetail =({props})=> {
  const { user, logout } = useAuth();

  const { t, i18n } = useTranslation('');

  const navigate = useNavigate();
  const GotToNewPage =(id)=>{
    navigate(`/prodetail/${id}`);
  }

  const [mainImage, setMainImage] = useState('');
    let {id} = useParams();

    const [acessorios, setAcessorios] = useState([]);
    useEffect(() => {
      fetch(`https://api-murex-theta.vercel.app/acessorios/${id}`)
        .then(res => res.json())
        .then(res => {
          console.log("Dados recebidos: ", res);
          if (Array.isArray(res)) {
            setAcessorios(res);
          } else if (typeof res === 'object' && res !== null) {
            setAcessorios([res]);
          } else {
            console.log("Nenhum acessório encontrado ou estrutura inesperada");
            setAcessorios([]);
          }
        })
        .catch(err => console.log(err));
    }, [id]);  // Atualize para depender do id

    
    const [acessoriosSubC, setAcessoSub] = useState([]);
    useEffect(() => {
      fetch(`https://api-murex-theta.vercel.app/acessoriosSub/${id}`)
      .then(res => res.json())
      .then(res => {
        console.log("Dados recebidos: ", res);
        if (Array.isArray(res)) {
          setAcessoSub(res);
        } else if (typeof res === 'object' && res !== null) {
          setAcessoSub([res]);
        } else {
          console.log("Nenhum acessório encontrado ou estrutura inesperada");
          setAcessoSub([]);
        }
      })

      .catch(err => console.log(err));
    }, [id]); 

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    /************Lista de produtos************/
    useEffect(() => {
      fetch('https://product-list-ten-drab.vercel.app/products')
      .then(res => res.json())
      .then(prod => {
        setProducts(Array.isArray(prod) ? prod : []);
      })
      .catch(err => console.log(err));
    }, [products]);

    /*Chamar api que chama um produto pelo Id requisitado*/
    const [product, setProduct] = useState(null);


    useEffect(() => {
      const cachedProduct = localStorage.getItem(`product-${id}`);
      
      if (cachedProduct) {
        setProduct(JSON.parse(cachedProduct));
        setMainImage(JSON.parse(cachedProduct).imgP);
        setLoading(false);
      } else {
        axios.get(`https://backend-theta-gold-17.vercel.app/api/products/${id}`, { timeout: 10000 })
          .then(response => {
            if (response.data) {
              setProduct(response.data);
              setMainImage(response.data.imgP);
              localStorage.setItem(`product-${id}`, JSON.stringify(response.data)); // Salva no cache
            } else {
              setError("Produto não encontrado");
            }
            setLoading(false);
          })
          .catch(error => {
            const errorMessage = error.response?.data?.error || "Erro ao buscar produto";
            setError(errorMessage);
            setLoading(false);
          });
      }
    }, [id]);

    const [especifi, setEspecifi] = useState([]);
    const [pdfUrl, setPdfUrl] = useState([]); 
    useEffect(() => {
      axios.get(`https://product-list-ten-drab.vercel.app/api/references/${id}`)
        .then(response => {
          setEspecifi(response.data);
        const pdfItem = response.data.find(item => item.pdf);

        if (pdfItem && pdfItem.pdf) {
          // Converte o Buffer em um Blob
          const pdfBlob = new Blob([new Uint8Array(pdfItem.pdf.data)], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
          setPdfUrl(pdfUrl); // Define a URL do PDF
        }

        console.log(response.data);
        })
        .catch(error => {
        });
    }, [id]);

    var settings = {
      autoplay: true,
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      speed: 1000,
      autoplaySpeed: 4000,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const itensFiltrados = especifi.filter(item => item.idP == id);
    
    var recomen = {
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll:4,
      initialSlide: 0,
      speed: 1000,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
          }
        }
      ]
    };

    var slideImgs = {
      autoplay: false,
      dots: false,
      arrows: false,
      infinite: false,
      slidesToShow: 4, // Mostra até 4 imagens ou o número disponível, mas garante que seja pelo menos 1
      slidesToScroll: 4, // Apenas uma imagem por vez para evitar aumento
      speed: 500,
      initialSlide: 0,
      speed: 1000,
      autoplaySpeed: 6000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
          }
        }
      ]
    };

    useEffect(() => {
      const slickTrack = document.querySelector('.slick-track');
      if (itensFiltrados.length < 4 && slickTrack) {
        slickTrack.style.transform = 'translate3d(0px, 0px, 0px)'; // Mover o track para o início
        slickTrack.classList.add('float-left-important'); // Adiciona a classe
      } else if (slickTrack) {
        slickTrack.classList.remove('float-left-important'); // Remove a classe se não for mais necessário
      }
    }, [itensFiltrados.length]);
  {/*Image zoom on hover*/}
    {/*Replace for images of references */}
   const imgsSlide = [
      { imgSlide:'https://ik.imagekit.io/advance/produtos/Fitas%20profissionais/fita%20led.png?updatedAt=1725892075173'},
      { imgSlide:'https://ik.imagekit.io/advance/produtos/Lumi%20publi/37409.png?updatedAt=1725377463138'},
      { imgSlide:'https://ik.imagekit.io/advance/produtos/Interruptor/VL-FC1Z-2G.png?updatedAt=1725620602887'},
    ]

    if (loading) {
      return  <>
       <style>{'body { background-color: var(--logo-azul); }'}</style>
      <div className="containe" >
        <div className="box loading">
      <div className="content">
        <h1>Carregando</h1>
        <div className="content">
          <div className="plug-wire"></div>
          <div className="plug-case">
            <div className="pin pin-1"></div>
            <div className="pin pin-2"></div>
          </div>
        <div className="socket"><i className="fa fa-bolt fa-1x"></i>
          <div className="socket-wire"></div>
          </div>
    
        </div>
      </div>
    </div>
    </div>
      </>
    }
    if (error) {
      return <p>Error: {error}</p>;
    }
    if (!product) {
      return <p>Product not found</p>;
    }

    const translatedCategory = t(`categorias.${product?.nomeC}`) || product?.nomeC; 

    /*
    useEffect(() => {
      axios.get(`https://api-murex-theta.vercel.app/api/products/${id}`)
        .then(response => {
          setProduct(response.data);
          setLoading(false);
          setMainImage(response.data.imgP)
        })
        .catch(error => {
        setError(error.message);
        setLoading(false);
        });
    }, [id]);
*/
  return (
  <div className='Prodetail'>
    <>  
  {/* Hello world */}
  <div className="container">
        <nav aria-label="breadcrumb" className="breadcrumbs medium-font">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/"role="button" tabIndex="0" className='text-capitalize'>Home</Link></li>
          <li className="breadcrumb-item"><Link to="/produtos" className='text-capitalize'>{t('homepage.produtos')}</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{translatedCategory}</li>
        </ol>
        </nav>
        <div className="row prod-box">
            <div className="col-lg-5">
              <div className="row">
                <div className=" col-lg-12 main-image">
                  {mainImage ? (
                   <InnerImageZoom src={mainImage} zoomScale={1.5} zoomType="hover" hasSpacer={false} fullscreenOnMobile={false}// Remove o espaçamento interno
                   alt="" className="img-thumbnail p-0 border-0"/>
                  ) : (
                    <p>Carregando...</p>
                  )}
                  {(product?.novo == 1) && (
                   <div className="prod-top-tags"><div className={`prod-info prod-new`}>Novo</div></div> 
                  )}
                </div>
                {/*Inserir aqui as imagens por referência*/}
                <div className='slider-carousel'>
                <Slider {...slideImgs}>
                <img src={product.imgP} onClick={() => setMainImage(product.imgP)} alt={product.nome} className="img-thumbnail p-0 border-0" />
                {itensFiltrados.map((img, index) => (
                  img.img ? ( // Verifica se img.img existe e não está vazio
                    <div className="img-item" key={index}>
                      <img  alt="" className="img-thumbnail p-0 border-0" src={img.img} onClick={() => setMainImage(img.img)} />
                    </div>
                  ) : null // Se img.img estiver vazio, não renderiza nada
                ))}
                </Slider>
                </div>
              </div>
            </div>
            <div className="col-lg-7 info-prod">
              <div className="card product-info">
                <div className="card-body">
                  <h1 className='product-title'>{product?.nome}</h1>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item logo-prod">
                      <div className='brand-img'>
                      <img src={product?.logo}/>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className='prod-info-title'>
                      {t('pdetail.caraGerais')}:
                      </div>
                    </li>

                    <li className="list-group-item info-geral">
                      <p> {t('pdetail.tipo')}: <b>{translatedCategory}</b></p>
                    </li>

                    {(product?.ip != null) && (
                       <li className="list-group-item info-geral">
                        <p>IP:<b> {product.ip}</b></p>
                      </li>
                    )}
                   
                    {(product?.cri != null) && (
                     <li className="list-group-item info-geral">
                      <p>CRI:<b> {product.cri}</b></p>
                    </li>)}

                    {(product?.volt != null) && 
                    (<li className="list-group-item info-geral">
                    <p>{t('pdetail.poten')}:<b> {product.volt}V</b></p>
                    </li>)}

                    {(product?.ik != null) && 
                    (<li className="list-group-item info-geral">
                    <p>IK:<b> {product.ik}</b></p>
                    </li>)}

                   {(product?.ac != null) && 
                    (<li className="list-group-item info-geral">
                    <p>AC:<b> {product.ac}V</b></p>
                    </li> )}

                   {(product?.dc != null) && 
                    (<li className="list-group-item info-geral">
                    <p>DC:<b> {product.dc}V</b></p>
                    </li>)}

                   {(product?.pcb != null) && 
                    (<li className="list-group-item info-geral">
                    <p>PCB:<b> {product.pcb} OZ</b></p>
                    </li>)} 

                   {(product?.dim != null) && 
                    (<li className="list-group-item info-geral">
                    <p>Dimensões:<b> {product.dim} </b></p>
                    </li>)} 

                    {(product?.tempe != null) && 
                    (<li className="list-group-item info-geral">
                    <p>Temperatura:<b> {product.tempe} </b></p>
                    </li>)} 

                   {(product?.ugr != null) && 
                    (<li className="list-group-item info-geral">
                    <p>UGR:<b> {product.ugr} </b></p>
                    </li>)} 

                  {(product?.ampere != null) && 
                    (<li className="list-group-item info-geral">
                    <p>A:<b> {product.ampere} </b></p>
                  </li>)} 
                    
                    {(product?.descri != null) && 
                    (
                      <div className='prod-info'>
                      <div className='prod-descri-title'>{t('pdetail.descri')}</div>
                        <p>{product?.descri}</p>
                      </div>
                    )} 
                    
                    <div className='box-recomen'>
                      <div className='prod-recomen'>{t('pdetail.prodRe')}</div>
                  <Slider {...recomen}>
                  {products.map((resu,index)=>
                  (resu.nomeC === product.nomeC) &&
                  ( 
                    <div className="recomen-card" key={index} onClick={() => GotToNewPage(resu.idP)}>

                    <div className="shop__thumb">

                    <div className="shop-thumb__img">
                      <img src={resu.imgP} className="img-responsive" alt="..." />
                    </div>
                    <h3 className="shop-thumb__title pt-2">{resu.nome}</h3>
                    </div>
                  </div>
                  ))}
                  </Slider>
                  
                    </div>
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>

    <div className="container technical-details">
    <p className="tech-title">{t('pdetail.espeTecni')}</p>
    
<Table striped bordered hover>
    {/* Renderizar a tabela apenas se houver itens filtrados */}
    {itensFiltrados.length > 0 && (
      <>
        <thead>
          <tr>
            <th>Ref</th>
            {/* Exibir o cabeçalho condicionalmente com base no primeiro item filtrado */}
            {itensFiltrados[0]?.w != null && <th>Watt</th>}
            {itensFiltrados[0]?.led != null && <th>Led</th>}
            {itensFiltrados[0]?.furo != null && <th>{t('pdetail.furo')}</th>}
            {itensFiltrados[0]?.descri != null && <th>{t('pdetail.descritb')}</th>}
            {itensFiltrados[0]?.cor != null && <th> {t('pdetail.cor')}</th>}
            {itensFiltrados[0]?.mat != null && <th>{t('pdetail.mate')} </th>}
            {itensFiltrados[0]?.k != null && <th>K</th>}
            {itensFiltrados[0]?.lm != null && <th>LM</th>}
            {itensFiltrados[0]?.corte != null && <th>{t('pdetail.corte')}</th>}
            {itensFiltrados[0]?.medida != null && <th>{t('pdetail.medida')}(mm)</th>}
            {itensFiltrados[0]?.angulo != null && <th>{t('pdetail.angulo')}</th>}
            {itensFiltrados[0]?.qtcx != null && <th>QT/CX</th>}
            {itensFiltrados[0]?.pdf != null && <th>{t('pdetail.ft')}</th>}
            <th>PVP</th>
            {/*   {user ? (<th>PVP</th>) : ""}*/}
          </tr>
        </thead>
        <tbody>
          {/* Renderizar as linhas da tabela com base nos itens filtrados */}
          {itensFiltrados.map((item, index) => (
            <tr key={index}>
              <td data-label="referência"><b>{item.ref}</b></td>
              {item?.w != null && <td data-label="watt">{item.w}W</td>}
              {item?.led != null && <td data-label="led">{item.led}</td>}
              {item?.furo != null && <td data-label="furo">{item.furo}</td>}
              {item?.descri != null && <td data-label="descri">{item.descri}</td>}
              {item?.cor != null && <td data-label="cor">{item.cor}</td>}
              {item?.mat != null && <td data-label="mat">{item.mat}</td>}
              {item?.k != null && <td data-label="k" className="text-uppercase">{item.k}</td>}
              {item?.lm != null && <td data-label="lm">{item.lm}lm/W</td>}
              {item?.corte != null && <td data-label="corte">{item.corte}mm</td>}
              {item?.medida != null && <td data-label="medida">{item.medida}</td>}
              {item?.angulo != null && <td data-label="angulo">{item.angulo}°</td>}
              {item?.qtcx != null && <td data-label="qtcx">{item.qtcx}</td>}
              {item.pdf != null ? (
                <td>
                { // Crie a URL do PDF para cada item
                (() => {
                const pdfBlob = new Blob([new Uint8Array(item.pdf.data)], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                return <button className='button button-download'><a href={pdfUrl} target="_blank" rel="noopener noreferrer" className='download-ft'>{t('pdetail.vficha')}</a></button>;
                })()}
                </td>) : null}
              
              <td data-label="pvp">
                {item.pvp !== "*" && item.pvp.toLowerCase() !== "sob-consulta" ? `${item.pvp} €` : item.pvp}
              </td>

              {/*{user ? (<td data-label="pvp">{item.pvp ? `${parseFloat(item.pvp).toFixed(2)}€` : 'N/A'}</td>) : ('')}*/}
            </tr>
            ))}
        </tbody>
      </>
    )}
</Table>

{/* <h6>{t('pdetail.textqtd')}</h6>*/}
 {itensFiltrados.some(item => String(item.qtcx).includes('*')) && (
    <div style={{ color: 'var(--logo-bege)', fontWeight: 'bold', marginBottom: '10px' }}>
      {(product?.nomeC == "Lâmpadas") && (t('pdetail.qtcx'))}
      {(product?.nomeC == "Ligadores") && ('*'+t('pdetail.msg2'))}
      {(product?.nomeC == "Suportes") && ('*'+t('pdetail.msg3'))}
    </div>
  )}

{itensFiltrados.some(item => String(item.pvp).includes('*')) && (
    <div style={{ color: 'var(--logo-bege)', fontWeight: 'bold', marginBottom: '10px' }}>* Stock e preço sob-consulta.</div>
  )}

  </div>

  {acessorios && acessorios.length? (
         <div className="container recomented-prods">
         <div className="row mb-5">
               <div className="slider-container">
                <h5 className='title-related'>{t('pdetail.acesso')}</h5> 
                {acessorios.map((item, index) =>  (
                  <div className="col-sm-6 col-md-3" key={index}>
                    <div className="shop__thumb">
                     <div className="shop-thumb__img">
                         <img src={item.imgP} className="img-responsive" alt="..." />
                     </div>
                     <span key={item.idP} onClick={() => GotToNewPage(item.idP)}>
                       <div className='product_reference'></div>
                       <h3 className="shop-thumb__title"> <a href=''>{item.nome}</a></h3>
                       {(item?.ip != null) && ( <span className="shop-thumb-brand">IP: {item.ip}</span> )}
                       <div className="c-product-card__stock">
                         <p className="text-sm ">
                         <span className='text-uppercase '>{item.marca}</span>
                         </p>
                       </div>
                     </span>
                     </div>
                   </div> 
                ))}
               </div>
         </div>
       </div>
    ):""}

    <div className="container recomented-prods">
      <div className="row mb-5">
            {/*Produtos pela mesma categoria */}
          <div className="slider-container mt-5">
          <h5 className='title-related'>{t('pdetail.tbmGostar')}</h5>
          <Slider {...recomen}>
          {products.filter((item) => (item.marca === product.marca) && (item.idP !== product.idP)).sort((a, b) => a.nome.localeCompare(b.nome))
          .map((item,index)=>(
           <div className="col-sm-6 col-md-3" key={index}>
           <div className="shop__thumb">
           <div className="shop-thumb__img">
               <img src={item.imgP} className="img-responsive" alt="..." />
           </div>
           <span key={item.idP} onClick={() => GotToNewPage(item.idP)}>
             <div className='product_reference'></div>
             <h3 className="shop-thumb__title"> <a href=''>{item.nome}</a></h3>
             {(item?.ip != null) && ( <span className="shop-thumb-brand">IP: {item.ip}</span> )}
             <div className="c-product-card__stock">
               <p className="text-sm ">
                 <span className='text-uppercase '>{item.marca}</span>
               </p>
             </div>
           </span>
           </div>
         </div>
            ))}
          </Slider>
          </div>
      </div>
    </div>

{acessoriosSubC && acessoriosSubC.length? (
         <div className="container recomented-prods">
         <div className="row mb-5">
               <div className="slider-container">
               <h5 className='title-related'>{t('pdetail.aceSub')}</h5>
              {acessoriosSubC.map((item, index) =>  
              (
                <div className="col-sm-6 col-md-3" key={index}>
                  <div className="shop__thumb">
                  <div className="shop-thumb__img">
                    <img src={item.imgP} className="img-responsive" alt="..." />
                  </div>
                  <span key={item.idP} onClick={() => GotToNewPage(item.idP)}>
                    <div className='product_reference'></div>
                    <h3 className="shop-thumb__title"> <a href=''>{item.nome}</a></h3>
                    {(item?.ip != null) && ( <span className="shop-thumb-brand">IP: {item.ip}</span> )}
                  </span>
                  </div>
                </div>
              ))}
              </div>
         </div>
       </div>
    ):""}
        

<div className="container legend">

  <h2 className="section-title">{t('pdetail.legenda')}</h2>

  <h3 className="subtitle">{t('pdetail.tabela')}</h3>
  <div className="services-inner">
  {tabela.map((item,index)=> (
        <div className="services-box" key={index}>
          <span className="icon">
            <p>{item.sigla}</p>
          </span>
          <h3 className="services-title">{item.nome[i18n.language]}</h3>
        </div>
      ))}
  </div>

   <h3 className="subtitle">{t('pdetail.mate')}</h3>
    <div className="services-inner">
    {material.map((item,index)=> (
          <div className="services-box" key={index}>
            <span className="icon">
              <p>{item.sigla}</p>
            </span>
            <h3 className="services-title">{item.nome[i18n.language]}</h3>
          </div>
        ))}
    </div>

    <h3 className="subtitle">{t('produtos.ip')}</h3>
    <div className="services-inner">
    {ip.map((item,index)=> (
          <div className="services-box" key={index}>
            <span className="icon">
              <p>{item.sigla}</p>
            </span>
            <h3 className="services-title">{item.nome[i18n.language]}</h3>
          </div>
        ))}
    </div>

    <h3 className="subtitle">{t('pdetail.acaba')}</h3>
    <div className="services-inner">
    {acabamento.map((item,index)=> (
          <div className="services-box" key={index}>
            <span className="icon">
              <p>{item.sigla}</p>
            </span>
            <h3 className="services-title">{item.nome[i18n.language]}</h3>
          </div>
        ))}
    </div>

</div>
    
    </>
  </div>

  )
}

export default ProdDetail
