import React, { useEffect, useState, useRef} from 'react' 
import { useParams, useLoaderData } from 'react-router-dom';
import { Link, Route, Routes } from 'react-router-dom'
import '../layout/Noticia.Module.css'
import { news } from '../noticias';
import $ from 'jquery';
import emailjs from '@emailjs/browser'
import { useTranslation } from 'react-i18next';
const Noticia =({data}) =>{
    let {id} = useParams();
    const noti = news.find((n)=>n.id == id);
    const filtered = news.filter((n)=>n.id != id)

const { t, i18n } = useTranslation('');


const form = useRef();

const [name, setName] = useState('')
const [tele, seTele] = useState('')
const [email, setEmail] = useState('')
const [sector, setSector] = useState('')
const [city, setCity] = useState('')
const [address, setAddress] = useState('')
const [cp, setCp] = useState('')
const [cv, setCv] = useState('')
const [file, setFile] = useState(null);

const handleFileChange = (e) => {
  setFile(e.target.files[0]);
};

const handleDownload = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_u0tl3ep', 'template_zoin3m9', form.current, 'q9GuAywOCrOMlKxC4')
    .then(
      () => {
        alert('Candidatura enviada com sucesso!');
        console.log('SUCCESS!');
      },
      (error) => {
        console.error('Falha ao enviar o formulário:', error);
      }
    );
};

const handleLinkChange = (e) => {
  const url = e.target.value;

  // Exemplo de verificação: URL válida e termina com .pdf
  const pdfPattern = /^https?:\/\/.*\.pdf$/i;
  if (!pdfPattern.test(url)) {
    alert("Por favor, insira um link válido para um arquivo PDF.");
  } else {
    // Armazene o link ou envie para o backend conforme necessário
    console.log("Link do currículo:", url);
  }
};

const [error, setError] = useState('')

  return (
        <div className='pag-noticia'>
        <div className="container">
            
        <div className="row">
          <main className='col-md-10 col-lg-8 col-xxl-7'>
            <div className="header-block">
                <div className="title">
                <h1 className="title__name">{noti.title[i18n.language]}</h1>
                </div>
                <ul className="list-inline list-inline-dashed">
                  <li>{noti.data}</li>
                  <li className="font-italic"><Link to={`/noticias/`}>{t('homepage.titleNoticias')}</Link></li>
                </ul>
            </div>

            <div className="content">
            <div className='descri-news'>{noti.descri[i18n.language]}</div>
                <img src={noti.imgP}/>
                <br /><br />
              {noti.content?.map((item, index) => (
                <div className="inset-lg-right-35 inset-xxl-right-0 content-text" key={index}>
                    <h4 key={index}>{item?.title?.[i18n.language] || ''}</h4>
                    {item?.subtitle && <h6 key={index}>{item.subtitle}</h6>}
                    {item?.text1 && <p className='text1'>{item.text1?.[i18n.language] || ''}</p>}
                    {item?.text2 && <p className='text2'>{item.text2?.[i18n.language] || ''}</p>}
                    {item?.txt3 && (
                      <p>
                        {item.txt3?.[i18n.language] || ''} 
                        <strong>{item.strongtxt3?.[i18n.language] || ''}</strong>
                      </p>
                    )}
                    {item?.txt4 && <p>{item.txt4?.[i18n.language] || ''}</p>}
                    {item?.text && <p>{item.text?.[i18n.language] || ''}</p>}
                    {item?.img2 && <img src={item.img2} className='image2-news' alt="" />}
                    {item?.listTitle && <h4 className='list-title'>{item.listTitle?.[i18n.language] || ''}</h4>}
                    {item?.list && (
                      <ul className='list-news'>
                        {item.list.map((listItem, listIndex) => (
                          <li className='items-news' key={listIndex}>{listItem.element?.[i18n.language] || ''}</li>
                        ))}
                      </ul>
                    )}
                    {item?.img1 && <img src={item.img1} alt="" />}
                </div>
              ))}
                          <div className='recru-info'>
                {(noti.type == 'recru') && (<div className="recru-title fw-bold">{t('noticia.perfil')}</div>)}
                {noti.perfil?.map((pr,index)=>(
                    <div className='recru' key={index}><li>-{pr.name[i18n.language]}</li></div>
                ))}
                 {( noti.type == 'recru') && (<div className="recru-title fw-bold">{t('noticia.funcoes')}</div>)}
                {noti.functions?.map((pr,index)=>(
                    <div className='recru' key={index}><li>-{pr.name[i18n.language]}</li></div>
                ))}
                 {( noti.type == 'recru') && (<div className="recru-title  fw-bold">{t('noticia.ofere')}</div>)}
                 {noti.offers?.map((pr,index)=>(
                    <div className='recru' key={index}><li>-{pr.name[i18n.language]}</li></div>
                ))}
              </div>
            </div>

            </main>
        <div className="col-md-10 col-lg-4 more-news">
        <hr className="divider hr-left-0 bg-bermuda"></hr>
        <h3>Outras notícias</h3>

        {filtered.map((item, index)=>(
        <Link to={`/noticia/${item.id}`} className='other-news' key={index}>
         <li className={`news-card`}>
            <div className="article">
            <div className="article-left">
                <img src={item.imgP} alt="" />
            </div>
            <div className="article-right">
                <h2>{item.title[i18n.language]}</h2>
            </div>
            </div>
         </li>
         </Link>
         ))}
        </div>
     </div>
    </div>

    {(noti.type == 'recru') && (
      <div className="recruitment-form">
        <div className="apply_box">
        <h1>CANDIDATURA</h1>
        
        <div className="uvc-heading-spacer line_only">
          <span className="uvc-headings-line" />
        </div>

        <form ref={form} className='form-download row g-3 needs-validation' onSubmit={handleDownload} method='POST'>
          <div className="form_container">

            <div className="form_control">
              <label htmlFor="first name">{t('recru.nome')} *</label>
              <input id="first name" value={name} name="from_name" onChange={(e) => setName(e.target.value)} placeholder="Enter name" required/>
            </div>

            <div className="form_control">
              <label htmlFor="phone">{t('recru.tele')}*</label>
              <input type="text" value={tele} name="from_phone" onChange={(e) => seTele(e.target.value)} id="phone" placeholder="phone" />
            </div>

            <div className="form_control">
              <label htmlFor="email">Email *</label>
              <input  id="email" type="email" name="from_email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="john@doe.com" />
            </div>

            <div className="form_control">
              <label htmlFor="job">{t('recru.setor')}</label>
              <select id="job" value={sector} name="from_sector" onChange={(e) => setSector(e.target.value)}>
                <option value="">{t('recru.setorp')} </option>
                <option value="Marketing">{t('recru.marke')}</option>
                <option value="Recursos humanos">{t('recru.rh')}</option>
                <option value="Armazém">{t('recru.ar')} </option>
                <option value="Admnistração">{t('recru.admi')}</option>
                <option value="Comercial">{t('recru.co')}</option>
                <option value="Finanças">{t('recru.fi')}</option>
                <option value="Outros">{t('recru.outros')}</option>(e A PESSOAL ESCREVER O QUE É)
              </select>
            </div>

            <div className="textarea_control">
              <label htmlFor="address">{t('recru.mo')}</label>
              <textarea cols="50" id="address" name="from_address" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Rua X"  row="4" />
            </div>
            <div className="form_control">
              <label htmlFor="city">{t('recru.cidade')}</label>
              <input id="city" name="from_city" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Porto"/>
            </div>
            <div className="form_control">
              <label htmlFor="postalcode">{t('recru.capacidad')}</label>
              <input id="postalcode" name='from_cp' value={cp} onChange={(e) => setCp(e.target.value)} placeholder="XXXX-XXX" type="text"/>
            </div>
            <div className="form_control">
              <label htmlFor="cv">{t('recru.cv')}</label>
              <input id="cv" name='from_cv' value={cv} onChange={(e) => setCv(e.target.value)} placeholder={t('recru.inserircv')} type="url"/>
            </div>

          </div>
          <div className="button_container">
            <button className='submit' type="submit">{t('recru.candida')}</button>
          </div>
        </form>

      </div>
    </div>)} 


    </div>
  )
}

export default Noticia
