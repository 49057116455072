export const tabela =[
    {
        sigla: 'Ref',
        nome:{
            pt: 'Referência', en: 'Referência', es: 'Referencia',fr: 'Référence', it:'Riferimento', zhCN: "代码"
        },
    },
    {
        sigla: 'W',
        nome: {
            pt: 'Potência', en: 'Power', es: 'Potencia',fr: 'Puissance', it:'Potenza', zhCN: "代码"
        },
    },
    {
        sigla: 'K',
        nome: {
            pt: 'Temperatura de cor de luz', en: 'Light colour temperature', 
            es: 'Temperatura de color de la luz',fr: 'Température de couleur de la lumière', it:'Temperatura di colore della luce', zhCN: "灯光色温"
        },
    },
    {
        sigla: 'V',
        nome: {
            pt: 'Voltagem', en: 'Voltage', 
            es: 'Tensión',fr: 'Tension', it:'Tensione', zhCN: "电压"
        },
    },
    {
        sigla: 'LM',
        nome: {
            pt: 'Fluxo Luminoso', en: 'Luminous flux', 
            es: 'Flujo luminoso',fr: 'Flux lumineux', it:'Flusso luminoso', zhCN: "光通量"
        },
    },
    {
        sigla: 'CRI',
        nome: {
            pt: 'Índice de reprodução cromática', en: 'Colour rendering index', 
            es: 'Índice de reproducción cromática',fr: 'Indice de rendu des couleurs', it:'Indice di resa cromatica', zhCN: "顯色指數"
        },
    },
    {
        sigla: 'AC',
        nome: {
            pt: 'Corrente alternada', en: 'Alternating current', 
            es: 'Corriente alterna',fr: 'Courant alternatif', it:'Corrente alternata', zhCN: "交流电"
        },
    },
    {
        sigla: 'DC',
        nome: {
            pt: 'Corrente contínua', en: 'Direct current', 
            es: 'Corriente continua',fr: 'Courant continu', it:'Corrente continua', zhCN: "直流电"
        },
    },
    {
        sigla: 'Furo',
        nome:  {
            pt: 'Furo', en: 'Hole', 
            es: 'Agujero',fr: 'Trou', it:'Foro', zhCN: "孔洞"
        },
    },
    {
        sigla: 'Â',
        nome:  {
            pt: 'Ângulo de feixe de de luz', en: 'Light beam angle', 
            es: 'Ángulo del haz luminoso',fr: 'Angle du faisceau lumineux', it:'Angolo del fascio di luce', zhCN: "光束角度"
        },
    },
    {
        sigla: 'H',
        nome: {
            pt: 'Autonomia', en: 'Autonomy', 
            es: 'Autonomía',fr: "L'autonomie", it:'Autonomia', zhCN: "自主性"
        },
    },
    {
        sigla: 'QT/CX',
        nome: {
            pt: 'Quantidade por caixa', en: 'Quantity per box', es: 'Cantidad por caja',
            fr: "Quantité par boîte", it:'Quantità per scatola', zhCN: "每盒数量"
        },
    },
    {
        sigla: 'UGR',
        nome: {
            pt: 'Índice de Brilho Unificado', en: 'Unified Brightness Index', es: 'Índice de luminosidad unificado',
            fr: "Indice de luminosité unifié", it:'Indice di luminosità unificato', zhCN: "统一亮度指数"
        },
    },
]

export const material =[
    {
        sigla: 'ALUM',
        nome: {
            pt: 'Alumínio', en: 'Aluminium', es: 'Aluminio',
            fr: "Aluminium", it:'Alluminio', zhCN: "铝"
        },
    },
    {
        sigla: 'INOX',
        nome: {
            pt: 'Inox', en: 'Stainless steel', es: 'Acero inoxidable'
            ,fr: "Acier inoxydable", it:'Acciaio inox', zhCN: "氧化物"
        },
    },
    {
        sigla: 'MT',
        nome: {
            pt: 'Metal', en: 'Metal', es: 'Metal',
            fr: "Métal", it:'Metallo' , zhCN: "金属"
        },
    },
    {
        sigla: 'SIL',
        nome: {
            pt: 'Silicone', en: 'Silicone', es: 'Silicona',
            fr: "Silicone", it:'Silicone', zhCN: "硅胶"
        },
    },
    {
        sigla: 'VD',
        nome: {
            pt: 'Vidro', en: 'Glass', es: 'Vidrio',
            fr: "Verre", it:'Vetro', zhCN: "玻璃"
        },
    },
    {
        sigla: 'ACR',
        nome: {
            pt: 'Acrílico', en: 'Acrylic', 
            es: 'Acrílico',fr: "Acrylique", it:'Acrilico' , zhCN: "玻璃"
        },
    },
    {
        sigla: 'PC',
        nome: {
            pt: 'Policarbonato', en: 'Polycarbonate', 
            es: 'Policarbonato',fr: "Polycarbonate", it:'Policarbonato', zhCN: "聚碳酸酯"
        },
    },
    {
        sigla: 'CRM',
        nome: {
            pt: 'Cerâmica', en: 'Ceramics', es: 'Cerámica',
            fr: "Céramique", it:'Ceramica', zhCN: "陶瓷"
        },
    },
]

export const acabamento =[
    {
        sigla: 'BR',
        nome: {
            pt: 'Branco', en: 'White', es: 'Blanco',
            fr: "Blanc", it:'Bianco', zhCN: "陶瓷"
        },
    },
    {
        sigla: 'PR',
        nome: {
            pt: 'Preto', en: 'Black', 
            es: 'Negro',fr: "Noir", it:'Nero'
        },
    },
    {
        sigla: 'CZ',
        nome: {
            pt: 'Cinza', en: 'Grey', 
            es: 'Gris',fr: "Gris", it:'Grigio', zhCN: "灰色"
        },
    },
    {
        sigla: 'AZ',
        nome: {
            pt: 'Azul', en: 'Blue', 
            es: 'Azul',fr: "Bleu", it:'Blu', zhCN: "蓝色"
        },
    },
    {
        sigla: 'VR',
        nome: {
            pt: 'Vermelho', en: 'Red', 
            es: 'Rojo',fr: "Rouge", it:'Rosso', zhCN: "红色"
        },
    },
    {
        sigla: 'AMR',
        nome: {
            pt: 'Amarelo', en: 'Yellow', 
            es: 'Amarillo',fr: "Jaune", it:'Giallo', zhCN: "黄色"
        },
    },
    {
        sigla: 'VRD',
        nome:  {
            pt: 'Verde', en: 'Green', 
            es: 'Verde',fr: "Vert", it:'Verde', zhCN: "绿色"
        },
    },
    {
        sigla: 'LRJ',
        nome: {
            pt: 'Laranja', en: 'Orange', 
            es: 'Naranja',fr: "Orange", it:'Arancione', zhCN: "橙色"
        },
    },
    {
        sigla: 'AM',
        nome: {
            pt: 'Âmbar', en: 'Amber', 
            es: 'Ámbar',fr: "Ambre", it:'Ambra', zhCN: "琥珀"
        },
    },
    {
        sigla: 'TRP',
        nome: {
            pt: 'Transparente', en: 'Transparent', 
            es: 'Transparente',fr: "Transparent", it:'Trasparente', zhCN: "透明"
        },
    },
    {
        sigla: 'FSC',
        nome: {
            pt: 'Fosco', en: 'Matte', 
            es: 'Mate',fr: "Mat", it:'Opaco', zhCN: "哑光"
        },
    },
    {
        sigla: 'MLK',
        nome: {
            pt: 'Milk', en: 'Milkatte', 
            es: 'Mate',fr: "Lait", it:'Latte', zhCN: "牛奶"
        },
    },
    {
        sigla: 'NQ',
        nome: {
            pt: 'Níquel', en: 'Nickel', 
            es: 'Níquel',fr: "Nickel", it:'Nichel', zhCN: "镍"
        },
    },
    {
        sigla: 'CR',
        nome:{
            pt: 'Cromado', en: 'Chrome', 
            es: 'Cromo',fr: "Chrome", it:'Cromo', zhCN: "镀铬"
        }, 
    },
    {
        sigla: 'AN',
        nome: {
            pt: 'Antracite', en: 'Anthracite', 
            es: 'Antracita',fr: "Anthracite", it:'Antracite', zhCN: "无烟煤"
        },
    },
    {
        sigla: 'CRT',
        nome: {
            pt: 'Corten', en: 'Corten', 
            es: 'Corten',fr: "Corten", it:'Corten', zhCN: "耐候钢"
        },
    },
    {
        sigla: 'ORM',
        nome:{
            pt: 'Ouro rosa mate', en: 'Matt rose gold', 
            es: 'Oro rosa mate',fr: "Or rose mat", it:'Oro rosa opaco', zhCN: "哑光玫瑰金"
        },
    },
    {
        sigla: 'ORS',
        nome: {
            pt: 'Ouro rosa', en: 'Matt rose', 
            es: 'Oro rosa',fr: "Or rose", it:'Oro rosa',zhCN: "玫瑰金"
        },
    },
    {
        sigla: 'OR',
        nome: {
            pt: 'Ouro', en: 'Gold', 
            es: 'Oro',fr: "L'or", it:'Oro', zhCN: "金子"
        },
    },
    {
        sigla: 'BRZ',
        nome: {
            pt: 'Bronze', en: 'Bronze', 
            es: 'Bronce',fr: "Bronze", it:'Nero perla', zhCN: "青铜"
        },
    },
    {
        sigla: 'PP',
        nome:{
            pt: 'Preto pérola', en: 'Pearl black', 
            es: 'Negro perla',fr: "Noir perle", it:'Nero perla', zhCN: "黑色珍珠"
        },
    },
    {
        sigla: 'WD',
        nome: {
            pt: 'Madeira', en: 'Wood', 
            es: 'Madera',fr: "Madère", it:'Madeira', zhCN: "木材"
        },
    },
]

export const ip =[
    {
        sigla: 'IP20',
        nome: {
            pt: 'Poeira', en: 'Dust', 
            es: 'Polvo',fr: "Poussière", it:'Polvere'
        },
    },
    {
        sigla: 'IP40',
        nome:{
            pt: 'Poeira', en: 'Dust', 
            es: 'Polvo',fr: "Poussière", it:'Polvere'
        },
    },
    {
        sigla: 'IP44',
        nome: {
            pt: 'Salpicos e Poeira', en: 'Splashes and dust', 
            es: 'Salpicaduras y polvo',fr: "Éclaboussures et poussière", it:'Schizzi e polvere'
        },
    },
    {
        sigla: 'IP54',
        nome: {
            pt: 'Salpicos e Poeira', en: 'Splashes and dust', 
            es: 'Salpicaduras y polvo',fr: "Éclaboussures et poussière", it:'Schizzi e polvere'
        },
    },
    {
        sigla: 'IP65',
        nome: {
            pt: 'Poeira e jatos de água de baixa pressão', en: 'Dust and low-pressure water jets', 
            es: 'Polvo y chorros de agua a baja presión',fr: "Poussière et jets d'eau à basse pression", it:"Polvere e getti d'acqua a bassa pressione"
        },
    },
    {
        sigla: 'IP67',
        nome:  {
            pt: 'Poeira e efeitos da imersão até 1m ou 30 mis', en: 'Dust and immersion effects up to 1m or 30 mis', 
            es: 'Efectos de polvo e inmersión hasta 1 m o 30 mis',fr: "Effets de la poussière et de l'immersion jusqu'à 1 m ou 30 m",
            it:"Effetti della polvere e dell'immersione fino a 1 m o 30 mis"
        },
    },
    {
        sigla: 'IP68',
        nome:  {
            pt: 'Totalmente á prova de poeira e água', en: 'Fully dust and waterproof', 
            es: 'Totalmente resistente al polvo y al agua',fr: "Entièrement étanche à la poussière et à l'eau",
            it:"Completamente impermeabile e antipolvere"
        },
    },
    {
        sigla: 'IP69K',
        nome:  {
            pt: 'Máxima proteção contra poeiras e infiltrações de água', en: 'Maximum protection against dust and water infiltration', 
            es: 'Máxima protección contra el polvo y las infiltraciones de agua',fr: "Protection maximale contre les infiltrations de poussière et d'eau",
            it:"Massima protezione contro le infiltrazioni di polvere e acqua"
        },
    },
]