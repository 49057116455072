import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useParams, useLoaderData } from 'react-router-dom';
import '../layout/Projectos.Module.css'
import '../layout/DetalheEvento.Module.css'
import { events } from '../events';
import Slider from 'react-slick'
import { useTranslation } from 'react-i18next';

function DetalheEvento() {

    let {name} = useParams();
    const eve = events.find((e)=>e.title === name);

    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 6000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      };
  
  const { t, i18n } = useTranslation('');
  return (
    <>
    <div className='Detalhe-evento'>
    <div id="main-bg" style={{backgroundImage: `url(${eve.currentEImg})`}}></div>

    <nav aria-label="breadcrumb" className="breadcrumbs medium-font">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/eventos" className="text-decoration-none text-uppercase" role="button" tabIndex="0">{t('navbar.eventos')}</Link></li>
          <li className="breadcrumb-item active text-uppercase" aria-current="page">{eve.title}</li>
        </ol>
    </nav>

    <h1 className='nameP'>{eve?.title}</h1>
    <h2 className='typeP text-uppercase'>{eve?.country[i18n.language]}</h2>
    <h3 className='typeP'>{eve?.city[i18n.language]}</h3>

     
    <div className='slide-eventos'>
    <Slider {...settings}>
    {eve?.gallery.map((item,index)=>(
        <div className='image-gallery-item' key={index}>
        <img src={item.linkImg}></img>
        </div>
    ))}
    </Slider>
    </div>

    <div className='event-info'>
    <hr className="card-line w-100" />

    <div className='grid-info'>
    <div className="image-left">
          <img className='logo' src={eve.logo} alt={eve?.title} />
        <div>
          <span><strong>{eve?.title1[i18n.language]}</strong></span>
            <p>{eve?.para1[i18n.language]}</p>
        </div>
        
    </div>

        <hr className="card-line w-100" />
   <div className="image-right">
     <img src={eve?.sideRight} alt={eve?.title} />
     <div>
     <span><strong>{eve?.title2[i18n.language]}</strong></span>
       <p>{eve?.para2[i18n.language]}</p>
     </div>
   </div>
    </div>

    </div>

  </div>
    </>
  )
}

export default DetalheEvento