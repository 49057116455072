import React from 'react'
import Sobre from '../layout/Sobre.Module.css'
import logob from '../images/logos/logo-d-azul.png'
import logorr from '../images/logos/logorr.png'
import { useTranslation } from 'react-i18next';

function About() {

  const { t, i18n } = useTranslation('');

  return (
    <div className='about-page'>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <section className="about-section">
        <div className="container">
          <div className="row">                
            <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
              <div className="inner-column">
                <div className="sec-title">
                  <span className="title">{t('sobre.grupo')}</span>
                    <div className='logo-img'><img src={logorr} className='logo-rr'/></div>
                  <h2>{t('sobre.bigtxt')}</h2>
                </div>
                <div className="text"><p>{t('sobre.text2')}</p>
                <p>{t('sobre.text3')}</p></div>
  
              </div>
            </div>
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column wow fadeInLeft">
                <figure className="image-1"><a href="#" className="lightbox-image" data-fancybox="images"><img src="./images/empresa/imagem-ext.jpg" alt="" /></a></figure>
                <figure className="image-2"><a href="#" className="lightbox-image" data-fancybox="images"><img src="./images/empresa/exterior-pb.jpg" alt="" /></a></figure>
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className="about" id="about">
        <div className="container">
          <div className="heading text-center">
            <h2>Sobre a  &nbsp;
              <span>Advance</span></h2>
            <p>As exigências específicas dos profissionais do setor da iluminação são inúmeras e existe uma grande procura por marcas que consigam antecipar as tendências 
            de um mercado altamente exigente e em constante evolução. A <b>Advance</b> apresenta assim as suas marcas: <b>LUMIARQ, MAXLED, R-ELECTRIC, LIVOLO, WESTINGHOUSE</b> e <b>ECOPOINT</b>, 
            inovadoras e competitivas, que dispõem de soluções LED que aliam o design moderno à recente tecnologia.
           
              <br />
              Com uma equipa especializada disponível para fornecer suporte técnico adequado, trabalhamos todos os dias para garantir a satisfação dos nossos clientes. Utilizamos tecnologia 
              e inovação para resolver problemas reais de iluminação e agregar valor aos nossos clientes e colaboradores.
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src="https://advance-rr.com/wp-content/uploads/elementor/thumbs/Ativo-3-q2moy4sj6m4e8fq27efgy2cj4kxt64xicpn7y6cqz8.jpg" alt="about" className="img-fluid" width="100%" />
            </div>
            <div className="col-lg-6">
              <h3>{t('sobre.ilufutu')}</h3>
              <p className="aboutus-text">
            Com uma equipa especializada disponível para fornecer suporte técnico adequado, trabalhamos todos os dias para garantir a satisfação dos nossos clientes. Utilizamos tecnologia 
            e inovação para resolver problemas reais de iluminação e agregar valor aos nossos clientes e colaboradores.</p>
              <p>Somos um grupo diversificado e dedicado em fornecer uma vasta gama de produtos de iluminação e materiais elétricos com foco em:</p>
              <div className="row list-qualities">
                <div className="col-md-6">
                  <h4>
                    <i className="far fa-star" />{t('sobre.dura')}</h4>
                </div>
                <div className="col-md-6">
                  <h4>
                    <i className="far fa-star" />
                    {t('sobre.efi')}</h4>
                </div>
                <div className="col-md-6">
                  <h4>
                    <i className="far fa-star"/> Design</h4>
                </div>
                <div className="col-md-6">
                  <h4>
                    <i className="far fa-star" />
                    {t('sobre.enerver')}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <section className='fundaments'>
      <div className="aboutus-banner">
          <img src={logob} alt="" />
      </div>
      <div className='cards'>
      <div className="card">
          <div className="ico">
            <i className="fa fa-bullseye" />
          </div>
          <h3 className="title text-uppercase">{t('sobre.missao')}</h3>
          <p>{t('sobre.txtmissao')}</p>
        </div>
        <div className="card">
          <div className="ico">
            <i className="fa fa-eye" />
          </div>
          <h3 className="title text-uppercase">{t('sobre.visao')}</h3>
          <p>{t('sobre.txtvisao')}</p>
        </div>        
        <div className="card">
          <div className="ico">
            <i className="fa fa-star" />
          </div>
          <h3 className="title text-uppercase">{t('sobre.valores')}</h3>
          <p>{t('sobre.txtvalores')}</p>
        </div>
        </div>
      </section>

      <section className='linha-tempo'>
      <h4 className="fw-bold text-center">
          <sl-icon className="me-2" name="diagram-3-fill" />{t('sobre.evolu')}
      </h4>
        <div className="hr-line mt-4 mb-4" />
        <h3 className="intro fw-bold text-center">{t('sobre.text')}</h3>
        <div className="container pt-5">
          <div className="main-timeline">
            {/*1st section*/}
            <div className="timeline-item d-flex flex-row flex-wrap justify-content-center align-items-center">
              <div className="date-content order_1b">
                <div className="date-outer">
                  <span className="date">
                    <p className="year pt-2">2013</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2b" />
              <div className="timeline-content order_3b col-md p-4">
                <h5 className="title pt-4 fw-bold">Nasce a Advance</h5>
                <p className="description">
                  Tim Berners-Lee, invents the “World Wide Web” as an easy way to share information. Though we often use the “Internet” and the “Web” 
                  interchangeably, they don’t actually refer to the same thing. The Internet hosts the Web, which was Berners-Lee’s breakthrough.
                </p>
              </div>
            </div>
            {/*1st section*/}
            {/*2nd section*/}
            <div className="timeline-item d-flex flex-row flex-wrap justify-content-center align-items-center">
              <div className="date-content order_1">
                <div className="date-outer">
                  <span className="date">
                    <p className="year pt-2">2016</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2" />
              <div className="timeline-content order_3 col-md p-3">
                <h5 className="title pt-4 fw-bold">A Advance expandindo-se</h5>
                <p className="description">
                  The beginning of the end for encyclopedia salesmen. Wikipedia launched with its first edit on January 15, 2001, and fast became the go-to source of 
                  information. By 2006, the site had published over 1 million articles and other internet enretpreneurs followed shortly.
                </p>
              </div>
            </div>
            {/*2nd section*/}
            {/*3rd section*/}
            <div className="timeline-item d-flex flex-row flex-wrap justify-content-center align-items-center">
              <div className="date-content order_1b">
                <div className="date-outer">
                  <span className="date">
                    <p className="year pt-2">2022</p>
                  </span>
                </div>
              </div>
              <div className="icon order_2b" />
              <div className="timeline-content order_3b col-md p-4">
                <h5 className="title pt-4 fw-bold">Internet users worldwide</h5>
                <p className="description">
                  As of October 2022, there were 5.07 billion active internet users worldwide – almost 59.6 percent of the global population. 
                  Ask any of them what life would be like without the net and the answer will likely be either ‘unimaginable’ or ‘very, very boring’.
                </p>
              </div>
            </div>
            {/*3rd section*/}
          </div>
        </div>
        <br />
        <div className="hr-line mt-4 mb-4" />
        <div className='social-icons'>
        <p className="footer fs-6 fw-bold text-center text-muted">{t('sobre.saiba')}:</p>
        <p className="text-center social-links">
          {/* facebook  Button */}
          <a href="https://www.facebook.com/Advancelight/" target="blank" className="social-margin"> 
            <div className="social-icon facebook">
              <i className="fa fa-facebook" aria-hidden="true"></i> 
            </div>
        </a>
        {/* linkedin  Button */}
        <a href="https://www.linkedin.com/company/grupo-ruan-ruan/" className="social-margin" target="blank">
          <div className="social-icon linkedin">
            <i className="fa fa-linkedin" aria-hidden="true"></i>
          </div> 
        </a>
        {/* youtube  Button */}
        <a href="https://www.youtube.com/channel/UCWZeoH9q8Rg4KFIWHDzQVRw" target="blank"  className="social-margin">
            <div className="social-icon youtube">
              <i className="fa fa-youtube" aria-hidden="true"></i>
            </div> 
        </a>
        {/* instagram  Button */}
        <a href="https://www.instagram.com/advancelightpt/" target="blank"  className="social-margin">
        <div className='gradient'>
        <div className="social-icon instagram">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </div> 
        </div>
        </a>
        {/* tiktok  Button */}
        <a href="https://www.tiktok.com/@advancelightpt" target="blank"  className="social-margin">
            <div className="social-icon tiktok">
              <i className="fab fa-tiktok" aria-hidden="true"></i>
            </div> 
        </a>
        </p>
        </div>
      
      </section>
    
    </div> 
  )
}

export default About