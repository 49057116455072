import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../layout/Projectos.Module.css';
import { projectos } from '../events';
import { useTranslation } from 'react-i18next';

const Projectos = () => {
  const { name } = useParams();
  const proje = projectos.find((p) => p.name === name);

  // State for managing the full-screen carousel
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Handle image click to open the carousel
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsFullScreen(true);
  };

  // Close the full-screen carousel
  const handleClose = () => {
    setIsFullScreen(false);
  };

  // Navigate to the previous image
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? proje.gallery.length - 1 : prevIndex - 1
    );
  };

  // Navigate to the next image
  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === proje.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const { t, i18n } = useTranslation('');

  return (
    <div className='Projectos'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={'/portfolio'} className="text-decoration-none text-uppercase">{t('projectos.portifolio')}</Link></li>
            <li className="breadcrumb-item active text-uppercase" aria-current="page">{proje.name}</li>
          </ol>
        </nav>

      <h1 className='nameP'>{proje.name}</h1>
      <h2 className='typeP text-uppercase'>{proje.type[i18n.language]}</h2>

      <div className='project-detail'>
        <div className='big-image'>
          <div className='main-image'>
            <img src={proje.imgP} alt='Main' />
          </div>
        </div>

        <div className='grid-info'>
          <div className='data-items'>

            {proje.place != null && <span><strong>{t('projectos.local')}</strong> {proje.place}</span>}

            {(proje?.social) && (
              <span><strong>{t('projectos.site')} </strong> <a href={proje?.social} target="_blank">{proje?.social}</a></span>
            )}
            <span><strong>{t('projectos.galeria')}</strong></span>
          </div>
          <div className='grid-gallery'>
            {proje.gallery.map((item, index) => (
              <div
                className='image-gallery-item'
                key={index}
                onClick={() => handleImageClick(index)}
              >
                <img src={item.linkImg} alt={`Gallery item ${index}`} />
              </div>
            ))}
          </div>

          <hr className="card-line w-100" />
          <span className='title-descri'><strong>{t('projectos.sobre')}:</strong></span>
          <p>{proje.descri[i18n.language]}</p>
        </div>
      </div>

      {/* Full-screen Carousel */}
      {isFullScreen && (
        <div className='full-screen-carousel'>
          <span className='close' onClick={handleClose}>&times;</span>
          <div className='carousel-content'>
            <button className='carousel-control prev' onClick={handlePrevClick}>
              &#10094;
            </button>
            <div className=''><img src={proje.gallery[currentImageIndex].linkImg} alt={`Carousel item ${currentImageIndex}`} /></div>
            <button className='carousel-control next' onClick={handleNextClick}>
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projectos;