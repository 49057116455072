export const cardImageSlide = [
    {
      id: 1,
      bigtitle:  {
        pt: 'Interior', en: "Interior", es: 'Interior', fr: "Intérieur",it: "Interno", zhCN: "內裝"
       },
      smaltitle: 'design',
      linkImg: './images/landing-page/img1.jpg',
      cardTitle: 'Casa',
      text: {
       pt: `Precisa de iluminação interior para o seu quarto, sala de estar ou casa de banho? Escolhemos a melhor iluminação de interior para si. 
       Contamos com candeeiros, Projetores, paineis e muitos mais para decorar a sua casa ou espaços comerciais`,
       en: `Do you need interior lighting for your bedroom, living room or bathroom? We've chosen the best interior lighting for you. 
       We have lamps, spotlights, panels and much more to decorate your home or commercial spaces.`,
       es: `¿Necesita iluminación interior para su dormitorio, salón o cuarto de baño? Hemos seleccionado la mejor iluminación interior para usted. 
       Tenemos lámparas, focos, paneles y mucho más para decorar tu hogar o espacios comerciales.`,
       fr: `Vous avez besoin d'un éclairage d'intérieur pour votre chambre, votre salon ou votre salle de bains ? Nous avons sélectionné pour vous les meilleurs éclairages d'intérieur. 
       Nous avons des lampes, des spots, des panneaux et bien plus encore pour décorer votre maison ou vos espaces commerciaux.`,
       it: `Avete bisogno di un'illuminazione interna per la camera da letto, il soggiorno o il bagno? Abbiamo scelto per voi la migliore illuminazione per interni. 
       Abbiamo lampade, faretti, pannelli e molto altro per decorare la vostra casa o i vostri spazi commerciali.`,
       zhCN: "您的臥室、客廳或浴室需要室內照明嗎？我們為您挑選了最好的室內照明。我們有燈具、射燈、面板等等，可裝飾您的居家或商業空間。"
      },
    },
    {
      id: 2,
      bigtitle:  {
        pt: 'Industrial', en: "Industrial", es: 'Industrial',fr: "Industrie", it: "Industriale", zhCN: "工業級"
       },
      smaltitle: 'design',
      linkImg: './images/landing-page/img2.jpg',
      cardTitle: 'Indus',
      text: {
        pt: `Eficiente, versátil e inteligente, oferecemos a solução de iluminação industrial mais potente do mercado.
         A Advance oferece uma opção de conectividade que satisfazem os requisitos rigorosos de Halls industriais e armazéns`,
        en: `Efficient, versatile and intelligent, we offer the most powerful industrial lighting solution on the market.
         Advance offers a choice of connectivity to meet the stringent requirements of industrial halls and warehouses.`,
        es: `ente, versátil e inteligente, ofrecemos la solución de iluminación industrial más potente del mercado.
         Advance ofrece una selección de conectividad para satisfacer los estrictos requisitos de naves industriales y almacenes.`,
        fr: `Efficace, polyvalent et intelligent, nous offrons la solution d'éclairage industriel la plus puissante du marché.
         Advance offre un choix de connectivité pour répondre aux exigences strictes des halls industriels et des entrepôts.`,
        it: `Efficiente, versatile e intelligente, offriamo la soluzione di illuminazione industriale più potente del mercato.
         Advance offre una scelta di connettività per soddisfare i severi requisiti di capannoni e magazzini industriali.`,
         zhCN: "高效、多用途且智慧型，我們提供市場上功能最強大的工業照明解決方案。Advance 提供多種連接性選擇，可滿足工業大廳和倉庫的嚴格要求。"
       },
    },
    {
      id: 3,
      bigtitle: {
        pt: 'Exterior', en: "Exterior", es: 'Exterior', fr: "Extérieur",it: "Esterno", zhCN: "外觀"
       },
      smaltitle: 'design',
      linkImg: './images/landing-page/img3.jpg',
      cardTitle: 'Pub',
      text: {
        pt: `Soluções desenhadas para iluminação exterior com fator de protecção IP. Balizadores, apliques, luminárias públicas e muito mais para iluminar
        seus jardins, terraços, piscinas e ambientes públicos.`,
        en: `Solutions designed for outdoor lighting with an IP protection factor. Beacons, sconces, public luminaires and much more to illuminate
        your gardens, terraces, swimming pools and public environments.`,
        es: `Soluciones diseñadas para el alumbrado exterior con factor de protección IP. Balizas, apliques, luminarias públicas y mucho más para iluminar
        sus jardines, terrazas, piscinas y entornos públicos.`,
        fr: `Solutions conçues pour l'éclairage extérieur avec un facteur de protection IP. Balises, appliques, luminaires publics et bien plus encore pour éclairer
        vos jardins, terrasses, piscines et environnements publics.`,
        it: `Soluzioni progettate per l'illuminazione esterna con fattore di protezione IP. Fari, appliques, apparecchi per il pubblico e molto altro ancora per illuminare
        giardini, terrazze, piscine e ambienti pubblici.`,
        zhCN: "專為戶外照明設計的解決方案，具有 IP 保護係數。燈標、燈座、公共燈具等等，照亮您的花園、露台、游泳池和公共環境。您的花園、露台、游泳池和公共環境。"
       },
    },
    {
      id: 4,
      bigtitle: {
        pt: 'Comercial', en: "Commercial", es: 'Comercial', fr: "Commercial",it: "Commerciale", zhCN: "商業"},
      smaltitle: 'design',
      linkImg: './images/landing-page/img4.png',
      cardTitle: 'Comercial',
      text: {
        pt: `Produtos de Iluminação LED para destinado a criar um ambiente seguro e eficiente onde as pessoas possam trabalhar e colaborar facilmente umas com as outras sem desperdiçar muita energia.
         Projetores, downlights LED, armaduras, luminárias e muito mais para suas lojas, hospitais, escritórios e armazéns etc...`,
        en: `LED lighting products designed to create a safe and efficient environment where people can work and collaborate easily with each other without wasting too much energy.
         Floodlights, LED downlights, armatures, luminaires and much more for your shops, hospitals, offices and warehouses etc...`,
        es: `Productos de iluminación LED diseñados para crear un entorno seguro y eficiente en el que las personas puedan trabajar y colaborar fácilmente entre sí sin gastar demasiada energía.
         Focos, downlights LED, armaduras, luminarias y mucho más para sus tiendas, hospitales, oficinas y almacenes, etc..`,
        fr: `Produits d'éclairage LED conçus pour créer un environnement sûr et efficace où les gens peuvent travailler et collaborer facilement les uns avec les autres sans gaspiller trop d'énergie.
         Projecteurs, downlights LED, armatures, luminaires et bien plus encore pour vos magasins, hôpitaux, bureaux et entrepôts, etc...`,
        it: `Prodotti per l'illuminazione a LED progettati per creare un ambiente sicuro ed efficiente in cui le persone possano lavorare e collaborare facilmente tra loro senza sprecare troppa energia.
         Proiettori, downlight a LED, armature, apparecchi di illuminazione e molto altro ancora per i vostri negozi, ospedali, uffici, magazzini ecc...`,
         zhCN: "LED 照明產品旨在創造一個安全高效的環境，讓人們可以在不浪費太多能源的情況下輕鬆工作和相互協作。適用於商店、醫院、辦公室和倉庫等的泛光燈、LED 崁燈、電樞、燈具等等..."
       },
    },
  ];