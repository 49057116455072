import React from 'react'
import Sobre from '../layout/Contacto.Module.css'
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t, i18n } = useTranslation('');
  
  return (
    <div>
    <section className="contact_us">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="contact_inner">
                <div className="row">
                  <div className="col-lg-10 col-md-7 col-7 form-container">
                    <div className="contact_form_inner">
                      <div className="contact_field">
                        <h3 className='text-uppercase'>{t('noticias.tituloPage')}</h3>
                        <p>{t('contactos.text')}</p>
                        <input type="text" className="form-control form-group" placeholder={t('contactos.nome')} />
                        <input type="tel" className="form-control form-group" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder={t('contactos.telefone')} required/>
                        <input type="text" className="form-control form-group" placeholder="Email" />
                        <textarea className="form-control form-group" placeholder={t('contactos.mensagem')} defaultValue={""} />
                        <button className="contact_form_submit" type='submit'>{t('contactos.enviar')}</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-5 col-5 social-icon">
                    <div className="right_contact_social_icon d-flex align-items-end">
                      <div className="social_item_inner d-flex">
                        <li><a href="#"><i className="fab fa-facebook-square" /></a></li>
                        <li><a href="#"><i className="fab fa-instagram" /></a></li>
                        <li><a href="#"><i className="fab fa-youtube" /></a></li>
                        <li><a href="#"><i className="fab fa-linkedin" /></a></li>
                        <li><a href="#"><i className="fab fa-tiktok" /></a></li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact_info_sec">
                  <h4>{t('contactos.infoconta')}</h4>
                  <div className='two-blocks'>
                  <div className="d-flex info_single align-items-center">
                    <i className="fas fa-headset" />
                    <span>+351 252 615 273</span>
                  </div>
                  <div className="d-flex info_single align-items-center">
                    <i className="fas fa-envelope-open-text" />
                    <span>geral@gruporr.pt</span>
                  </div>
                  </div>
                  <div className="d-flex info_single align-items-center">
                    <i className="fas fa-map-marked-alt" />
                    <span>Rua A, Lote 7- Árvore - 4480-619 &amp; Vila do Conde</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="map_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="map_inner">
                <h4>{t('footer.visitar')}</h4>
                <div className="map_bind">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.88517843689!2d-8.7180993!3d41.333110100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd244396ff945ea3%3A0x23827809ed7cdaa0!2sADVANCE%20-%20Lighting%20%26%20Electrical%20Distributors!5e0!3m2!1spt-PT!2spt!4v1716902225118!5m2!1spt-PT!2spt" width="100%" height={450} allowFullScreen aria-hidden="false" tabIndex={0} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Contact