import React from 'react'

function PoliCookies() {
  return (
    <div className='container Cookies'>
        <div className='text'>
        <h4>Política de cookies?</h4>
<p>A Advance Lighting utiliza, nos seus Websites, cookies que permitem melhorar o desempenho e a experiência de navegação dos nossos utilizadores, aumentando, por um lado, a rapidez e eficiência de resposta e, por outro, eliminando a necessidade de introduzir repetidamente as mesmas informações. Ao aceitares a Política de Cookies da Advance Lighting&nbsp;deverás ter em consideração que a utilização de cookies possibilita a personalização da utilização e a apresentação de serviços e ofertas adaptadas aos seus interesses. Podes alterar as tuas definições de cookies a qualquer altura.</p>
<h4>O que são cookies?</h4>
<p>Os cookies são pequenos ficheiros de texto enviados para o teu computador ou dispositivo móvel quando visitas websites. Os cookies ajudam os websites a armazenar e recuperar informação sobre os teus hábitos de navegação por forma a personalizar a navegação de acordo com as tuas preferências.</p>
<p>Os cookies podem ser categorizados face ao propósito que servem. Existem cookies que são estritamente necessários ao funcionamento dos <em>websites</em>, enquanto outros recolhem informação estatística com o intuito de analisar a utilização dos <em>websites</em> e respetivo desempenho; ou para assegurar a disponibilização de funcionalidades adicionais aos <em>websites;</em> ou até para guardar as preferências do visitante relativamente à navegação nos mesmos. Outros cookies podem ainda servir para medir o sucesso de aplicações e a eficácia da publicidade de terceiros.</p>
<h4>Como posso gerir as cookies?</h4>
<p>Com exceção dos cookies estritamente necessários ao desempenho dos <em>websites</em>, o armazenamento de outros cookies dependerá sempre da aceitação e consentimento do utilizador.</p>
<p>Podes alterar as tuas definições de cookies a qualquer altura na ferramenta de gestão de preferências de cookies disponível nos Websites da Advance Lighting.</p>
<h4>Transferência de dados para países terceiros à União Europeia</h4>
<p>A utilização de determinados Cookies poderá implicar a transferência de dados pessoais para um país terceiro à União Europeia. A Advance Lighting&nbsp;garante que verifica o grau de maturidade do país de destino e adota as garantias adequadas, contratuais ou de outra natureza, para mitigar os riscos de cada transferência de dados pessoais. Nomeadamente, as transferências de dados pessoais para países terceiros à União Europeia ocorrerão para países que beneficiam de uma decisão de adequação da Comissão Europeia ou se estiverem implementadas as Cláusulas Contratuais-Tipo aprovadas pela Comissão Europeia.</p>
<p>Poderás verificar, nos quadros seguintes, onde se localizam cada um dos cookies que utilizamos.&nbsp;</p>	 
        </div>

    </div>
  )
}

export default PoliCookies