export const ourBrands = [
    {
      id: 1,
      name: "lumiarq",
      linkImg: '/images/logos/m-prop/lumiarq.png',
      category: [
      {name:'Fitas profissionais',nome:{pt:'Fitas profissionais', en: "Professional tapes", es: 'Cintas profesionales', fr: "Bandes professionnelles",it: "Nastri professionali", zhCN: "专业带"}, linkImg: 'https://ik.imagekit.io/uqwhopr9g/categorias/fitas.jpg?updatedAt=1722875688727'} ,
      {name:'Sistema de Iluminação Magnético',nome:{pt:'Sistema de Iluminação Magnético', en: "Magnetic lighting system", es: 'Sistema de iluminación magnética', fr: "Système d'éclairage magnétique",it: "Sistema di illuminazione magnetica", zhCN: "磁性照明系统"}, linkImg:'https://ik.imagekit.io/uqwhopr9g/categorias/sis-magne.jpg?updatedAt=1722875692112'},
      {name:'Downlights',nome:{pt:'Downlights', en: "Downlights", es: 'Downlights', fr: "Projecteurs",it: "Downlights", zhCN: "嵌入式灯"}, linkImg: 'https://ik.imagekit.io/uqwhopr9g/categorias/downlights.jpg?updatedAt=1722875688731'},
      {name:'Projetores de calha',nome:{pt:'Projetores de calha', en: "Track Floodlights", es: 'Projecteurs de piste', fr: "Projecteurs de piste",it: "Proiettori per rotaie", zhCN: "导轨投影仪"}, linkImg:'https://ik.imagekit.io/advance/categorias/Menu/31216_31223.jpg?updatedAt=1725901606458'},
      {name:'Suspensões',nome:{pt:'Suspensões', en: "Suspensions", es: 'Suspensiones', fr: "Suspensions",it: "Sospensioni", zhCN: "悬挂灯具"}, linkImg:'https://ik.imagekit.io/advance/categorias/Menu/26069.jpg?updatedAt=1725901176257'},
      {name:'Armaduras estanque',nome:{pt:'Armaduras estanque', en: "Watertight Enclosures", es: 'Cajas estancas', fr: "Boîtiers étanches",it: "Contenitori a tenuta stagna",zhCN: "防水机柜"}, linkImg:'https://ik.imagekit.io/advance/produtos/Armaduras%20estanque/35573.png?updatedAt=1725900940607'} ,
      {name:'Luminárias Industriais',nome:{pt:'Luminárias Industriais', en: "Industrial luminaires", es: 'Luminarias industriales', fr: "Luminaires industriels",it: "Apparecchi industriali",zhCN:"工业灯具"}, linkImg: 'https://ik.imagekit.io/advance/categorias/Menu/lu-indus.jpg?updatedAt=1726246592901'},
      {name:'Câmpanulas Industriais',nome:{pt:'Câmpanulas Industriais', en: "Industrial Campanulas", es: 'Campanas industriales', fr: "Campanules industrielles",it: "Campanule industriali",zhCN:"工业用金钟罩"}, linkImg: 'https://ik.imagekit.io/advance/categorias/Menu/campa-indus.jpg?updatedAt=1726246516198'},
      {name:'Projetores Profissionais',nome:{pt:'Projetores Profissionais', en: "Professional Floodlights", es: 'Proyectores profesionalesterior', fr: "Projecteurs professionnels",it: "Proiettori professionali",zhCN:"专业投影仪"}, linkImg:'https://ik.imagekit.io/advance/categorias/Menu/projePro.png?updatedAt=1726246516411'},
      {name:'Projetores para Posto de Combustível',nome:{pt:'Projetores para Posto de Combustível', en: "Gas Station Floodlights", es: 'Proyectores para gasolineras', fr: "Projecteurs de stations-service",it: "Proiettori per stazioni di servizio",zhCN: "加油站投影仪"}, linkImg:'https://ik.imagekit.io/advance/produtos/Proje%20posto%20combustivel/21569.png?updatedAt=1725372118247'},
      {name:'Luminárias públicas',nome:{pt:'Luminárias públicas', en: "Public luminaires", es: 'Luminarias públicas', fr: "Luminaires publics",it: "Apparecchi pubblici",zhCN: "公共灯具"}, linkImg: 'https://ik.imagekit.io/advance/categorias/Menu/23648.jpg?updatedAt=1725901306497'} ,
      {name:'Séries ATEX', nome:{pt:'Séries ATEX', en: "ATEX Series", es:'Serie ATEX', fr: "Série ATEX",it: "Serie ATEX",zhCN: "ATEX 系列"}, linkImg:'https://ik.imagekit.io/advance/categorias/Menu/AHA240.png?updatedAt=1725902299942'},
    ],
    },
    {
      id: 2,
      name: 'maxled',
      linkImg: '/images/logos/m-prop/maxled.png',
      category: [
      {name:'Lâmpadas', nome:{pt:'Lâmpadas', en: "Lamps", es: 'Lámparas', fr: "Lampes",it: "Lampade",zhCN: "電燈泡"}, linkImg:'https://ik.imagekit.io/advance/categorias/Menu/10792.jpg?updatedAt=1725901913942'},
      {name:'Aros' ,nome:{pt:'Aros', en: "Rims", es: 'Llantas', fr: "Jantes",it: "Cerchi",zhCN: "戒指"}, linkImg:'https://ik.imagekit.io/advance/produtos/Aros/29541.png?updatedAt=1725900540857'} ,
      {name:'Tubos',nome:{pt:'Tubos',en: "Tubes", es: 'Tubos', fr: "Tubes",it: "Tubi",zhCN: "管材"}, linkImg:'https://ik.imagekit.io/advance/produtos/Tubos/20920.png?updatedAt=1726588277893'} ,
      {name:'Armaduras',nome:{pt:'Armaduras', en:"Armour", es: 'Armaduras', fr: "Armure",it: "Armature",zhCN: "盔甲"}, linkImg:'https://ik.imagekit.io/advance/produtos/Armaduras/37102.png?updatedAt=1726482783105'} ,
      {name:'Painéis',nome:{pt:'Painéis', en: "Panels", es: 'Paneles', fr: "Panneaux",it: "Pannelli",zhCN: "嵌板"},  linkImg:'https://ik.imagekit.io/advance/produtos/Paineis/21767.png?updatedAt=1726487813125'},
      {name:'Downlights',nome:{pt:'Downlights', en:"Downlights", es: 'Downlights', fr: "Downlights",it: "Downlights",zhCN: "嵌入式灯"}, linkImg: 'https://ik.imagekit.io/advance/produtos/Downlights/34064.png?updatedAt=1726239034600'},
      {name:'Fitas', nome:{pt:'Fitas', en:"Tapes", es:'Cintas', fr: "Rubans",it: "Nastri",zhCN: "地块"}, linkImg:'https://ik.imagekit.io/advance/produtos/Fitas/27660.png?updatedAt=1726507978082'},
      {name:'Perfis',nome:{pt:'Perfis', en: "Profiles", es:'Perfiles', fr: "Profils",it: "Profili",zhCN: "磁带"}, linkImg:'https://ik.imagekit.io/advance/produtos/Perfis/32756.png?updatedAt=1726505101708'} ,
      {name:'Campânulas',nome:{pt:'Campânulas', en: "Campanulas", es:'Campanulas', fr: "Capots",it: "Campanulas",zhCN: "公共灯具"}, linkImg: 'https://ik.imagekit.io/advance/categorias/Menu/eurostar1.jpg?updatedAt=1725902130778'},
      {name:'Plafons', nome:{pt:'Plafons', en: "Plafons", es: 'Plafones', fr: "Lumières",it: "Plafons",zhCN: "天花板灯"}, linkImg:'https://advance-rr.com/wp-content/uploads/2022/11/19320-720x720.jpg'} ,
      {name:'Apliques', nome:{pt:'Apliques', en: "Spotlights", es:'Focos', fr: "Appliqué",it: "Faretti"}, linkImg:'https://advance-rr.com/wp-content/uploads/2022/11/26151-720x720.jpg'} ,
      {name:'Balizadores', nome:{pt:'Balizadores', en: "Beacons", es: 'Balizas', fr: "Balises",it: "Fari",zhCN: "标灯"}, linkImg: 'https://ik.imagekit.io/advance/categorias/Menu/27486.jpg?updatedAt=1725901176080'},
      {name:'Projetores de espeto', nome:{pt:'Projetores de espeto', en: "Skewer projectors", es: 'Proyectores de pinchos', fr: "Projecteurs de brochettes",it: "Proiettori per spiedini",zhCN: "针式投影仪"}, linkImg: 'https://ik.imagekit.io/advance/produtos/Projetor%20espeto/29152.png?updatedAt=1729263797679'},
      {name:'Projetores de chão', nome:{pt:'Projetores de chão', en: "Floor projectors", es: 'Proyectores de suelo', fr: "Projecteurs de sol",it: "Proiettori da pavimento",zhCN: "地面投影仪"}, linkImg: 'https://ik.imagekit.io/advance/produtos/Projetor%20chao/23136.png?updatedAt=1729264090729'},
      {name:'Iluminação Solar',nome:{pt:'Iluminação Solar', en: "Solar lighting", es: 'Iluminación solar', fr: "Eclairage solaire",it: "Illuminazione solare",zhCN: "太阳能照明"}, linkImg: 'https://ik.imagekit.io/advance/produtos/Ilumi%20Solar/34750.png?updatedAt=1726590575692'},
      {name:'Iluminação de Emergência', nome:{pt:'Iluminação de Emergência', en: "Emergency Lighting", es: 'Iluminación de emergencia', fr: "Éclairage de secours",it: "Illuminazione di emergenza",zhCN: "应急照明"}, linkImg: 'https://ik.imagekit.io/advance/produtos/Iluminacao%20emer/32749.png?updatedAt=1726589635219'},
    ]
    },
    {
      id: 3,
      name: 'r-eletric',
      linkImg: '/images/logos/m-prop/reletric.png',
      category: [
      {name: 'Ligadores', nome:{pt:'Ligadores', en: "Connectors", es: 'Conectores', fr: "Connecteurs",it: "Connettori",zhCN: "连接器"},  linkImg:'https://ik.imagekit.io/advance/categorias/Menu/27394.jpg?updatedAt=1725901176192'},
      {name: 'Conectores estanque', nome:{pt:'Conectores estanque', en: "Waterproof connectors", es: 'Conectores estancos', fr: "Connecteurs étanches",it: "Connettori impermeabili",zhCN: "防水连接器"},  linkImg:'/images/categorias/reletric/conectores.jpg'} ,
      {name: 'Suportes', nome:{pt:'Suportes', en: "Brackets", es: 'Suportes', fr: "Supports",it: "Staffe",zhCN: "支架"},  linkImg:'https://ik.imagekit.io/advance/categorias/Menu/5M10T.jpg?updatedAt=1725901808036'},
      {name: 'Adaptadores', nome:{pt:'Adaptadores', en: "Adapters", es: 'Adaptadores', fr: "Adaptateurs",it: "Adattatori",zhCN: "适配器"},  linkImg:'https://ik.imagekit.io/advance/categorias/Menu/RLIGUT%20(1).jpg?updatedAt=1725901176225'},
      {name: 'Fontes de Alimentação', nome:{pt:'Fontes de Alimentação', en: "Power Supplies", es: 'Fuentes de alimentación', fr: "Blocs d'alimentation",it: "Alimentatori",zhCN: "电源"},  linkImg:'/images/categorias/reletric/fonte-alimen.jpg'},,
      {name: 'Kits de Emergência', nome:{pt:'Kits de Emergência', en: "Emergency Kits", es: 'Kits de emergencia', fr: "Kits d'urgence",it: "Kit di emergenza",zhCN: "应急包"},  linkImg:'https://ik.imagekit.io/advance/categorias/Menu/20555.jpg?updatedAt=1725901988612'} ,
      {name: 'Sensores', nome:{pt:'Sensores', en: "Sensors", es: 'Sensores', fr: "Capteurs",it: "Sensori",zhCN: "传感器"},  linkImg:'/images/categorias/reletric/sensores.jpg'} ,
      {name: 'Controladores', nome:{pt:'Controladores', en: "Controladores", es: 'Controladores', fr: "Contrôleurs",it: "Controllori",zhCN: "控制器"},  linkImg:'/images/categorias/reletric/controladores.jpg'} ,
      {name: 'Comandos', nome:{pt:'Comandos', en: "Controllers", es: 'Comandos', fr: "Commandes",it: "Comandi",zhCN: "控制装置"},  linkImg:'https://ik.imagekit.io/advance/produtos/Comandos/37591.png?updatedAt=1725622404431'} ,
      {name: 'Extratores', nome:{pt:'Extratores', en: "Extractors", es: 'Extratores', fr: "Extracteurs",it: "Estrattori",zhCN: "抽取器"},  linkImg:'https://ik.imagekit.io/advance/produtos/Extrator/21583.png?updatedAt=1725622534682'} ,
      {name: 'Abraçadeiras', nome:{pt:'Abraçadeiras', en: "Clamps", es: 'Abrazaderas', fr: "Pinces",it: "Morsetti",zhCN: "电缆扎带"},  linkImg:'https://ik.imagekit.io/advance/produtos/Abracadeiras/30516.png?updatedAt=1725619662910'} ,
    ]
    },
    {
      id:4,
      name: 'rdesign',
      linkImg: '/images/logos/m-prop/r-design.png',
      category: [{name:'Candeeiros',nome:{pt:'Candeeiros', en: "Lamps", es: 'Candeeiros', fr: "Lampes",it: "Lampade"},  linkImg:'/images/categorias/rdesign/candeeiro-usb.jpg'},
    ]
    },
]

export const reBrands = [

  {
    id: 1,
    name: 'ecopoint',
    linkImg: '/images/logos/m-repre/ecopointb.png',
    category: [{name: 'Carregadores de Carro', nome:{pt:'Carregadores de Carro', en: "Car Chargers", es: 'Cargadores de coche', fr: "Chargeurs de voiture",it: "Caricabatterie per auto",zhCN: "汽车充电器" }, linkImg:'/images/categorias/ecopoint/carregador-carro.png'},
  ]
  },
  {
    id: 2,
    name: 'livolo',
    linkImg: '/images/logos/m-repre/livolob.png',
    category: [
      {name: 'Mecanismos', nome:{pt:'Mecanismos', en: "Mechanisms", es: 'Mecanismos', fr: "Mécanismes",it: "Meccanismi",zhCN: "机制"}, linkImg:'https://ik.imagekit.io/advance/produtos/Mecanismo/VL-XG002-S72.png?updatedAt=1725633201897'},
      {name: 'Interruptores', nome:{pt:'Interruptores', en: "Switches", es: 'Interruptores', fr: "Interrupteurs",it: "Interruttori",zhCN: "开关"}, linkImg:'/images/categorias/livolo/interruptor.png'},
      {name: 'Espelhos', nome:{pt:'Espelhos', en: "Mirrors", es: 'Espejos', fr: "Miroirs",it: "Specchi",zhCN: "汽车充电器"}, linkImg:'https://ik.imagekit.io/advance/produtos/Espelhos/BB-C7-C2-12.png?updatedAt=1726744688325'},
  ]
  },

  {
    id:3,
    name: 'westinghouse',
    linkImg: '/images/logos/m-repre/westinghouseb.png',
    category: [{name: 'Pilhas',nome:{pt:'Pilhas', en: "Stacks", es: 'Pilas', fr: "Batteries",it: "Batterie",zhCN: "堆栈"}, linkImg:'/images/categorias/westinghouse/pilhas.jpg'},
  ]
  },
]
