import React, { useEffect, useState, useRef} from 'react' 
import  '../layout/ProdDetail.Module.css'
import logoAzul from '../images/logos/logo-azul.png'
import emailjs from '@emailjs/browser'
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'react-international-phone'
import { PhoneNumberUtil } from 'google-libphonenumber'
function Recru() {

  const [emailError, setEmailError] = useState('');
  const [getState, setState] = useState([]);
  const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
// Email and Phone Validation
const validateEmail = (e) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var email = e.target.value
  if (!regex.test(email)) {
    setEmailError('Email inválido');
 } else {
    setEmailError('Email válido :)!');
 }
}

function handleInputChange(event) {
  const { name, value } = event.target;
  setFormState(prevState => ({ ...prevState, [name]: value }));
}

const [formState, setFormState] = useState({ from_name: '', from_address: '', from_city:'', from_email:'', from_address:''});

const [phone, setPhone] = useState('');
const isValid = isPhoneValid(phone)

    const form = useRef();
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile && selectedFile.size <= 3 * 1024 * 1024) { // Limite de 3 MB
          setFile(selectedFile);
      } else {
          alert("O arquivo deve ser um PDF menor que 3MB.");
      }
  };

    function handleDownload(e){
      e.preventDefault();
  
      const formData = new FormData();
      formData.append("from_cv", file); // Adiciona o arquivo ao FormData

      // Verifica o tamanho total antes de enviar
      let totalSize = 0;
      for (const [key, value] of formData.entries()) {
          if (value instanceof File) {
              totalSize += value.size; // Adiciona o tamanho do arquivo
          } else {
              const stringSize = new Blob([value]).size; // Adiciona o tamanho do valor do campo
              totalSize += stringSize;
          }
      }
        
     console.log("Tamanho total dos dados:", totalSize); // Adiciona esta linha para verificar o tamanho

    if (totalSize > 50 * 1024) { // Limite de 50 KB
      alert("O tamanho total dos dados excede o limite permitido.");
      return;
    }
    
      emailjs.sendForm('service_p8r417p', 'template_kyy1kwn', form.current, {
        publicKey: 'Hd9bXwR34y-Uu_Jb9',
      })
      .then(
        (response) => {
            console.log("Email enviado com sucesso!", response.status, response.text);
            alert("Email enviado!");
        },
        (error) => {
            console.error("Erro ao enviar email:", error);
            alert("Erro ao enviar email.");
        }
    );

      alert('Candidatura enviada');

      console.log("Dados enviados:", formData);
    }

const { t, i18n } = useTranslation('');
  return (
    <div className='pag-recru'>
        <div className='container'>
        <div className='row'>
    <div className='col-md-5'>
     <div className="aply-info ">
        <h4><strong>{t('recru.carreira')}:</strong></h4>
        <p>{t('recru.text2')}</p>

        <p><strong>{t('recru.empreInclu')}</strong></p>

        <p>{t('recru.text')}</p>
     </div>
    </div>
    <div className='col-md-7'>
        <div className="form-application ">
        <div className="apply_box">
            <div className='aply-logo'>
            <img src={logoAzul}></img>
            </div>
        <h1 className='apply-title'>{t('recru.form')}</h1>
        <form ref={form} className='form-download row g-3 needs-validation' onSubmit={handleDownload} method='POST'>
        <div className="form_container">

        <div className="form_control">
            <label htmlFor="first name">{t('recru.nome')} *</label>
            <input type="text" id="first name" value={formState.name} name="from_name" placeholder="Enter name" required/>
        </div>
        <div className="form_control">
            <label className="form-label">{t('recru.tele')}: <span>*</span></label>  
            <PhoneInput defaultCountry="pt" value={phone} onChange={(phone) => setPhone(phone)} name='from_phone' required/>
            {!isValid && <div style={{ color: 'red' }}>{t('download.invalid')}</div>}
        </div>

      <div className="form_control">
            <label htmlFor="email">Email *</label>
            <input id="email" name='from_email' value={formState.email}  placeholder="Enter email" type="email" required/>
        </div>
        <div className="form_control">
            <label htmlFor="job">{t('recru.genero')} </label>
            <select id="job" name='from_gender'>
            <option value="nenhuma">{t('recru.nenhuma')}</option>
            <option value="feminino">{t('recru.feminino')}</option>
            <option value="masculino">{t('recru.masculino')}</option>
            </select>
        </div>
        <div className="form_control">
            <label htmlFor="dt-nasci">{t('recru.dt')}*</label>
            <input id="dt-nasci" name="from_nasc" value={formState.nasc} placeholder="Data Nascimento" type="date" required/>
        </div>
        <div className="form_control">
            <label htmlFor="job">{t('recru.setor')}</label>
            <select id="job" name='from_sector'>
            <option value="">{t('recru.setorp')}</option>
            <option value="Marketing"> {t('recru.marke')} </option>
            <option value="Recursos humanos"> {t('recru.rh')}</option>
            <option value="Armazém"> {t('recru.ar')}</option>
            <option value="Admnistração"> {t('recru.admi')}</option>
            <option value="Comercial"> {t('recru.co')}</option>
            <option value="Finanças"> {t('recru.fi')}</option>
            <option value="Outros">{t('recru.outros')}</option>
            </select>
        </div>
        <div className="form_control">
            <label htmlFor="job">{t('recru.to')}*</label>
            <select id="job" name='from_opor'>
            <option value="">{t('recru.setorp')}</option>
            <option value="Contrato de trabalho">{t('recru.ct')}</option>
            <option value="Estágio profissional">{t('recru.ep')}</option>
            <option value="Estágio curricular">{t('recru.ec')}</option>
            <option value="Estágio verão">{t('recru.ev')}</option>
            </select>
        </div>

        <div className="form_control">
            <label htmlFor="city text-capitalize">{t('recru.cidade')} </label>
            <input id="city" name='from_city' placeholder="Porto"/>
        </div>

        <div className="textarea_control">
            <label htmlFor="address"> {t('recru.mo')} </label>
            <textarea cols="50" id="address" name="from_address" placeholder="Rua X"  row="4" />
        </div>

        <div className="form_control">
            <label htmlFor="pincode">{t('recru.capacidad')}</label>
            <input id="pincode" placeholder="XXXX-XXX" name='from_cp' type="text"/>
        </div>
        <div className="form_control">
              <label htmlFor="cv">{t('recru.cv')}</label>
              <input id="cv" name='from_cv' placeholder={t('recru.inserircv')} type="url"/>
        </div>

        </div>
        <div className="button_container" >
            <button className='submit' type="submit">{t('recru.candida')}</button>
        </div>
        </form>
        </div>
        </div>
        </div>

    </div>
        </div>
    </div>
  )
}

export default Recru

