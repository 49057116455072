import React, { useEffect, useState, useRef} from 'react' 
import { Link, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from './AuthContext';
import i18n from './i18n'

function AreaPessoal() {
  const { user, logout } = useAuth()
  
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handlePasswordChange = async (e) => {
      e.preventDefault();

      try {
          const response = await axios.post('https://advancetest.advance-rr.com/alterar_senha.php', {
              email: user.email,
              current_password: currentPassword,
              new_password: newPassword,
          });

          if (response.data.success) {
              setMessage('Senha alterada com sucesso!');
          } else {
              setMessage(response.data.message || 'Erro ao alterar a senha');
          }
      } catch (error) {
          console.error('Erro ao alterar a senha:', error);
          setMessage('Erro no servidor. Tente novamente mais tarde.');
      }
  };

  return (
   <div className='container area-pessoal'>

  <h2 className='text-center m-5'>Bem vindo {user.nome}</h2>

  <button type="submit" className='btn  btn-danger'>Alterar palavra-passe</button>

    <form onSubmit={handlePasswordChange}>
    <h2>Alterar Senha</h2>
            <div class="form-row">

            <div class="form-group col-md-6">
            <label for="inputPassword4">Senha actual</label>
            <input type="password" className="form-control" placeholder="Senha Actual"id="inputPassword4"  value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}required/>
            </div>


            <div class="form-group col-md-6">
            <label for="inputPassword4">Nova Senha</label>
            <input type="password" className="form-control" placeholder="Nova Senha" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}required />
            </div>


            <button type="submit" className="btn btn-dark mt-3 mb-2">Alterar Senha</button>
            {message && <p>{message}</p>}
            </div>
      </form>
   </div>
  )
}

export default AreaPessoal