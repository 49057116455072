import React, { useEffect, useState} from 'react' 
import $ from 'jquery'
import '../layout/Filter.Module.css'
import '../layout/Eventos.Module.css'
import { events } from '../events';
import { Link, Route, Routes } from 'react-router-dom'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next';
/****Dar um destaque para eventos futuros quando entrar na página antes de mostrar Tudo, um formulário como o formulario da tabela para
solicitar os futuros eventos*******/
function Eventos() {

  const filteredEvents = events.filter((item)=>{
    const currentDate = new Date();
    const parts = item.date.split("/");

    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1; // meses em JS começam de 0
    const day = parseInt(parts[0], 10);

    const converted = new Date(year, month, day);
    return converted > currentDate;
  })

    // Função para converter a data do formato dd/mm/yyyy para um objeto Date
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(year, month - 1, day); // JavaScript usa 0-11 para os meses
    };
  
    // Função para calcular a diferença de tempo
    const calculateCountdown = (eventDate) => {
      const now = new Date();
      const event = parseDate(eventDate); // Usa a função de parsing correta
      
      let timeDifference = event - now; // diferença em milissegundos
      
      // Se a data do evento já passou, retorna zeros
      if (timeDifference <= 0) {
        return { months: 0, weeks: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
      }
    
      const seconds = Math.floor((timeDifference / 1000) % 60);
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
      const hours = Math.floor((timeDifference / 1000 / 60 / 60) % 24);
      
      // Calcula a diferença total em dias
      const daysTotal = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
      // Calcula os meses corretos
      let months = 0;
      let currentDate = new Date(now);
    
      while (currentDate < event) {
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (currentDate <= event) {
          months++;
        }
      }

      const eventWithoutMonths = new Date(now);
      eventWithoutMonths.setMonth(eventWithoutMonths.getMonth() + months);
      const remainingTime = event - eventWithoutMonths;
      
      const daysAfterMonths = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const weeks = Math.floor(daysAfterMonths / 7);
      const days = daysAfterMonths % 7;
    
      return { months, weeks, days, hours, minutes, seconds };
    };

      // Usar state para armazenar o countdown de cada evento
  const [countdowns, setCountdowns] = useState(
    filteredEvents.map(item => calculateCountdown(item.date))
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdowns(filteredEvents.map(item => calculateCountdown(item.date)));
    }, 1000); // Atualiza a cada 1 segundo

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, [filteredEvents]);

  useEffect(() => {
  
  }, []);
  var targetTime;

  const ShowCountdown = (e) => {
    //var target = new Date(e).getTime();
    console.log(e);
  }

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    const parts = dateStr.split("/");
    const day = parseInt(parts[0], 10);
    return day;
  }

  const getMonth= (dateStr) => {
    var dateParts = dateStr.split("/");

    const year = parseInt(dateParts[2]);
    const month = parseInt(dateParts[1])-1;
    const day = parseInt(dateParts[0]);
    
    var dateObject = new Date(year, month, day); 
    const m = dateObject.toLocaleString('pt-PT', { month: 'short' });
    return m
  }

  const setNewTime = () => {};

  useEffect(() => {
    const interval = setInterval(() => {
   
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  
  
  useEffect(() =>{

    $(".list").on('click',function(e){
      $(".list").removeClass("active");
      $(this).addClass("active");
    })

    $('.list').on('click',function(){
      const value = $(this).attr('data-filter');
      if(value === 'all'){
        $('.itemBox').show('1000');
      }
      else{
        $('.itemBox').not('.'+value).hide('1000');
        $('.itemBox').filter('.'+value).show('1000');
      }
    });
    const interval = setInterval(() => setNewTime(), 1000);
}, [])

const { t, i18n } = useTranslation('');
  return (
    <div id='#events' className='events'>
    <section id="gallery">

    <h4>{t('eventos.textTitle')}</h4>
    <h1 className='title_head'>{t('navbar.eventos')}</h1>

        <div className='filter-button'>
            <ul>
              <li className='list active' data-filter="all">{t('navbar.tudo')}</li>
              <li className='list' data-filter="2020">2020</li>
              <li className='list' data-filter="2021">2021</li>
              <li className='list' data-filter="2022">2022</li>
              <li className='list' data-filter="2023">2023</li>
              <li className='list' data-filter="2024">2024</li>
            </ul>
        </div>
        <div className='next-event'>
        <h5 className='next-events-title'>{t('eventos.proxEvents')}</h5>
        <hr className="divider hr-left-0 bg-bermuda"></hr>
        </div>
        <div className='all-events'>
       
        <div className="upcoming-events">
          <ul>
        {filteredEvents.map((item, index) => {
        const { months, weeks, days, hours, minutes, seconds } = countdowns[index];

        return (
          <li key={index}>
            <div className="time event-poster" style={{ backgroundImage: `url(${item.imgP})` }} data-img="event-poster"></div>
            <div className="details">
              <h3>{item.title}</h3>
              <p>{item.descri?.[i18n.language] || ''}</p>
              <div className="md:p-4 info-block">
                <div className="info-event">
                  <div>
                    <div className="place-event">
                      <i className="fa-solid fa-location-dot"></i> {item.city?.[i18n.language] || ''}, {item.country?.[i18n.language] || ''}
                    </div>
                    <div className="day-event">
                      <i className="fa-solid fa-calendar-days"></i> {item.date}
                    </div>
                  </div>
                </div>
                <div className="countdown">
                {t('eventos.faltam')} {months} {t('eventos.meses')}, {weeks} {t('eventos.semanas')}, {days}  {t('eventos.dias')}, {hours} {t('eventos.horas')}, {minutes} {t('eventos.minutos')}, {seconds} {t('eventos.segundos')}
                </div>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
          </li>
        );
      })}
          </ul>
      </div>

          <div className='list-events'>
            <ul className="events-list">
                {events.map((item)=>(
                    <li className={`${item.year} itemBox`} key={item.id}>
                    <div className='img-overlay'>
                      <Link to={`/evento/${item.title}`}><img src={item.imgP} width="100%" alt={item.title}/>
                        <div className="bottom-left">
                          <div className="thumbnail-info" style={{backgroundColor: 'var(--logo-bege)'}}>
                            <div className="thumbnail-date">
                              <span className="thumbnail-date-day">{getFormattedDate(item.date)}</span>
                              <span className="thumbnail-date-month">{getMonth(item.date)}</span>
                            </div>
                          </div>
                        </div>
                      <div className="badges">
                      <div className="badge top-right">
                        <div className="places text-uppercase">
                          <span>{item.city[i18n.language]}</span>
                        </div>
                      </div>
                      <div className="badge bottom-top">
                        <div className="price text-uppercase">
                            <p>{item.date}</p>
                        </div>
                      </div>
                    </div>
                    <div className="overlay-txt">
                      <div className="text">{item.title}</div>
                    </div>
                    </Link>
                    </div>
                  </li>

                ))}
            </ul> 
        </div>
        </div>
    </section>
    </div>
  )
}

export default Eventos