import React, { useEffect, useState, useRef, useCallback} from 'react' 
import  $ from 'jquery'
import { Link, Route, Routes } from 'react-router-dom'
import '../layout/Products.Module.css'
import { prods, cate } from '../prods'
import { ourBrands, reBrands } from '../brands'
import bgImg from '../images/ex2.jpg'
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import slugify from 'slugify'
import axios from 'axios'
import { useAuth } from './AuthContext';

function Marcas({resetPageFlag}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);


  const { t, i18n } = useTranslation('');
   
  const [products, setProducts] = useState([])
  const [language, setLanguage] = useState(i18n.language || 'pt'); // idioma padrão

    useEffect(() => {
    fetch('https://product-list-ten-drab.vercel.app/products')
      .then((res) => res.json())
      .then((prod) => {
        // Verifica se o valor retornado é um array
        if (Array.isArray(prod)) {
          setProducts(prod);
        } else {
          console.error('A API não retornou um array');
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    if(!data.length){
      fetch('https://product-list-ten-drab.vercel.app/api/data')
      .then(res => res.json())
      .then(result => {
        setData(Array.isArray(result) ? result : []);
      })
      .catch(err => console.log(err))
    }
  }, [data]);

const [listIps, setIps] = useState([])
useEffect(() => {
  fetch('https://api-murex-theta.vercel.app/ipList')
  .then(res => res.json())
  .then(ip => setIps(ip))
  .catch(err => console.log(err));
}, [listIps]);

  /**************MYSQL database****************** */
  const [categorias, setCategorias] = useState([])
/*
  useEffect(() => {
  
    fetch('https://api-murex-theta.vercel.app/categorias')
    .then(res => res.json())
    .then(categorias => setCategorias(categorias)
  )
    .catch(err => console.log(err));
  }, [categorias]);
*/

  useEffect(() => {
    fetch('https://api-murex-theta.vercel.app/categorias')
      .then(res => res.json())
      .then(data => {
        const translatedCategories = data.map(categoria => {
          const translatedCategoryName = t(`categorias.${categoria.nome}`) || categoria.nome;
  
          const translatedSubcategorias = categoria.subcategorias.map(subcategoria => {
            const translatedSubcategoryName = t(`${subcategoria.nome}`) || subcategoria.nome;
            //const translatedSubcategoryName = t(`categorias.${subcategoria.nome}`) || subcategoria.nome;
            return {
              ...subcategoria,
              nome: translatedSubcategoryName,
            };
          });
  
          return {
            ...categoria,
            nome: translatedCategoryName, // Nome traduzido
            nomeOriginal: categoria.nome, // Nome original para o link
            subcategorias: translatedSubcategorias,
          };
        });
  
        setCategorias(translatedCategories);
      })
      .catch(err => console.log(err));
  }, [t]); // Reexecuta quando a função de tradução muda

  /*
  
  */

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      }
    ]
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory([]); // Reseta as subcategorias quando uma nova categoria for selecionada
  };

    const handleSubcategoryChange = (e) => {
    const value = e.target.value;
    setSelectedSubcategory((prev) => {
      if (prev.includes(value)) {
        return prev.filter((item) => item !== value);
      } else {
        return [...prev, value];
      }
    });
  };

  const [selectedOptions, setSelectedOptions] = useState([]);
  /**************Get atributes of produt to use on filter*************/
  const handleCheckboxChange = (e) =>{
    const value = e.target.value;
    setSelectedOptions((prevSelected)=>{
      if(prevSelected.includes(value)){
        return prevSelected.filter((option)=> option !== value);
      } else{
        return [...prevSelected,value];
      }
    });
  };

  /*******Filtrar produtos da base de dados pela checkbox******/
  const filteredData = products.filter((item) => {
    if (selectedOptions.length === 0 && selectedCategory === null) {
      return true;
    } else {
      const subCategoriasArray = item?.subCategoria ? item.subCategoria.split(',') : [];
      const belongsToCategory = selectedCategory ? item.categoriaId === selectedCategory : true;
      return (
        belongsToCategory &&
        (selectedOptions.includes(item.nomeC) ||
          selectedOptions.includes(item.nomeSC) ||
          selectedOptions.includes(String(item.ip)) ||
          subCategoriasArray.some((subCat) => selectedOptions.includes(subCat.trim())) ||
          selectedOptions.includes(item.marca))
      );
    }
  });

const [tabActive, setTabActive] = useState(0);
const categoryIcon = document.querySelectorAll('.main-filter-list li');
//Tab active icon function
$('.main-filter-list li').on('click', function(e){
  $(this).addClass('active');
})

const allIndicator = document.querySelectorAll('.main-filter-list li');

allIndicator.forEach(item=> {
  item.addEventListener('click', function () {

    allIndicator.forEach(i=> {i.classList.remove('active'); })
    $(this).addClass('active');
  })
})

const [currentTab, setCurrentTab] = useState('1');
const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('currentPage'), 10) || 1);
const [itemPerPage, setItemPerPage] = useState(8);/*Items per page*/
const resetPage = () => setCurrentPage(1);

const pages = []
for(let i=1; i <= Math.ceil(filteredData.length / itemPerPage); i++){
    pages.push(i);
}

const totalPages = pages.length;
const pagesToShow = 5;
const halfPagesToShow = Math.floor(pagesToShow / 2);
let startPage = Math.max(currentPage - halfPagesToShow, 1);
let endPage = Math.min(startPage + pagesToShow - 1, totalPages);
let lastIndexPage = currentPage * itemPerPage;
let firstItemPage = lastIndexPage - itemPerPage;
const listProds = filteredData.slice(firstItemPage,lastIndexPage)

if (endPage - startPage < pagesToShow - 1) {
  startPage = Math.max(endPage - pagesToShow + 1, 1);
}

const mpages = [];
for (let i = startPage; i <= endPage; i++) {
  mpages.push(i);
}

useEffect(() => {
  localStorage.setItem('currentPage', currentPage);
}, [currentPage]);

// Observa mudanças em 'resetPageFlag' para forçar o reset da página
useEffect(() => {
  setCurrentPage(1); // Sempre que resetPageFlag mudar, volta para página 1
}, [resetPageFlag]);

const onPageChange = (page) => setCurrentPage(page);

function handleTabClick(id){
  setCurrentTab(id); // Atualiza a categoria ativa
  setSelectedOptions([]); // Limpa as subcategorias selecionadas ao trocar de categoria
}

  return (
    <div className='Produtos'>

      <div className="homepage-intro has-bg-img position-relative">
        <h2 className="text-center text-white position-absolute top-50 start-50 translate-middle">{t('produtos.todosP')}</h2>
        <picture className="darkened-image">
          <source media="(max-width: 925px)" srcSet={bgImg} />
          <img className="bg-img bg-cover" alt="img-intro-listagem" src={bgImg} />
        </picture>
      </div>

      <div className='filter-subcategory'>
        <div id='filter-bar'>
          <div className='container scroll-categories'>
          <ul id="main-filter-list" className="main-filter-list nav nav-tabs cats_list" role="tablist">
            <li className tabIndex={-1} role="option">
        </li>
        {/*Lista de categorias e tabs com subcategorias*/}
      <Slider {...settings}>
      {categorias.map((tab,index)=>(
        <li className={`item-category` + currentTab === tab.id ? "active-tab": "tabs"} key={index} id={tab.id} onClick={()=> handleTabClick(tab.id)}>
          <a>
          <span>
            <img className="filter-bar-icons" src={tab.icone} alt={tab.nome}/>
            <span>{tab.nome}</span>
          </span>
          </a>
        </li>
        ))}
        </Slider>
        </ul>

      </div>
        </div>
        <div className="padding-bottom-20 product_list">
         <div className="tab-content plist">
            <div role="tabpanel" className="tab-pane active">
              <div className="ins-filter-wrapper">
                <div className="container">
                  <div className="col-xs-12 padding-0">
                    {/*Quando chamar as categorias fazer uma query para as subcategorias*/}
                  <div className="padding-top-bottom-10 col-xs-12 col-md-12 col-lg-12 pfilters">
                  {categorias.map((tab, i) => (
                    <div
                      key={tab.id}
                      id={tab.id}
                      className={currentTab === tab.id ? "active-content" : "noactive-content"}>
                      {/* Verifica se a categoria atual é a selecionada */}
                      {selectedCategory === null || selectedCategory === tab.id ? (
                        tab.subcategorias?.map((cont, i) => (
                          <span className="subCategoryItem" key={i}>
                            <input
                              type="checkbox"
                              className="css-checkbox"
                              id={cont.nome}
                              name={cont.nome}
                              value={cont.nome}
                              checked={selectedOptions.includes(cont.nome)}
                              onChange={handleCheckboxChange}
                            />
                            <label className="checkbox_label col-xs-6 col-sm-3 col-md-2" htmlFor={cont.nome}>
                              {cont.nome}
                            </label>
                          </span>
                        ))
                      ) : null}
                    </div>
                  ))}
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl mx-auto m-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb"> 
            <li className="breadcrumb-item"><a className="text-decoration-none text-uppercase" href="#">{t('homepage.produtos')}</a></li>
            <li className="breadcrumb-item active text-uppercase" aria-current="page">Todos</li>
          </ol>
        </nav>

        <div className="d-flex justify-content-between align-items-md-center mb-3 py-2 py-lg-0 prod-list-top-btns">
          <div className="dropdown btn-open-filters">
            <button className="btn btn-secondary open-filters d-md-none mr-auto fw-bold text-uppercase" data-bs-toggle="offcanvas" href="#prodFilters" role="button" aria-controls="prodFilters">{t('produtos.filtro')}</button>
          </div>

        </div>

        <div className="row">
          <div className="col-12 col-md-3 col-xl-2 prod-list-filter-wrapper offcanvas offcanvas-start" tabIndex={-1} id="prodFilters" aria-labelledby="prodFilters">
            <div className="offcanvas-header d-lg-none pe-0">
              <button className="btn ms-auto px-3" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><i className="fa-solid fa-backward" /></button>
            </div>
            <div className="offcanvas-body p-3 pt-2 pb-3">
              <div className="filter-block">
                <button className="filter-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filterMarcas" aria-expanded="false" aria-controls="filterMarcas">{t('navbar.marcas')}</button>
                <div className="prod-list-filter-list collapse show" id="filterMarcas">
                    <div className="custom-scroll">
                    {ourBrands.map((item,index)=>(
                        <label className="material-checkbox text-capitalize" key={index}>
                        <input type="checkbox" id={item.name} name={item.name} value={item.name} checked={selectedOptions.includes(item.nome)} onChange={handleCheckboxChange}/>
                        <span className="checkmark"></span>
                        {item.name}
                        </label>
                    ))} 

                    {reBrands.map((item,index)=>(
                        <label className="material-checkbox text-capitalize" key={index}>
                        <input type="checkbox" id={item.name} name={item.name} value={item.name} checked={selectedOptions.includes(item.name)} onChange={handleCheckboxChange}/>
                        <span className="checkmark"></span>
                        {item.name}
                        </label>
                    ))} 

                    </div>
                </div>

              <hr className="card-line w-100" />
              <div className="filter-block">
                <button className="filter-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filterIp" aria-expanded="false" aria-controls="filterIp">{t('produtos.ip')}</button>
                <div className="prod-list-filter-list collapse" id="filterIp">
                  <div className="custom-scroll">
                  {listIps.map((item,index)=>(
                    <label className="material-checkbox" key={index}>
                    <input type="checkbox" id={item.ip} value={item.ip.toString()} checked={selectedOptions.includes(item.ip.toString())} onChange={handleCheckboxChange}/>
                    <span className="checkmark"></span>
                    {item.ip}
                  </label>
                    ))}
                  </div>
                </div>
              </div>

              <hr className="card-line w-100" />
              <div className="filter-block">
                <button className="filter-title collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filterType" aria-expanded="false" aria-controls="filterType">{t('produtos.tipo')}</button>
                <div className="prod-list-filter-list collapse" id="filterType">
                  <div className="custom-scroll">
                  {categorias.map((item)=>(
                    <label  className="material-checkbox text-capitalize" key={item.id}>               
                    <input type="checkbox" id={item.nome} value={item.nomeOriginal} checked={selectedOptions.includes(item.nomeOriginal)} onChange={handleCheckboxChange}/>
                     <span className='checkmark'></span>
                     {item.nome} 
                    </label>
                    ))}
                  </div>
                </div>
              </div>

              </div>

            </div>
          </div>

          <div className="col-12 col-md prod-list-content-wrapper">
            <div className="row g-4 prod-list-wrapper position-relative" id="prod-list">
            <span className='total-prods'>{t('listProds.existem')} <b>{filteredData.length}</b> {t('homepage.produtos')}</span>
              {listProds.map((resu,index)=>
              {
                const productReferences = data.find(ref => ref.idP === resu.idP) || {};
                let references = [];

                // Se prodEspe for uma string, converta em um array (separando por vírgula)
                if (typeof productReferences.prodEspe === 'string') {
                references = productReferences.prodEspe.split(',').map(ref => ref.trim());
                }
                // Se prodEspe for um array, use-o diretamente
                else if (Array.isArray(productReferences.prodEspe)) {
                references = productReferences.prodEspe;
                }

                return(
                
                  <div className="col-sm-6 col-md-3 col-6" key={index}>
                  <div className="shop__thumb">
                    <Link to={`/prodetail/${resu.idP}`} key={resu.idP} target="_blank">
                      <div className="shop-thumb__img">
                        <img src={resu.imgP} className="img-responsive" alt="..." />
                      </div>
                      <h3 className="shop-thumb__title"><b>{resu.nome}</b></h3>
                      <h5 className="shop-thumb-brand">{resu.marca}</h5>
                      <h5 className="shop-thumb-ref">
                        <span className='references'>Refs:</span>&nbsp;
                        {references.length > 0 ? (
                          references.slice(0, 3).map((ref, idx) => (
                            <span key={idx} className="product-reference">
                              {ref}{idx < references.slice(0, 3).length - 1 && ', '}
                            </span>
                          ))
                        ) : (
                          <span><></></span>
                        )}
                      </h5>
                      {(resu?.ip != null) && (<h6 className="shop-thumb__title"> <b>IP:{resu.ip}</b></h6>)}
                      <div className="c-product-card__wrapper-3">
                        <ul className="c-product-card__certificates mb-2 xl:mb-3 flex flex-wrap gap-2">
                          <li><img src="https://cdn1.efectoled.com/img/co/fetval_rohs.png" alt="Product card certificates" width="1" height="1"></img></li>
                          <li><img src="https://cdn1.efectoled.com/img/co/fetval_ce.png" alt="Product card certificates" width="1" height="1"></img></li>
                        </ul>
                      </div>
                    </Link>
                  </div>
                </div>
                )
              })}

            </div>
          </div>

        <div className='center-pagination'>
            <section className="pagination-section p-5 bd-highlight">
            <ul className="pagination-prods">
            <li>
            <button id="pg-button-prev" type="button" className="pagination__button" 
            onClick={()=>onPageChange(currentPage-1)} disabled={currentPage ===1}>
              <i className="fa fa-chevron-left" />
            </button>
            </li>

            {startPage > 1 &&(
              <>
              <button className='pagination__button_ellipses' onClick={()=>onPageChange(1)}>1</button>
              {startPage > 2 && <span className='pagination__button_ellipses'>...</span>}
              </>
            )}
              {
                mpages.map((page,index)=>{
                    return(
                    <li className={page ===  currentPage ? 'active' : ''} key={index} 
                    onClick={()=>onPageChange(page)}>
                      <button id="pg-button-1" type="button">{page}</button>
                    </li>
                    )
                  })
              }
            
            {endPage <totalPages &&(
              <>
              {endPage < totalPages -1 && <button className="pagination__button_ellipses">...</button>}
              <button className="pagination__button_ellipses" type="button" onClick={()=> onPageChange(totalPages)}>
                {totalPages}
              </button>
              </>
            )}
            <li>

            <button id="pg-button-next" type="button" className="pagination__button" 
              onClick={()=>onPageChange(currentPage+1)} disabled={currentPage === Math.ceil(filteredData.length / itemPerPage)}>
              <i className="fa fa-chevron-right" />
            </button>
            </li>

            </ul>
          </section>
        </div>

        </div>
      </div>
  
    </div>
  )
}

export default Marcas