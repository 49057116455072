import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [warningTimeoutId, setWarningTimeoutId] = useState(null); // Para gerenciar o tempo de aviso
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [message, setMessage] = useState(''); 
  const navigate = useNavigate();
  const location = useLocation();

  const idleTimeout = 15 * 60 * 1000; // 15 minutos de inatividade
  const warningTimeout = 1 * 60 * 1000; // 1 minuto de aviso
  let inactivityTimeout;

    // Exibir aviso de logout próximo

  // Carregar usuário do localStorage ao iniciar
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Define o usuário a partir do localStorage
      setIsLoggedOut(false); // Garante que o usuário não esteja marcado como desconectado
    }
  }, []);
  
  const logout = useCallback(() => {
    if (user && !isLoggedOut) {
      setUser(null);
      setIsLoggedOut(true);
      localStorage.removeItem('user'); // Remove o usuário do localStorage
      setMessage('Você foi desconectado.');

      // Redireciona para a página principal apenas se estiver em /area-pessoal
      if (location.pathname === '/area-pessoal') {
        navigate('/'); // Redireciona para a página principal
      }
    }
  }, [user, isLoggedOut, navigate, location.pathname]);

    /*
       const logout = useCallback(() => {
    if (user && !isLoggedOut) {
      setUser(null);
      setIsLoggedOut(true);
      localStorage.removeItem('user');
      localStorage.removeItem('lastActivity');
      if (timeoutId) clearTimeout(timeoutId);
      if (warningTimeoutId) clearTimeout(warningTimeoutId);
      setMessage('Você foi desconectado por inatividade.');
    }
  }, [user, isLoggedOut, timeoutId, warningTimeoutId]);
    */
  

    // Exibir aviso de logout próximo
    const showWarning = useCallback(() => {
      setMessage('Você será desconectado por inatividade em 1 minuto. Deseja continuar logado?');
      const newWarningTimeoutId = setTimeout(() => {
        logout();
      }, warningTimeout);
      setWarningTimeoutId(newWarningTimeoutId);
    }, [logout, warningTimeout, warningTimeoutId]);



  // Função de login
  const login = (userData) => {
    setUser(userData);
    setIsLoggedOut(false); // Garante que o usuário não esteja marcado como desconectado ao logar
    localStorage.setItem('user', JSON.stringify(userData));
    setMessage(''); // Limpa qualquer mensagem de notificação
  };

  // Função para verificar se o tempo de inatividade foi excedido
  // Verifica se o tempo de inatividade foi excedido

  /*

    const resetTimeout = useCallback(() => {
    if (!user) return;
  
    if (timeoutId) clearTimeout(timeoutId);
    if (warningTimeoutId) clearTimeout(warningTimeoutId);
  
    // Configura o aviso e o timeout para logout
    const newTimeoutId = setTimeout(showWarning, idleTimeout - warningTimeout);
    setTimeoutId(newTimeoutId);
    
    localStorage.setItem('lastActivity', Date.now());
    setIsLoggedOut(false);
  }, [user, showWarning, idleTimeout, warningTimeout, timeoutId, warningTimeoutId]);

    const checkInactivity = useCallback(() => {
    const lastActivity = localStorage.getItem('lastActivity');
    if (lastActivity) {
      const timeElapsed = Date.now() - parseInt(lastActivity, 10);
      if (timeElapsed > idleTimeout) {
        logout(); // Logout após 15 minutos de inatividade
      }
    }
  }, [logout, idleTimeout]);

  // Carrega o usuário do localStorage e verifica a inatividade ao iniciar
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    const events = ['click', 'mousemove', 'keydown'];

    const resetOnActivity = () => {
      if (user) {
        resetTimeout(); // Reseta o timeout se o usuário estiver logado
      }
    };

    // Adiciona listeners para eventos de atividade do usuário
    events.forEach(event => window.addEventListener(event, resetOnActivity));

    // Intervalo para checar a inatividade
    const inactivityCheckInterval = setInterval(checkInactivity, 1000); // Checa a cada segundo

    return () => {
      // Remove listeners e limpa o intervalo quando o componente for desmontado
      events.forEach(event => window.removeEventListener(event, resetOnActivity));
      clearInterval(inactivityCheckInterval);
      if (timeoutId) clearTimeout(timeoutId);
      if (warningTimeoutId) clearTimeout(warningTimeoutId);
    };
  }, [user, resetTimeout, checkInactivity]);
  */

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
      {message && <div className="notification">{message}</div>} {/* Exibe a mensagem de forma não bloqueante */}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};