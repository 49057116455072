import React from 'react'
import '../layout/PoliPri.Module.css'

function PoliPriva() {
  return (
    <div className='container PoliPriva'>
        <div className='text'>
        <h1>Política de Privacidade</h1>

            <p>
            <strong>1. INFORMAÇÃO E CONSENTIMENTO</strong>
            <br></br>
            </p>

            <p>
            A Lei da Proteção de Dados Pessoais (em diante “LPDP”) e o Regulamento Geral de Proteção de Dados 
            (Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, em diante “RGPD”) 
            asseguram a proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados.
            </p>

            <p>
            Nos termos legais são considerados “dados pessoais” qualquer informação, de qualquer natureza e independentemente do respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular 
            identificada ou identificável, pelo que a proteção não abrange os dados de pessoas coletivas.
            </p>

            <p>
            Mediante a aceitação da presente Política de Privacidade o utilizador presta o seu consentimento informado, expresso, livre e inequívoco para que os dados pessoais fornecidos através 
            do site https://www.advance-rr.com/ sejam incluídos num ficheiro da responsabilidade da Advance Lighting, cujo tratamento nos termos da LPDP e do RGPD cumpre as medidas de segurança técnicas e organizativas adequadas.
            </p>

            <p>
            A Advance Lighting, mantém uma base de dados com o registo dos seus clientes. Os dados presentes nesta base são unicamente os dados prestados pelos próprios na altura do seu registo,
            sendo recolhidos e processados automaticamente, nos termos aprovados pela Comissão Nacional de Proteção de Dados, pela Advance Lighting, entidade responsável pelo correspondente ficheiro.
            </p>

            <p>
            Em caso algum será solicitada informação sobre convicções filosóficas ou políticas, filiação partidária ou sindical, fé religiosa, vida privada e origem racial ou étnica bem como os dados relativos à saúde e à vida sexual, incluindo os dados genéticos.
            Em caso algum levaremos a cabo qualquer das seguintes atividades com os dados pessoais que nos sejam facultados através deste site:
            </p>
            <ul>
                <li>Ceder a outras pessoas ou outras entidades, sem o consentimento prévio do titular dos dados;</li>
                <li>Transferir para fora do Espaço Económico Europeu (EEE), sem o consentimento prévio do titular dos dados.</li>
            </ul>

            <br></br>
            <p>
            <strong>2. FINALIDADES DO TRATAMENTO DE DADOS PESSOAIS</strong>
            <br></br>
            </p>

            <p>Os dados pessoais que tratamos através desta página serão unicamente utilizados para as seguintes finalidades:</p>

            <ul>
            (i) Execução e gestão de contrato de compra e venda ou de prestação de serviços;
                <li>(ii) Processamento de encomendas;</li>
                <li>(iii) Comunicação com clientes e esclarecimento de dúvidas;</li>
                <li>(iv) Processamento de pedidos de informação;</li>
                <li>(v) Processamento de reclamações, Processos de Reguladores, Processos pré-contencioso ou exercício de direitos em processos judiciais;</li>
                <li>(vi) Atividades de análise estatística;</li>
                <li>(vii) Verificar, manter e desenvolver sistemas e análises estatísticas;</li>
                <li>(viii) Comunicações de marketing direto (caso tenha consentido no tratamento dos seus dados pessoais para esta finalidade);</li>
                <li>(ix) Prevenção e combate à fraude;</li>
                <li>(x) Solicitação de comentários a produtos ou serviços adquiridos;</li>
                <li>(xi) Realização de inquéritos de satisfação.</li>
                <li>(xii) Gestão e participação em eventos, passatempos e campanhas.</li>
            </ul>

            <p>A Advance garante a confidencialidade de todos os dados fornecidos pelos seus clientes. Não obstante a Advance proceder à recolha e ao tratamento de dados de forma segura e que impede a sua perda ou manipulação, 
                utilizando as técnicas mais aperfeiçoadas para o efeito, informamos que a recolha em rede aberta permite a circulação dos dados pessoais sem condições de segurança, correndo o risco de ser vistos e utilizados por terceiros não autorizados.</p>

            <p>Por outro lado, o utilizador consente que se possa aceder à informação relativa ao serviço contratado com a Advance Lighting com o fim de lhe poder oferecer serviços adicionais ao contratado.</p>

            <p>No momento da recolha dos dados pessoais, salvo nos campos em que for indicado o contrário, o utilizador poderá, voluntariamente, disponibilizar os dados pessoais, sem que a falta de resposta implique um decréscimo na qualidade 
                ou quantidade dos serviços correspondentes (a menos que esteja indicada outra coisa). Não obstante, a falta de resposta aos dados, considerados obrigatórios, implicará a impossibilidade de aceder ao serviço para o qual os dados foram solicitados.</p>

            <p>Caso não concorde com as condições acima referidas, a Advance Lighting não poderá contratar com o utilizador através da sua página Web.</p>

            <p>
            <strong>3. CESSÃO DE DADOS PESSOAIS</strong>
            <br></br>
            </p>

            <p>Por forma a poder cumprir com o objeto do presente website, a Advance Lighting irá ceder os seus dados pessoais a outras entidades, que os irão tratar, para as seguintes finalidades:</p>

            <ul>
                <li>Atividades de gestão e processamento de pagamentos;</li>
                <li>Prestação dos serviços contratados.</li>
                <li>Gestão e participação em eventos, passatempos e campanhas.</li>
            </ul>

            <p>As entidades a quem a Advance Lighting irá ceder os seus dados pessoais para os tratarem nos termos acima referidos terão a seguinte natureza:</p>

            <ul>
                <li>Entidades seguradoras;</li>
                <li>Entidades terceiras relacionadas com a prestação dos serviços contratados;</li>
                <li>Entidades de gestão e processamento de pagamentos.</li>
            </ul>

            <br></br>
            <p>
            <strong>4. MEDIDAS DE SEGURANÇA</strong>
            <br></br>
            </p>

            <p>A Advance Lighting declara que implementou e continuará a implementar as medidas de segurança de natureza técnica e organizativa necessárias para garantir a segurança dos dados de carácter pessoal
             que lhe sejam fornecidos visando evitar a sua alteração, perda, tratamento e/ou acesso não autorizado, tendo em conta o estado atual da tecnologia, a natureza dos dados armazenados e os riscos a que estão expostos.</p>

             <p>A Advance Lighting garante a confidencialidade de todos os dados fornecidos pelos seus clientes quer no registo, quer no processo de compra/encomenda de produtos ou serviços. A recolha e tratamento de dados realiza-se de 
                forma segura e que impede a sua perda ou manipulação. Todos os dados serão inseridos num Servidor Seguro (SSL de 128 bits) que os encripta/codifica (transforma num código). O utilizador poderá verificar que o seu browser 
                é seguro se o símbolo do cadeado aparecer ou se o endereço começar com https em vez de http.</p>

            <p>Os dados pessoais são tratados com o nível de proteção legalmente exigível para garantir a segurança dos mesmos e evitar a sua alteração, perda, tratamento ou acesso não autorizado, tendo em conta o estado da tecnologia, 
            sendo o utilizador consciente e aceitando que as medidas de segurança em Internet não são inexpugnáveis.</p>

            <p>A Advance Lighting, sempre que aceda a qualquer dado pessoal, compromete-se a:</p>   

            <p>Armazená-los por intermédio de medidas de segurança, legalmente exigíveis, de natureza técnica e organizacional, que garantam a sua segurança, evitando assim a alteração, perda, tratamento ou acesso não autorizado,
                 em conformidade com o estado da tecnologia em cada momento, a natureza dos dados e os possíveis riscos a que estejam expostos;</p>

            <p>Utilizar os dados exclusivamente para as finalidades previamente definidas;</p>  

            <p>Certificar-se de que os dados são tratados unicamente pelos trabalhadores cuja intervenção seja necessária para a prestação do serviço estando os mesmos obrigados ao dever de sigilo e confidencialidade.
             Existindo a possibilidade de a informação ser revelada a terceiros, estes devem ser obrigados a guardar a devida confidencialidade em conformidade com o previsto neste documento.</p>  

             <br></br>
            <p>
            <strong>5. COMUNICAÇÕES COMERCIAIS E PROMOCIONAIS</strong>
            <br></br>
            </p>

            <p>Um dos propósitos para os quais tratamos dados pessoais fornecidos pelos utilizadores é enviar comunicações eletrónicas com informações relativas a comunicações comerciais e promocionais.</p>  

            <p>Sempre que fizermos uma comunicação deste tipo, será direcionado exclusivamente aos utilizadores que as tenham autorizado previamente e de forma expressa.</p>  

            <p>De acordo com o disposto na Decreto-Lei n.º 7/2004, de 7 de janeiro, no caso de o utilizador desejar deixar de receber comunicações comerciais ou promocionais da Advance Lighting, 
            poderá solicitar a oposição do serviço enviando um e-mail para o seguinte formulário Web: <a href='/Contactos'>www.advance-rr.com/contactos</a></p>  

            <br></br>
            <p>
            <strong>6. EXERCÍCIO DOS DIREITOS</strong>
            <br></br>
            </p>

            <p>De acordo com as disposições da LDPD e do RGPD, o utilizador pode exercer a todo o tempo os seus direitos de acesso, retificação, apagamento, limitação, oposição e portabilidade, através de solicitação por:</p>  

            <ul>
            <li><a href="mailto:info@gruporr.pt">info@gruporr.pt</a> </li>
            </ul>

            <br></br>
            <p>Caso o utilizador deseje, a qualquer momento, deixar de fazer parte da base de dados Advance poderá exercer esse direito através deste contacto.</p>  
        </div>
      </div>
  )
}

export default PoliPriva