import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../layout/RedefinirSenha.Modulo.css'
import logob from '../images/logos/logo-d-azul.png'
import logoBranco from '../images/logos/Logo com Avatar br.png'
import poster from '../images/logos/aplique parede_rgb_1.jpg'
function RedefinirSenha() {
    const { token } = useParams();
    console.log("Token recebido na URL:", token);
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://advancetest.advance-rr.com/redefinir_senha.php', {
                token: token,
                new_password: newPassword,
            },
            {
                headers: { 'Content-Type': 'application/json' },
            }
        );

            setMessage(response.data.message);
        } catch (error) {
            console.error('Erro ao redefinir senha:', error);
            setMessage('Erro ao redefinir senha. Tente novamente mais tarde.');
            console.log("Token enviado:", token);
        }
    };

  return (
    <div className='container RedefinirSenha'>
        <div className="mainDiv">
            <div className="cardStyle">
            <form action method="post" name="signupForm" id="signupForm" onSubmit={handleSubmit}>
                <img src={logob} id="signupLogo" />
                <h4 className="formTitle mt-4"> Redefinir Palavra Passe</h4>
                <div className="inputDiv">
                <label className="inputLabel mt-3" htmlFor="password">Nova Palavra Passe</label>
                <input type="password" placeholder="Nova senha"  value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                
                </div>
                <div className="buttonWrapper">
                <button type="submit" id="submitButton" onclick="validateSignupForm()" className="submitButton pure-button pure-button-primary">
                    <span>Alterar palavra passe</span>
                </button>
                {message && <p>{message}</p>}
                </div>
            </form>
            </div>
        </div>    
    </div>
  )
}

export default RedefinirSenha