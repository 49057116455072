import React, { useEffect, useState, useRef, useCallback} from 'react' 
import { useParams, useLocation } from 'react-router-dom';
import { Link, Route, Routes } from 'react-router-dom';
import '../layout/ListProds.Module.css';
import { cate, prods } from '../prods';
import { material, ips } from '../filters';
import { useTranslation } from 'react-i18next';

 function ListProdus(props) {
 //https://api-murex-theta.vercel.app/products 
  const [products, setProducts] = useState([])
  const [combinedProducts, setCombinedProducts] = useState([]);

  useEffect(() => {
    fetch('https://product-list-ten-drab.vercel.app/products')
    .then(res => res.json())
    .then(prod => {
      setProducts(Array.isArray(prod) ? prod : []);
    })
    .catch(err => console.log(err));
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    if(!data.length){
      fetch('https://product-list-ten-drab.vercel.app/api/data')
      .then(res => res.json())
      .then(result => {
        setData(Array.isArray(result) ? result : []);
      })
      .catch(err => console.log(err))
    }
  }, [data]);

  const [listIps, setIps] = useState([])
  useEffect(() => {
    fetch('https://api-murex-theta.vercel.app/ipList')
    .then(res => res.json())
    .then(ip => setIps(ip))
    .catch(err => console.log(err));
  }, [listIps]);

  const [listIk, setIk] = useState([])
  useEffect(() => {
    fetch('https://backend-theta-gold-17.vercel.app/ikList')
    .then(res => res.json())
    .then(ik => setIk(ik))
    .catch(err => console.log(err));
  }, [listIk]);

const [brands, setBrand] = useState([])
useEffect(() => {
  if(!brands.length){
    fetch('https://product-list-ten-drab.vercel.app/listBrands')
    .then(res => res.json())
    .then(result => {
      setBrand(Array.isArray(result) ? result : []);
    })
    .catch(err => console.log(err))
  }
}, [brands]);

const [cor, setCor] = useState([])
useEffect(() => {
    fetch('https://api-murex-theta.vercel.app/cor')
    .then(res => res.json())
    .then(cor => setCor(cor))
    .catch(err => console.log(err))
}, [cor]);
    /*******************Usar paginação em 2 listas, dos clique e pesquisa********************* */

    const [categoryName, setCategoryName] = useState('');
    const { t, i18n } = useTranslation();

    const location = useLocation();
    const searchItem = location.state;

    const { brand, category} = useParams(); 
    const searchValue = data.filter((pro) => pro.nome?.toLowerCase().includes(searchItem?.toLowerCase())
           || pro.prodEspe?.includes(searchItem));

  {/* console.log(products.filter((item) => item.nomeC === category)) */}        
  const searchCategory = products.filter((item) => item.nomeC === category); 
  const searchCategoryBrand = products.filter((item) => item.marca === brand && item.nomeC === category); 

const [selectedOptions, setSelectedOptions] = useState([]);
  
const handleCheckboxChange = (e) =>{
  const value = e.target.value;
  setSelectedOptions((prevSelected)=>{
    if(prevSelected.includes(value)){
      return prevSelected.filter((option)=> option !== value);
    } else{
      return [...prevSelected,value];
    }
  });
};

var filterVariable;
if(searchItem != null){
  filterVariable = searchValue;
} else if(brand != null){
  filterVariable = searchCategoryBrand;
} else {
  filterVariable = searchCategory;
}
  const filteredData = filterVariable.filter((item)=>{
    if(selectedOptions.length==0){
      return true;
    }else{
      return (
        ( (selectedOptions.includes(String(item?.ip))) || (selectedOptions.includes(item?.marca)) || (selectedOptions.includes(String(item.ik))) 
        ||(selectedOptions.includes(item?.nomeC)) ||(selectedOptions.includes(String(item?.novo))) ||(selectedOptions.includes(item?.cor))
        )
      );
    }
  })

  const showProds = ()=>{
    console.log(searchValue);
  }
  const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem('listProdsCurrentPage'), 10) || 1);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [itemPerPage, setItemPerPage] = useState(8); /*Items per page*/
  
const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);
  
const pages = [];
for(let i = 1; i <= Math.ceil(filteredData.length / itemPerPage); i++){
    pages.push(i);
}

const uniqueFilteredData = filteredData.filter(
  (item, index, self) =>
    index === self.findIndex((t) => t.idP === item.idP)
);

const totalPages = pages.length;
const pagesToShow = 5;
const halfPagesToShow = Math.floor(pagesToShow / 2);
let startPage = Math.max(currentPage - halfPagesToShow, 1);
let endPage = Math.min(startPage + pagesToShow - 1, totalPages);
let lastIndexPage = currentPage * itemPerPage;
let firstItemPage = lastIndexPage - itemPerPage;
const listProds = uniqueFilteredData.slice(firstItemPage, lastIndexPage);

if (endPage - startPage < pagesToShow - 1) {
  startPage = Math.max(endPage - pagesToShow + 1, 1);
}

const mpages = [];
for (let i = startPage; i <= endPage; i++) {
  mpages.push(i);
}

useEffect(() => {
  if (!isFirstLoad) {
    setCurrentPage(1); // Reset para a primeira página somente em busca/seleção nova
  } else {
    setIsFirstLoad(false); // Marca que o primeiro carregamento terminou
  }
}, [brand, category, searchItem]);

// Salva a página atual no localStorage quando ela mudar
useEffect(() => {
  localStorage.setItem('listProdsCurrentPage', currentPage);
}, [currentPage]);

const onPageChange = (page) => setCurrentPage(page);
// Resetar para a primeira página ao entrar no componente

const translatedCategory = t(`categorias.${category}`) || category; 
  return (
    <div className='listProds'> 

     <div className='container title-container'>
     <div className='name-category'> 
         <h2 className='text-center category-name'>{category != undefined ? translatedCategory : null}</h2>
         <h2 className='text-center brand-name'>{brand}</h2>
        </div>
     </div>

      <div className="container-xxl mx-auto m-4">
      <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="text-decoration-none text-uppercase" href="#">{t('homepage.produtos')}</a></li>
            <li className="breadcrumb-item active text-uppercase" aria-current="page">{t('listProds.pesqui')}</li>
          </ol>
        </nav>

        <div className="d-flex justify-content-between align-items-md-center mb-3 py-2 py-lg-0 prod-list-top-btns">
          <div className="dropdown btn-open-filters">
            <button className="btn btn-secondary open-filters d-md-none mr-auto fw-bold text-uppercase" data-bs-toggle="offcanvas" href="#prodFilters" role="button" aria-controls="prodFilters">{t('listProds.filtrar')}</button>
          </div>
        </div>

        <div className="row">
        <div className="col-12 col-md-3 col-xl-2 prod-list-filter-wrapper offcanvas offcanvas-start" tabIndex={-1} id="prodFilters" aria-labelledby="prodFilters">
            <div className="offcanvas-header d-lg-none pe-0">
              <button className="btn ms-auto px-3" type="button" data-bs-dismiss="offcanvas" aria-label="Close"><i className="fa-solid fa-backward" /></button>
            </div>
          <div className="offcanvas-body p-3 pt-2 pb-3">
          <div className="filter-block">
            {/* Filter */}
            <form className="shop__filter">

              <h4 className="block_title">{t('listProds.filtrarpor')}</h4>
              {/* Checkboxes */}
            
              <div className="filter-block">
                <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterIp" aria-expanded="false" aria-controls="filterIp">{t('produtos.ip')}</button>
                <div className="prod-list-filter-list collapse show" id="filterIp">
                <div className="custom-scroll">
                {listIps.map((item,index)=>(
                    <label className="material-checkbox" key={index}>
                      <input type="checkbox" id={item.ip} value={item.ip.toString()} checked={selectedOptions.includes(item.ip.toString())} onChange={handleCheckboxChange}/>
                      <span className="checkmark"></span>
                      {item.ip}
                    </label>
                  ))}
                </div>
              </div>
              </div>

              <hr className="card-line w-100" />
              <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterMarcas" aria-expanded="false" aria-controls="filterMarcas">{t('navbar.marcas')}</button>
              <div className="prod-list-filter-list collapse" id="filterMarcas">
                <div className="custom-scroll">
              {brands.map((item,index)=>(
                <label className="material-checkbox text-capitalize" key={index}>
                <input type="checkbox" id={item.marca} name={item.marca} value={item.marca} checked={selectedOptions.includes(item.marca)} onChange={handleCheckboxChange}/>
                <span className="checkmark"></span>
                {item.marca}
                </label>
              ))} 
                </div>
              </div>

              <hr className="card-line w-100" />
              <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterIk" aria-expanded="false" aria-controls="filterIk">{t('listProds.ik')}</button>
              <div className="prod-list-filter-list collapse" id="filterIk">
                <div className="custom-scroll">
              {listIk.map((item,index)=>(
                <div className={`checkbox`} key={index}>
                  <input type="checkbox" id={item.ik} value={item.ik.toString()} checked={selectedOptions.includes(item.ik.toString())} onChange={handleCheckboxChange}/> 
                  <label className="filter-field" htmlFor={item.ik}>{item.ik}</label>
                </div>
              ))} 
               </div>
              </div>

              <hr className="card-line w-100" />

              <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterNovo" aria-expanded="false" aria-controls="filterNovo">{t('listProds.novo')}</button>
              <div className="prod-list-filter-list collapse" id="filterNovo">
              <div className="checkbox">
                <input type="checkbox" id={'1'} value={'1'} checked={selectedOptions.includes('1')} onChange={handleCheckboxChange}/>
                <label className='material-checkbox text-capitalize' htmlFor={'1'}>Novo</label>
              </div>
              </div>

              {/* 
             <hr className="card-line w-100" />
              <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterMaterial" aria-expanded="false" aria-controls="filterMaterial">{t('pdetail.mate')}</button>
              <div className="prod-list-filter-list collapse" id="filterMaterial">
                <div className="custom-scroll">
                {material.map((item,index)=>(
                <div className="checkbox" key={index}>
                <input type="checkbox" id={item.nome} value={item.nome} />
                <label className='material-checkbox text-capitalize' htmlFor={item.nome}>{item.nome}</label>
                </div>
                ))}
                </div>
              </div>
              */}
              {/* Colors */}

              <hr className="card-line w-100" />
              <button className="filter-title collapsed headline" type="button" data-bs-toggle="collapse" data-bs-target="#filterCor" aria-expanded="false" aria-controls="filterCor">{t('pdetail.cor')}</button>
              <div className="prod-list-filter-list collapse" id="filterCor">
                <div className="custom-scroll">
                <div className='colors-checkbox'>
                  {cor.map((item, index) => (
                  <label className="material-checkbox text-capitalize" key={index}>
                    <input 
                      type="checkbox" 
                      id={item.cor} 
                      name={item.cor} 
                      value={item.cor} 
                      checked={selectedOptions.includes(item.cor)} 
                      onChange={handleCheckboxChange}/>
                    <span className="checkmark" style={{
                      backgroundColor: 
                      item.cor === "BR" ? "#FFFFFF" :  item.cor === "PR" ? "#000000" : 
                      item.cor === "NQ" ? "#a8a4a3" :  item.cor === "CZ" ? "#808080" : 
                      item.cor === "OR" ? "#daa520" :  item.cor === "WD" ? "#deb887" : 
                      item.cor === "CR" ? "#c0c0c0" :  item.cor === "AN" ? "#383e42" :
                      item.cor === "AM" ? "#FFBF00":   item.cor === "WD" ? "#A1662F":
                      item.cor === "AZ" ? "#1E90FF":   item.cor === "VRD" ? "#228B22":
                      item.cor === "AMR" ? "#FFEA00":  item.cor === "FSC" ? "#696969": 
                      item.cor === "LRJ" ? "#FF8C00":  item.cor === "MLK" ? "#fff6e9":
                      item.cor === "TRP" ? "rgb(0 0 255 / 16%);":  item.cor === "VR" ? "#b81414":
                       item.cor, 
                      textAlign: 'center', color: '#4169e1', fontWeight: '500'}}></span>
                    {item.cor}
                  </label>
                ))}
                </div>
               </div>
              </div>

            </form>

           </div>
         
          </div>
          </div>

          <div className="col-sm-8 col-md-9">
            {/* Filters */}
            <ul className="shop__sorting">
              <li className="number-prods"><span>{t('listProds.existem')} <b>{filteredData.length}</b> {t('homepage.produtos')}</span></li>
              <li><span onClick={showProds}>Newest</span></li>
            </ul>
            <div className="row">
              {/*Search list result*/}
                {listProds.map((resu, index) => {
                // Busca as referências de data pelo idP
                const productReferences = data.find(ref => ref.idP === resu.idP) || {};

                // Garantir que prodEspe seja um array válido
                let references = [];

                // Se prodEspe for uma string, converta em um array (separando por vírgula)
                if (typeof productReferences.prodEspe === 'string') {
                references = productReferences.prodEspe.split(',').map(ref => ref.trim());
                }
                // Se prodEspe for um array, use-o diretamente
                else if (Array.isArray(productReferences.prodEspe)) {
                references = productReferences.prodEspe;
                }

                return (
                <div className="col-sm-6 col-md-3 product-card" key={index}>
                <Link to={`/prodetail/${resu.idP}`} target='_blank'>
                <div className="shop__thumb">
                  <div className="shop-thumb__img">
                    <img src={resu.imgP} className="img-responsive" alt="..." />
                  </div>
                  <h3 className="shop-thumb__title"><b>{resu.nome}</b></h3>
                  <h5 className="shop-thumb-brand">{resu.marca}</h5>

                  <h5 className="shop-thumb-brand">
                    <span className='references'>Refs:</span>&nbsp; 
                    {references.length > 0 ? (
                      references.slice(0, 3).map((ref, idx) => (
                        <span key={idx} className="product-reference">
                          {ref}
                          {idx < references.slice(0, 3).length - 1 && ', '}
                        </span>
                      ))) : (<span><></></span> )}
                  </h5>
                  <span className="shop-thumb-brand"> {resu?.ip != null ? <span>IP: {resu.ip}</span> : <br />}</span>
                  <div className="c-product-card__wrapper-3">
                        <ul className="c-product-card__certificates mb-2 xl:mb-3 flex flex-wrap gap-2">
                          <li><img src="https://cdn1.efectoled.com/img/co/fetval_rohs.png" alt="Product card certificates" width="1" height="1"></img></li>
                          <li><img src="https://cdn1.efectoled.com/img/co/fetval_ce.png" alt="Product card certificates" width="1" height="1"></img></li>
                        </ul>
                  </div>
                </div>
                </Link>
                </div>


                );
                })}

            </div> {/* / .row */}
          </div> {/* / .col-sm-8 */}
        </div> {/* / .row */}
      </div>

      <div className='center-pagination'>
            <section className="pagination-section p-5 p-2 bd-highlight">
            <ul className="pagination-prods">
            <li>
            <button id="pg-button-prev" type="button" className="pagination__button" 
            onClick={()=>onPageChange(currentPage-1)} disabled={currentPage ===1}>
              <i className="fa fa-chevron-left" />
            </button>
            </li>
            {startPage > 1 &&(
              <>
              <button className='pagination__button_ellipses' onClick={()=>onPageChange(1)}>1</button>
              {startPage > 2 && <span className='pagination__button_ellipses'>...</span>}
              </>
            )}
              {
                mpages.map((page,index)=>{
                    return(
                    <li className={page === currentPage ? 'active' : ''} key={index} 
                    onClick={()=>onPageChange(page)}>
                      <button id="pg-button-1" type="button">{page}</button>
                    </li>
                    )
                  })
              }
              <li>
            <button id="pg-button-next" type="button" className="pagination__button" 
              onClick={()=>onPageChange(currentPage+1)} disabled={currentPage === totalPages}>
              <i className="fa fa-chevron-right" />
            </button>
            </li>

            </ul>
          </section>
      </div>
    </div>
  )
}

export default ListProdus
 
