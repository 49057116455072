import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function LogoutModal({ show, onHide, onConfirm }) {
    const { t, i18n } = useTranslation('');
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('logout.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('logout.msg')}</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onHide}>{t('logout.no')}
        </Button>
        <Button variant="success" onClick={onConfirm}> {t('logout.sim')} </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogoutModal;