import React, { useEffect, useState, useRef} from 'react' 
import { Link, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Home from './Home'
import Contactos from './Contactos'
import Sobre from './Sobre'
import Noticias from './Noticias'
import AreaPessoal from './AreaPessoal'
import Produtos from './Produtos'
import PoliPriva from './PoliPriva'
import PoliCookies from './PoliCookies'
import Eventos from './Eventos'
import Portfolio from './Portfolio'
import '../layout/Popups.Module.css'
import '../layout/Navbar.Module.css'
import logob from '../images/logos/logo-b.png'
import logoBranco from '../images/logos/Logo com Avatar br.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faSearch, faAngleDown, faDownload, faArrowRight, faSignOut, faChevronLeft, faL } from '@fortawesome/free-solid-svg-icons'
import  $ from 'jquery'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { brochures, catalogos, flyers } from '../downloads'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber'
import validator from 'validator'
import { ourBrands, reBrands} from '../brands'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Projectos from './Projectos'
import ProdDetail from './ProdDetail'
import Noticia from './Noticia'
import ListProdus from './ListProdus'
import LoginModal from './LoginModal'
import LogoutModal from './LogoutModal'; 
import RedefinirSenha from './RedefinirSenha'; 
import emailjs from '@emailjs/browser'
import axios from 'axios';
import Recru from './Recru'
import DetalheEvento from './DetalheEvento'
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import i18n from './i18n'

function Navbar({ onResetPage }){
  const [resetPageFlag, setResetPageFlag] = useState(false);

  const { t, i18n } = useTranslation('');

  const { user, logout } = useAuth();
  const [showInfo, setShowInfo] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const getInitials = (name) => {
    return name.split(' ').map(word => word[0].toUpperCase()).join(''); // Pega a primeira letra de cada parte do nome
  };

/**************MYSQL database*******************/
useEffect(() =>{
  $(document).ready(
    $(".menu__item:not(.not-page)").on('click',function(e){
      $(".menu__item").removeClass("menu__item-current");
      $(this).addClass("menu__item-current");
    })
  );
 
  const menu = document.querySelector(".menu-respon");
  const menuItems = document.querySelectorAll(".menu__dropdown");
  const subMenuTitles = document.querySelectorAll(".menu-respon .menu-title");

  menuItems.forEach((item, index) => {
    item.addEventListener("click", () => {
      menu.classList.add("submenu-active");
      item.classList.add("show-submenu");
      menuItems.forEach((item2,index2)=>{
        if(index !== index2){ item2.classList.remove("show-submenu"); }
      })
    });
  });

  $(document).on("click", ".menu-title", function () {
    $('.menu-respon').removeClass("submenu-active");
});

$(".indicator li").on("click", function(event){
  var brandButton = $(this).data("brand");
})
/******************Tabs************************/
//add active class to the brands Tabs
const allIndicator = document.querySelectorAll('.indicator li:not(.not-tab)');
const allContent = document.querySelectorAll('.content-brand li');

allIndicator.forEach(item=> {
  item.addEventListener('click', function () {
    const content = document.querySelector(this.dataset.target);

    allIndicator.forEach(i=> {i.classList.remove('active'); })
    allContent.forEach(i=> {i.classList.remove('active'); })

    content.classList.add('active');
    this.classList.add('active');
  })
})

},[])
/*********SubTabs************/
var brandLinks = document.querySelectorAll(".ourBrandsImgs");
var tabContent = document.querySelectorAll(".tabOurs");

brandLinks.forEach(function(el){
  el.addEventListener("click", setupBrandTabs)
})

var brandLinksRepre = document.querySelectorAll(".repreBrandsImgs");
var tabReContent = document.querySelectorAll(".tabRepre");

brandLinksRepre.forEach(function(el){
  el.addEventListener("click", setupBrandTabsRepre)
})

function setupBrandTabsRepre (el){
  var imgReTarget = el.currentTarget;
  var brandRe = imgReTarget.dataset.brand;
  tabReContent.forEach(function(el){
    el.classList.remove("tab-content-active");
  });

  brandLinksRepre.forEach(function(el){
    el.classList.remove("active")
  });

  document.querySelector("#"+brandRe).classList.add('tab-content-active');
  imgReTarget.classList.add("active");
}

document.addEventListener('DOMContentLoaded',()=>{
  setupBrandTabs();
  setupBrandTabsRepre();
})

/******************Close hover dropdown after click an element************************/
/*
function removeAttr(){
  $('.nav-dropdown').addClass("closeHover"); 
  setTimeout(function() { $(".nav-dropdown").removeClass("closeHover");
  }, 5000);
}
$('.typesProds').on('click', function(){
  removeAttr();
})
*/
/*************Show products categories************** */
var subMenu_Brand = document.querySelectorAll(".submenu__inner");
var OursBrands = document.querySelectorAll(".brand-ours");
var RepreBrands = document.querySelectorAll(".brand-repre");

const width = window.innerWidth;
  if(width <= 1024){
    subMenu_Brand.forEach(function(el){
      el.addEventListener("click", showBrandCategory);
    })
}

function showBrandCategory(el){
  /*Obter valor de data-nameb do brand-ours e brand-repre*/
  var getBrand = $(this).find('.brandsImgs').attr('data-brand');
  /*Remover a classe showBrandCategories que mostra as categorias*/
  console.log("Current brand:"+ getBrand);
  OursBrands.forEach(function(el){
    console.log(el.dataset.nameb)
    el.classList.remove("showBrandCategories");
    if(getBrand === el.dataset.nameb){
    el.classList.add("showBrandCategories");
    }
  });
  RepreBrands.forEach(function(repre){
    repre.classList.remove("showBrandCategories");
    if(getBrand === repre.dataset.nameb){
      repre.classList.add("showBrandCategories");
    }
  });
}

 //remove class showBrandCategories
 $('.category-list').on('click',function(){
  $('.brand-ours').removeClass("showBrandCategories");
  $('.brand-repre').removeClass("showBrandCategories");
});
/******************Responsive navbar************************/
/*********Open First Tab************/
const [isActive, setActive] = useState(false);
const [isOverlay, setOverlay] = useState(false);
const [isSub, setSubMenu] = useState(false);

const toggleClass = () => {
  setActive(!isActive);
  setOverlay(!isOverlay);
  setSubMenu(!isSub);
};

$('.indicator li:not(.not-tab)').on('click',function(){
  $('.content-brand li').addClass("showBrand");
});

$('.subMenu-title').on('click',function(){
  $('.content-brand.subMenu li').removeClass("showBrand");
});

/*************** Download catalogs and brochures ******************/
const [download, setDownload] = useState('');
function openModal(e){
  handleShow();
  setDownload(download => e)
}
// Name and company Validation
const form = useRef();
function handleDownload(e){
  e.preventDefault();
  if(formState.name==='' || formState.company===''){
    alert('Faltam campos a serem preencher');
  }

  else{
    console.log("Valor atual do download:"+ download)
    window.open(download, '_blank');
  }

  emailjs.sendForm('service_p8r417p', 'template_a5wijz8', form.current, {
    publicKey: 'Hd9bXwR34y-Uu_Jb9',
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );
}

const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};
// Email and Phone Validation
const validateEmail = (e) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var email = e.target.value
  if (!regex.test(email)) {
    setEmailError('Email inválido');
 } else {
    setEmailError('Email válido :)!');
 }
}

function handleInputChange(event) {
  const { name, value } = event.target;
  setFormState(prevState => ({ ...prevState, [name]: value }));
}

const [phone, setPhone] = useState('');
const isValid = isPhoneValid(phone)

const [emailError, setEmailError] = useState('');
const [formState, setFormState] = useState({ from_name: '', from_company: ''});

// Country and city select on downloads
const [data, setData] = useState([]);
const [getCountry, setCountry] = useState();
const [getState, setState] = useState([]);
const [selectedState, setSelectedState] = useState();

useEffect(() =>{
   axios.get('https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json')
  .then( (res) =>{ 
    setData(res.data);
  }
  )
  .catch(function (error) {
    console.log(error);
  })
  .then(function () {
    // always executed
  });

},[])

const country = [...new Set(data.map(item => item.country))];
country.sort();

const handleCountry =(e)=>{
  let states = data.filter(state => state.country === e.target.value);
 states = [...new Set(states.map(item=> item.subcountry))];
 states.sort();
 console.log(states);
 setState(states);
}

const handleState =(e)=>{
  let cities = data.filter(city => city.subcountry === e.target.value);
 cities = [...new Set(cities.map(item=> item.subcountry))];
 console.log(cities);
}

  /**************Esqueçi a palavra passe**************** */
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [open, setOpen] = useState(false);

  const closeRedefinition = () => {
    setOpen(false);
  };

  const openRedefinition = () => {
      setOpen(true);
  };

  const handleSubmit = async (e) => {
      e.preventDefault();

      try {
          const response = await axios.post('https://advancetest.advance-rr.com/solicitar_redefinicao.php', {
              email: email,
          });

          setMessage(response.data.message);
      } catch (error) {
          console.error('Erro ao solicitar redefinição:', error);
          setMessage('Erro ao enviar solicitação. Tente novamente mais tarde.');
      }
  };

/*************************************************LOGOUT BUTTON****************************************/



/*************************************************LOGOUT BUTTON****************************************/
const [showLogoutModal, setShowLogoutModal] = useState(false);

const handleLogout = () => {
  setShowLogoutModal(true); // Abre o modal de logout
};

const confirmLogout = () => {
  logout(); // Chama a função de logout
  setShowLogoutModal(false); // Fecha o modal de logout
};

const cancelLogout = () => {
  setShowLogoutModal(false); // Fecha o modal de logout
};
/*************************************************Change Login/Register modal****************************************/
const [login, setLogin] = useState(false);

const [showLogin, setShowLogin] = useState(false);
const [showRegister, setShowRegister] = useState(false);

const loginShow = () => setShowLogin(true);
const loginClose = () => setShowLogin(false);
const registerShow = () => setShowRegister(true);
const registerClose = () => setShowRegister(false);

const changeModal = () => {
  setShowLogin(false);
  setShowRegister(true);
};

const changeToLogin=()=>{
  setShowRegister(false);
  setShowLogin(true);
}

function submitRegister(){
  alert("Entraremos em contacto em breve")
}
/*************** Open form pop up downloads******************/
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
/*************** Pop up ******************/

const [searchBox, setSearchBox] = useState("");
const searchB = useRef(null);

const navigate = useNavigate();
function handleSearch (){
  if(searchBox.trim().length !==0){
    navigate('/search-results/', {state:searchBox});
  }
}

function onKeypress(e){
  if(e.key === 'Enter'){
    handleSearch();
  }
}

const [brand, setBrand] = useState([])
useEffect(() => {
  if(!brand.length){
    fetch('https://api-murex-theta.vercel.app/listBrands')
    .then(res => res.json())
    .then(result => {
      setBrand(Array.isArray(result) ? result : []);
    })
    .catch(err => console.log(err))
  }
}, [brand]);

/**********Show categories of products********** */
function setupBrandTabs (el){
  var imgTarget = el.currentTarget;
  var brand = imgTarget.dataset.brand;

  tabContent.forEach(function(el){
    el.classList.remove("tab-content-active");
  });

  /**************Não deixa todos os elementos coloridos**************** */
  document.querySelector("#"+brand).classList.add('tab-content-active');
{/*  imgTarget.classList.add("active"); 
  $(".tabOurs").first().addClass("tab-content-active");*/}
}

const [activeLogo, setActiveLogo] = useState('');
const [activeLogoRe, setActiveLogoRe] = useState('');

const [activeDefault, setActiveDefault] = useState('primeiro');
const [disableDefaultTab, setDisableDefaultTab] = useState('tab-content-active');

const [activeDefaultRe, setActiveDefaultRe] = useState('primeiro');
const [disableDefaultTabRe, setDisableDefaultTabRe] = useState('tab-content-active');

function removeClassOfFirstElement(i){
 if(i!==0){
  setActiveDefault(!activeDefault)
  setDisableDefaultTab(!disableDefaultTab)
 }
}

function removeClassOfFirstElementRe(i){
  if(i!==0){
    setActiveDefaultRe(!activeDefaultRe)
    setDisableDefaultTabRe(!disableDefaultTabRe)
  }
 }
 
function handleLogoClick(b,i){
  removeClassOfFirstElement(i);
  setActiveLogo(b);
}

function handleLogoClickRe(b,i){
  removeClassOfFirstElementRe(i);
  setActiveLogoRe(b);
}

const [openLogin, setOpenLogin] = useState(false);

const openLoginModal = ()=>{
 setOpenLogin(!openLogin);
}

const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'pt');

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('language', lng);
  setSelectedLanguage(lng);
}

useEffect(() => {
  const savedLanguage = localStorage.getItem('language');
  if (savedLanguage) {
    changeLanguage(savedLanguage);
  }
}, []);

const flagUrls = {
  pt: 'https://cdn.countryflags.com/thumbs/portugal/flag-800.png',
  en: 'https://cdn.countryflags.com/thumbs/england/flag-800.png',
  es: 'https://cdn.countryflags.com/thumbs/spain/flag-800.png',
  fr: 'https://cdn.countryflags.com/thumbs/france/flag-800.png',
  it: 'https://cdn.countryflags.com/thumbs/italy/flag-800.png',
  zhCN: 'https://cdn.countryflags.com/thumbs/china/flag-800.png'
};

const availableLanguages = Object.keys(flagUrls).filter(lng => lng !== selectedLanguage);

const [menuOpen, setMenuOpen] = useState(false);

const toggleMenu = () => {
    setMenuOpen(!menuOpen);
};

const toggleInfo = () => {
  setShowInfo(!showInfo);
};

  return (
    <>
    <header className='header'>

     <nav className='navBar container-nav'>
     <section className="navbar__left">
        <Link to="/" className="brand"> <img src={logob} className='logo' id='logo'></img> </Link>
        <div className="burger" id="burger" onClick={toggleClass}>
          <span className="burger-line" />
          <span className="burger-line" />
          <span className="burger-line" />
        </div>
    </section>

      <section className="navbar__center">
        <span onClick={toggleClass} className={`overlay-nav ${isActive ? 'is-active': ''}`}></span>
        <div id='menu-respon' className={`menu-respon ${isActive ? 'is-active': ''}`}>
        <div className="menu__header">
          <span className="menu__arrow"><FontAwesomeIcon icon={faChevronLeft}/></span>
          <span className="menu__title"></span>
        </div>
          <ul className="menu">
        <li className='menu__item menu__item-current page'><Link to="/" id='house-icon' className='menu__link'><FontAwesomeIcon icon={faHome}/></Link></li>
        <li className={`menu__item menu__dropdown brands`}><a className='menu__link text-capitalize'>{t('navbar.marcas')}<FontAwesomeIcon className='brandList' icon={faAngleDown} /></a>

        <div className= {`nav-dropdown brands submenu__list`}>
        <div className='menu-title'>
            <i className='fa-solid fa-chevron-left'></i>
            <h6>{t('navbar.marcas')}</h6>
        </div>

        <ul className="indicator subMenu">
          <li className="active" data-brand="ours" data-target="#ours"><FontAwesomeIcon icon={faArrowRight}/><span>{t('navbar.nMarca')}</span></li>
          <li data-brand="repre" data-target="#repre"><FontAwesomeIcon icon={faArrowRight}/><span>{t('navbar.rMarca')}</span></li>
          <li className='seeAll not-tab'><Link to="/produtos" className=''>{t('portifolio.tudo')} +</Link></li>
        </ul>

        <ul className="content-brand subMenu">
          <li className={`active`} id="ours">
          <div className='subMenu-title'>
            <i className='fa-solid fa-chevron-left'></i>
            <h6>{t('navbar.mproprias')}</h6>
          </div>
            <div className='img-brands ours'>

            {brand.slice(0,4).map((b,i)=>(
            <div className='submenu__inner' key={i}>
                 <div className='submenu__logo '>
                 <img className={`brandsImgs ourBrandsImgs ${i == 0 ? activeDefault : ''}  ${activeLogo == b ? 'active': ''}`} src={b.logoB} alt={b.marca} data-brand={`${b.marca}`} onClick={()=> handleLogoClick(b,i)}/>
                 </div>
            </div>
            ))}
            
          </div>
          {ourBrands.map((item,index)=>(
          <div className='brand-ours' key={index} data-nameb={item.name}>
              <div className='category-list'>
                <i className='fa-solid fa-chevron-left'></i>
                <div className="image-title">
                  <img src={item.linkImg}></img>
                </div>
              </div>
              <div className={`subTabCategory tabOurs ${index === 0 ? disableDefaultTab : ''}`} key={index} id={item.name}>
                {item.category.map((type, index)=>(
                <Link to={`search-results/${item.name}/${type.name}`}  key={index} >
                <div className='typesProds'>
                 <LazyLoadImage src={type.linkImg}></LazyLoadImage>
                 <span className="title" style={{color: 'white'}}>{type?.nome[i18n.language]}</span>
                </div>
                </Link>
                ))}
              </div>
          </div>
            ))}
          </li>
      
          <li id="repre">
          <div className='subMenu-title'>
            <i className='fa-solid fa-chevron-left'></i>
            <h6>{t('navbar.mrepresen')}</h6>
          </div>
            <div className='img-brands repre' >
            {brand.slice(4,7).map((item,index)=>(
              <div className={`submenu__inner`} key={index} >
               <div className='submenu__logo'>
               <img className={`brandsImgs repreBrandsImgs ${index == 0 ? activeDefaultRe : ''}  ${activeLogoRe == item ? 'active': ''}`} src={item.logoB} alt={item.marca} data-brand={`${item.marca}`} onClick={()=> handleLogoClickRe(item,index)}/>
               </div>
              </div>
            ))}  
            </div>
            {reBrands.map((item,index)=>(
             <div className='brand-repre' key={index} data-nameb={item.name}>
               <div className='category-list'>
                  <i className='fa-solid fa-chevron-left'></i>
                  <div className="image-title">
                      <img src={item.linkImg}></img>
                  </div>
              </div>
              <div className={`subTabCategory tabRepre ${index === 0 ? disableDefaultTabRe : ''}`} key={index} id={item.name}>
                  {item.category?.map((type, index)=>(
                  <Link to={`search-results/${item.name}/${type.name}`} key={index} >
                    <div className="typesProds">
                    <LazyLoadImage src={type.linkImg}></LazyLoadImage>
                    <span className="title" style={{color: 'white'}}>{type?.nome[i18n.language]}</span>
                    </div>
                  </Link>
                  ))}
              </div>
            </div>
            ))}
          </li>
        </ul>
        </div>
        </li>
        
        <li className='menu__item not-page'><a href='#popup1' className='menu_download'>Downloads</a></li>
        <li className='menu__item'><Link to="/Contactos" className='menu__link'>{t('navbar.contactos')}</Link></li>
        <li className='menu__item'><Link to="/noticias" className='menu__link'>{t('homepage.titleNoticias')}</Link></li>
    

        <li className={'menu__item menu__dropdown about'}><a className='menu__link'>{t('navbar.sobre')}<FontAwesomeIcon className={`icons fa fa-angle-down subLevel`} icon={faAngleDown}/></a>
        <div className= {`nav-dropdown about`} >
          <div className='menu-title'>
          <i className='fa-solid fa-chevron-left'></i>
          <h6>{t('navbar.sobre')}</h6>
          </div>
          <ul className="nav-list">
            <li className='dropdown-ele'><Link to="/sobre-nos" className=''><i className="fa fa-info-circle"></i>{t('navbar.sobreN')}</Link></li>
            <li className='dropdown-ele'><Link to="/portfolio" className=''><i className="fas fa-briefcase"></i>{t('navbar.portfolio')}</Link></li>
            <li className='dropdown-ele'><Link to="/eventos" className=''><i className="fa fa-calendar" aria-hidden="true"></i>{t('navbar.eventos')}</Link></li>
          </ul>
        </div>
        </li>

        <li className='menu__item not-page login-container'> 
            {/* Se o usuário estiver logado, mostrar o círculo com as iniciais */}
            {user ? (
              <>
              <button type="button" onClick={toggleInfo} className='menu__link show-desktop'>
                  {/* Círculo com as iniciais */}
                  <div className="user-initials-circle">
                      {getInitials(user.nome)}
                  </div>
              </button><FontAwesomeIcon onClick={toggleInfo} className={`icons fa fa-angle-down subLevel show-desktop`} icon={faAngleDown}/>

            <li className="container mt-3 show-mobile menu__link">
                <button type="button" className='show-mobile' data-bs-toggle="collapse" data-bs-target="#login">
                    {/* Círculo com as iniciais */}
                    <div className="user-initials-circle">
                        {getInitials(user.nome)}
                    </div>
                </button>
                {user.email && <p className='show-email' data-bs-toggle="collapse" data-bs-target="#login">{user.email} <FontAwesomeIcon className={`icons fa fa-angle-down subLevel`} icon={faAngleDown}/></p>}
            </li>
              <div id="login" className="collapse show-mobile">
                 <p><strong>{t('navbar.ola')},</strong> {user.nome}!</p>

                <button className="personal-area"><Link to="/area-pessoal" className='menu__link'> <FontAwesomeIcon icon={faUser} />Área Pessoal</Link></button>
                <button onClick={handleLogout} className="logout-button show-mobile">Logout</button>
              </div>
              </> ) : (
                // Caso contrário, mostrar o ícone de login
                <button type="button" onClick={loginShow} className='menu__link'>
                    <FontAwesomeIcon icon={faUser} />
                </button>
               )}

            {showInfo && user && (
                <div className="user-info-container show-desktop">
                    <p><strong>{t('navbar.ola')},</strong> {user.nome}!</p>
                    {/* Exibir o e-mail do usuário */}
                    {user.email && <p className='show-email'>{user.email}</p>}
                    <button className="personal-area"><Link to="/area-pessoal" className='menu__link'> <FontAwesomeIcon icon={faUser} />Área Pessoal</Link></button>
                    <button onClick={handleLogout} className="logout-button"><FontAwesomeIcon className={`icons fas fa-sign-out-alt`} icon={faSignOut}/>Logout</button>
                </div>
            )}
          </li>

        <li className='menu__item not-page language-picker'>
        <div id="select-container">
        
          <li id="current-flag" flow="down">
          <img src={flagUrls[selectedLanguage]} alt={`Selected language: ${selectedLanguage}`} />
          </li>

          <ul>
          {availableLanguages.map(lng => (
          <li key={lng} lang-selection={lng.toUpperCase()} tooltip={lng.toUpperCase()} flow="down" onClick={() => changeLanguage(lng)}>
            <img src={flagUrls[lng]} alt={`${lng} Flag`} width="50" />
          </li>
          ))}
          </ul>

        </div>
        </li>

        </ul>
        </div>
      </section>

   <section className="navbar__right">
    <div className='move-search-box'>
        <div className="search-box">
          <input type="text" className="search-box-input" placeholder={t('navbar.searchbox')} value={searchBox} onChange={(e)=>setSearchBox(e.target.value)} onKeyUp={onKeypress}/>
          <button className="search-box-btn"><FontAwesomeIcon className="search-box-icon material-icons" icon={faSearch} onClick={handleSearch}/></button>
        </div>
    </div>
    </section>

     </nav>
     <div>
    </div>

    </header>

    {/*Pop up downloads*/}
  <div id="popup1" className="overlay">
      <div className="popup">

      <h1 className='main-heading'>{t('navbar.download')}</h1>
      <a className="close-btn fade-out main-btn-circle" href="#">X</a>
        <h2>{t('navbar.catalogos')}</h2>

        {/*Se usuário tiver conta não precisa fazer registro */}
        {user ? (
              <div id="gallery" className="photos-grid-container gallery">
              <div className="sub">
                {catalogos.map((item, index) =>((
                  <a href={item.download} download key={index} className='img-box' target='_blank'>
                    <img src={item.linkImg} alt="image" />
                    <div className="item__details"> 
                      <div className="item__details_text">
                        {item.nameImg[i18n.language]} <FontAwesomeIcon icon={faDownload} />
                      </div>
                    </div>
                  </a>
                )
              ))}
            </div>
          </div>
        ): (
          <div id="gallery" className="photos-grid-container gallery">
          <div className="sub">
           {catalogos.map((item, index) => (item.id === 1 ? (
              <div className="img-box"  onClick={() => {openModal(item.download); }} onChange={setDownload} key={index}>
                <img src={item.linkImg} alt="image" />
                <div className="item__details"> 
                  <div className="item__details_text">
                    {item.nameImg[i18n.language]} <FontAwesomeIcon icon={faDownload} />
                  </div>
                </div>
              </div>
            ) : (
              <a href={item.download} download key={index} className='img-box'>
                <img src={item.linkImg} alt="image" />
                <div className="item__details"> 
                  <div className="item__details_text">
                    {item.nameImg[i18n.language]} <FontAwesomeIcon icon={faDownload} />
                  </div>
                </div>
              </a>
            )
          ))}

        </div>
      </div>
        )}

        <h2>{t('navbar.brochuras')}</h2>
        <div className="brochuras-grid-container">
              <div className='sub'>
              {flyers.map((item, index)=>(
                <a href={item.download} key={index}>
                <img src={item.linkImg} alt={item.nameImg[i18n.language]} />
                <div className="item__details"> 
                <div className="item__details_text">{item.nameImg[i18n.language]}</div>
                </div>
                </a>
              ))}
              </div>
        </div>

          <h2>{t('navbar.flyers')}</h2>
        <div className="brochuras-grid-container">
              <div className='sub'>
              {brochures.map((item, index)=>(
                <a href={item.download} download key={index}>
                <img src={item.linkImg} alt={item.nameImg[i18n.language]} />
                <div className="item__details"> 
                <div className="item__details_text">{item.nameImg[i18n.language]}</div>
                </div>
                </a>
              ))}
              </div>
          </div>
      </div>
    </div>

    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Download</Modal.Title>
      </Modal.Header>
    <Modal.Body>


    <form ref={form} className="form-download row g-3 needs-validation" noValidate onSubmit={handleDownload} method='POST'>
      <div className="mb-1">
      <label className="form-label">{t('contactos.nome')}: <span>*</span></label>
      <input type="text" name="from_name" value={formState.name} onChange={handleInputChange} className="form-control" required/>
    </div>

    <div className="mb-1">
      <label className="form-label">Email: <span>*</span></label>
      <input type="email" onChange={(e) => validateEmail(e)} className="form-control" required name='from_email'/>
      <span style={{ fontWeight: 'bold', color: 'red' }}>{emailError}</span>
    </div>

    <div className="mb-1">
    <label className="form-label">{t('download.pais')}: <span>*</span></label>
      <select onChange={(e)=> handleCountry(e) } className="form-control" name='from_country'>
          {country.map(items=> <option key={items} value={getCountry}>{items}</option>)}
      </select>
    </div>

    <div className="mb-1">
    <label className="form-label">{t('recru.cidade')}: <span>*</span></label>
      <select onChange={(e)=> handleState(e) } className="form-control" name='from_city'>
          {getState.map(items=> <option key={items} value={selectedState}>{items}</option>)}
      </select>
    </div>

    <div className="mb-1">
      <label className="form-label">{t('download.empresa')}: <span>*</span></label>
      <input type="text" name="from_company" value={formState.company} onChange={handleInputChange} className="form-control"  />
    </div>

    <div className="mb-1">
      <label className="form-label">{t('recru.tele')}: <span>*</span></label>  
    <PhoneInput defaultCountry="pt" value={phone} onChange={(phone) => setPhone(phone)} name='from_phone'/>
    {!isValid && <div style={{ color: 'red' }}>{t('download.invalid')}</div>}
    </div>
    
      <div className="col-12">
        <button className="btn btn-primary sub-btn" type="submit" disabled={!isValid ||
          formState.from_name.length <5 || formState.from_company.length <4 
          || emailError !== 'Email válido :)!' || getState === ''}>{t('download.descar')}</button>
      </div>
    </form>

      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>{t('download.fechar')}</Button>
        </Modal.Footer>
      </Modal>


    {/*Pop up login*/}
    <LoginModal 
        login={showLogin} 
        loginClose={loginClose} 
        loginShow={loginShow}
        register={showRegister}
        registerClose={registerClose}
        registerShow={registerShow}
        changeModal={changeModal}
        changeToLogin={changeToLogin}
      />

      {/* Modal de Confirmação de Logout */}
     <LogoutModal 
        show={showLogoutModal} 
        onHide={cancelLogout} 
        onConfirm={confirmLogout} />
  <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/produtos' element={<Produtos/>}/>
        <Route path='/Contactos' element={<Contactos/>}/>
        <Route path='/sobre-nos' element={<Sobre/>}/>
        <Route path='/noticias' element={<Noticias/>}/>
        <Route path='/noticia/:id' element={<Noticia/>}/>
        <Route path='/eventos' element={<Eventos/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
        <Route path='/projectos/:name' element={<Projectos/>}/>
        <Route path='/prodetail/:id' element={<ProdDetail/>}/>
        <Route path='/search-results/:brand?/:category' element={<ListProdus/>}/>
        <Route path='/search-results' element={<ListProdus/>}/>
        <Route path='/candidatura-espontanea/' element={<Recru/>}/>
        <Route path='/evento/:name' element={<DetalheEvento/>}/>
        <Route path='/area-pessoal' element={<AreaPessoal/>}/>
        <Route path='/politica-de-privacidade/' element={<PoliPriva/>}/>
        <Route path='/politica-de-cookies/' element={<PoliCookies/>}/>
        <Route path="/redefinir_senha/:token" element={<RedefinirSenha />} />
    </Routes>
    </>
  )
}

export default Navbar
