export const events = [
    {
      id: 1,
      year: 2022,
      title: 'Matelec 2022',
      country:{ pt: `Espanha`, en: `Spain`, es: `España`, fr:`Espagne`, it:`Spagna`, zhCN: `西班牙` 
       },
      city: { pt: `Madrid`, en: `Madrid`,  es: `Madrid`, fr:`Madrid`,  it:`Madrid`, zhCN:`马德里`
       },
      date: '15/09/2022',
      imgP: '../images/feiras/matelec/gallery2022/matelec2022.png',

      currentEImg: '../images/feiras/matelec/gallery2022/matelec2022.png',

      sideRight: '../images/feiras/matelec/gallery2022/matelecright.png',
      title1: {
        pt: `O que é a Matelec ?`,
        en: `What is Matelec?`, 
        es: `¿Qué es Matelec?`,
        fr:`Qu'est-ce que Matelec ?`, 
        it:`Che cos'è il Matelec?`
        
       },
      logo: '../images/feiras/matelec/gallery2024/logo.png',
      para1: {
        pt: `É a Feira Internacional da Indústria Elétrica e Eletrônica dirigida a profissionais e apresenta elevada relevância no setor da iluminação. 
      MATELEC é uma grande plataforma comercial que serve de alavanca na aceleração e revitalização dos mercados, tanto no mercado interno como no sector exportador.`,
      en: `It is the International Trade Fair for the Electrical and Electronic Industry, aimed at professionals and highly relevant to the lighting sector. 
      MATELEC is a major commercial platform that serves as a lever for accelerating and revitalising markets, both domestically and in the export sector.`, 
      es: `Es la Feria Internacional de la Industria Eléctrica y Electrónica, dirigida a los profesionales y de gran relevancia para el sector de la iluminación. 
      MATELEC es una importante plataforma comercial que sirve de palanca para acelerar y revitalizar los mercados, tanto en el ámbito nacional como en el de la exportación.`,
       fr: `Il s'agit du salon international de l'industrie électrique et électronique, destiné aux professionnels et très pertinent pour le secteur de l'éclairage. 
      MATELEC est une importante plate-forme commerciale qui sert de levier pour accélérer et dynamiser les marchés, tant au niveau national qu'à l'exportation.`, 
       it:`È la fiera internazionale dell'industria elettrica ed elettronica, rivolta ai professionisti e di grande rilevanza per il settore dell'illuminazione. 
      MATELEC è un'importante piattaforma commerciale che funge da leva per accelerare e rivitalizzare i mercati, sia a livello nazionale che nel settore delle esportazioni.`,
      zhCN: `它是电气和电子行业的国际贸易博览会，面向专业人士，与照明行业密切相关。MATELEC 是一个重要的商业平台，是加速和振兴国内和出口市场的杠杆。` 
      }, 
      title2:{
       pt: `O que se expõe em Matelec?`,
       en: `What is on display at Matelec?`, 
       es: `¿Qué se expone en Matelec?`,
       fr:`Qu'est-ce qui est exposé à Matelec ?`, 
       it:`Cosa viene esposto a Matelec?`
      } ,
      para2:{
        pt: `A MATELEC está comprometida com a sustentabilidade, inovação e tecnologia, é desenvolvido sob o chamado ePower&Building com o lema “Inspiração e Negócios. 
      Construindo o futuro”. Uma comunidade setorial que reúne mais de 1.600 empresas expositoras e quase 90.000 participantes profissionais de 100 países. Serão 
      apresentando as novidades de empresas de Espanha e internacionais relacionadas aos setores de engenharia, Design eletrônico, Componentes Eletrônicos, Iluminação, Equipamento Industrial, eletrônica`,
      en: `It is the International Trade Fair for the Electrical and Electronic Industry, aimed at professionals and highly relevant to the lighting sector. 
      MATELEC is a major commercial platform that serves as a lever for accelerating and revitalising markets, both domestically and in the export sector.`, 
      es: `MATELEC apuesta por la sostenibilidad, la innovación y la tecnología MATELEC se desarrolla bajo el nombre ePower&Building con el lema «Inspiración y negocio. 
      Construyendo el futuro». Una comunidad industrial que reúne a más de 1.600 empresas expositoras y casi 90.000 participantes profesionales de 100 países. Se 
      mostrarán las últimas innovaciones de empresas españolas e internacionales en los campos de la ingeniería, diseño electrónico, componentes electrónicos, iluminación, equipamiento industrial, electrónica`,
       fr: `MATELEC s'engage en faveur de la durabilité, de l'innovation et de la technologie.  MATELEC est développé sous le nom d'ePower&Building avec la devise Inspiration and Business. 
       Construire l'avenir ». Une communauté industrielle qui rassemble plus de 1 600 sociétés exposantes et près de 90 000 participants professionnels de 100 pays. 
      et près de 90 000 participants professionnels de 100 pays. Ils seront présenteront les dernières nouveautés des entreprises espagnoles et internationales dans les domaines suivants 
      l'ingénierie, la conception électronique, les composants électroniques, l'éclairage, l'équipement industriel, l'électronique`, 
       it:`A MATELEC está comprometida com a sustentabilidade, inovação e tecnologia , é desenvolvido sob o chamado ePower&Building com o lema “Inspiração e Negócios. 
      Construindo o futuro”. Uma comunidade setorial que reúne mais de 1.600 empresas expositoras e quase 90.000 participantes profissionais de 100 países. Serão 
      apresentando as novidades de empresas de Espanha e internacionais relacionadas aos setores de engenharia, Design eletrônico, Componentes Eletrônicos, Iluminação, Equipamento Industrial, eletrônica`,
      zhCN: `MATELEC 致力于可持续发展、创新和技术，在 ePower&Building 旗帜下发展，以 “灵感与商业 ”为座右铭。建设未来"。来自 100 个国家的 1 600 多家参展公司和近 90 000 名专业与会者将汇聚在这个行业社区。他们将 
      将展示西班牙和国际公司在工程设计、电子设计、电子元件、照明、工业设备、电子和通信领域的最新创新成果。` 
      },
      gallery: [
        {linkImg:'../images/feiras/matelec/gallery2022/1.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/2.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/3.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/4.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/5.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/6.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/7.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/8.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/9.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/10.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/11.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/12.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/13.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/14.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/15.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/16.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/17.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/18.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/19.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/20.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/21.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/22.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2022/23.jpg'},{linkImg:'../images/feiras/matelec/gallery2022/24.jpg'}
      ],
    },
    {
      id: 2,
      year: 2024,
      title: 'Matelec 2024',
      country: { pt: `Espanha`,en: `Spain`, es: `España`,fr:`Espagne`,  it:`Spagna`, zhCN: `西班牙` 
       },
      city: { pt: `Madrid`, en: `Madrid`, es: `Madrid`,fr:`Madrid`,  it:`Madrid`, zhCN:`马德里`
       },
      date: '05/11/2024',
      imgP: '../images/feiras/matelec/gallery2024/matelec2024.png',
      currentEImg: '../images/feiras/matelec/gallery2024/backgroundImg.png',
      sideRight: '../images/feiras/matelec/gallery2024/matelecright.png',
      title1:{
        pt: `O que esperar da Matelec de 2024 ?`,en: `What can we expect from Matelec 2024?`, 
        es: `¿Qué podemos esperar de Matelec 2024?`, fr:`Que peut-on attendre de Matelec 2024 ?`, 
        it:`Cosa possiamo aspettarci da Matelec 2024?`,zhCN: `我们对 Matelec 2024 有什么期待？` 
       },
      logo: '../images/feiras/matelec/gallery2024/logo.png',
      para1:{
        pt: `De 5 a 8 de novembro de 2024 a Advance estará presente na Feira lnternacional de Eletricidade e Eletronica MATELEC. 
        Venhas-nos visitar e conhecer a nossa gama de produtos.`,
        en: `From 5 to 8 November 2024, Advance will be at the MATELEC International Electricity and Electronics Fair. 
        Come and visit us and see our range of products. `, 
        es: `Del 5 al 8 de noviembre de 2024, Advance estará presente en la Feria Internacional de Electricidad y Electrónica MATELEC.  
        Venga a visitarnos y conozca nuestra gama de productos.`,
        fr:`Du 5 au 8 novembre 2024, Advance sera présent au Salon international de l'électricité et de l'électronique MATELEC. 
        Venez nous rendre visite et découvrir notre gamme de produits.`, 
        it:`Dal 5 all'8 novembre 2024, Advance sarà presente alla Fiera internazionale dell'elettricità e dell'elettronica MATELEC. 
        Venite a trovarci e a vedere la nostra gamma di prodotti.`,zhCN: `2024 年 11 月 5 日至 8 日，Advance 将参加 MATELEC 国际电子电气博览会。欢迎前来参观我们的产品系列` 
       },
      title2:  { pt: `O que iremos expor no evento?`, en: `What will we exhibit at the event?`, es: `¿Qué expondremos en el evento?`,
        fr:`Qu'allons-nous exposer lors de l'événement ?`,  it:`Cosa esporremo all'evento?`,zhCN: `我们将在活动中展出什么` 
      },
      para2:{
        pt: `Uma vez mais a Advance mostrará seu objetivo de inovação e força no setor assim como também sua capacidade de enfrentar os novos desafios deste mercado cada vez mais sustentavel, conectado e de elevada qualidade.
         Neste evento deste ano teremos exposto uma gama diversa de produtos que fazem parte do nosso catalogo assim como também iremos apresentar novidades importantes para o setor da iluminação.`,
        en: `Once again, Advance will be showing its innovation and strength in the sector, as well as its ability to meet the new challenges of this increasingly sustainable, connected and high-quality market.
        At this year's event we will be exhibiting a diverse range of products from our catalogue, as well as presenting important innovations for the lighting sector.`, 
        es: `Una vez más, Advance mostrará su innovación y solidez en el sector, así como su capacidad para afrontar los nuevos retos de este mercado cada vez más sostenible, conectado y de alta calidad.
        En esta edición expondremos una variada gama de productos de nuestro catálogo, además de presentar importantes novedades para el sector de la iluminación.`,
        fr:`Une fois de plus, Advance démontrera son innovation et sa force dans le secteur, ainsi que sa capacité à relever les nouveaux défis de ce marché de plus en plus durable, connecté et de haute qualité.
        Lors de cet événement, nous exposerons une gamme variée de produits de notre catalogue et présenterons d'importantes innovations pour le secteur de l'éclairage.`, 
        it:`Ancora una volta, Advance mostrerà la sua innovazione e la sua forza nel settore, nonché la sua capacità di affrontare le nuove sfide di questo mercato sempre più sostenibile, connesso e di alta qualità.
       All'evento di quest'anno esporremo una vasta gamma di prodotti del nostro catalogo, oltre a presentare importanti innovazioni per il settore dell'illuminazione.`,
       zhCN: `Advance 将再次展示其在该领域的创新和实力，以及应对这一日益可持续、互联和高质量市场新挑战的能力。在今年的展会上，我们将展出产品目录中的各种产品，并展示照明领域的重要创新成果。` 
       },
      gallery: [
        {linkImg:'../images/feiras/matelec/gallery2024/1.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/2.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/3.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/4.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/5.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/6.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/7.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/8.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/9.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/10.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/11.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/12.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/13.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/14.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/15.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/16.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/17.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/18.jpg'},
        {linkImg:'../images/feiras/matelec/gallery2024/19.jpg'},{linkImg:'../images/feiras/matelec/gallery2024/20.jpg'},
      ],
    },
    {
      id: 3,
      year: 2024,
      title: 'Light + Building',
      country: { pt: `Alemanha`, en: `Germany`, es: `Alemania`,fr:`Allemagne`, it:`Germania`, zhCN: `德国` 
       },
      city:{
        pt: `Frankfurt`, en: `Frankfurt`,  es: `Fráncfort`,fr:`Francfort`, it:`Francoforte`, zhCN: `法兰克福`
       },
      date: '03/08/2024',
      logo: '../images/feiras/frankfurt/logo.png',
      imgP: '../images/feiras/frankfurt/poster.jpg',
      currentEImg: '../images/feiras/frankfurt/poster.jpg',
      sideRight: '../images/feiras/frankfurt/frankfurt2.png',
      title1: {
        pt: `O que é a Light + building ?`, en: `What is Light + building?`, es: `¿Qué es Light + building?`,
        fr:`Qu'est-ce que Light + building ?`, it:`Che cos'è Light + building?`, zhCN: `什么是 Light + building？` 
       },
      para1:{
        pt: `A Light+Building é a maior feira comercial do mundo para tecnologia de iluminação e 
        serviços de construção, focada nas áreas de iluminação, engenharia elétrica, automação predial
        e software de engenharia civil. É realizada na Messe Frankfurt, em Frankfurt am Main, Alemanha , 
        e combinada com o festival de design de iluminação e arte Luminale com instalações e performances 
        na cidade e arredores.`,
        en: `Light+Building is the world's largest trade fair for lighting technology and building services, focussing on the areas of lighting, 
        electrical engineering, building automation and civil engineering software. It is held at Messe Frankfurt in Frankfurt am Main, Germany, 
        and combined with the Luminale lighting design and art festival with installations and performances in and around the city.`, 
        es: ` Light+Building es la mayor feria mundial de luminotecnia y servicios para edificios, centrada en las áreas de iluminación, 
        ingeniería eléctrica, automatización de edificios y software de ingeniería civil. Se celebra en la Messe Frankfurt de Fráncfort 
        del Meno (Alemania) y se combina con el festival de arte y diseño de iluminación Luminale, con instalaciones y actuaciones en la ciudad y sus alrededores.`,
        fr:` Light+Building est le plus grand salon mondial des technologies de l'éclairage et des services du bâtiment, 
        axé sur les domaines de l'éclairage, de l'ingénierie électrique, de l'automatisation des bâtiments et des logiciels de 
        génie civil. Il se tient à la Messe Frankfurt à Francfort-sur-le-Main, en Allemagne, et se combine avec le festival d'art 
        et de design d'éclairage Luminale, qui propose des installations et des performances dans la ville et ses environs. dans et autour de la ville.`, 
        it:`Light+Building è la più grande fiera al mondo per l'illuminotecnica e i servizi di illuminazione. 
      servizi, incentrata sui settori dell'illuminazione, dell'elettrotecnica, dell'automazione degli edifici e del software per l'ingegneria civile.
      e software per l'ingegneria civile. Si tiene presso la Messe Frankfurt a Francoforte sul Meno, in Germania, 
      e si combina con il festival artistico e di lighting design Luminale, con installazioni e spettacoli in città e nei dintorni. 
      in città e nei dintorni.`,
      zhCN: ` Light+Building 是世界上最大的照明技术和建筑服务贸易展览会，重点关注照明、电气工程、楼宇自动化和土木工程软件等领域、 电气工程、楼宇自动化和土木工程软件等领域。它在德国美因河畔法兰克福的法兰克福展览馆举行、 同时还举办 Luminale 照明设计和艺术节，在市内和周边地区举办装置和表演活动。`
       } ,
      title2: {
        pt:`O que apresentamos em Frankfurt ?`, en:`What are we presenting in Frankfurt?`,  es:`¿Qué presentamos en Fráncfort?`,
        fr:`Que présentons-nous à Francfort ?`, it:`Cosa presenteremo a Francoforte?`,  zhCN: `我们将在法兰克福展示什么？` 
       },
      para2:{
        pt:`Fiéis ao nosso lema de inovação e qualidade, demos a conhecer aos nossos clientes em Frankfurt, a capacidade de utilizar todas as possibilidades da luz, tanto como meio de iluminação 
      como para produção de energia. As nossas soluções de energia inteligente, tecnologias solares e sistemas de iluminação modernos para melhorar os ambientes de trabalho e de vida ajudaram os 
      nossos clientes a conhecer formas de  obter poupanças significativas nos custos de energia. 
      Também demonstramos a todos os visitantes o nosso comprometimento com um planeta mais verde, reduzindo as emissões e resíduos de CO2 através de produtos, soluções e processos sustentáveis.`,
        en:`True to our motto of innovation and quality, we showed our customers in Frankfurt that we can exploit all the possibilities of light, both as a means of illumination and for energy production. 
      and for energy production. Our intelligent energy solutions, solar technologies and modern lighting systems for improving working and living environments helped our customers learn about ways to save money. 
      Our customers learnt about ways to save significantly on energy costs. 
We also demonstrated to all visitors our commitment to a greener planet, reducing CO2 emissions and waste through sustainable products, solutions and processes.`, 
        es:`Fieles a nuestro lema de innovación y calidad, demostramos a nuestros clientes en Fráncfort que podemos explotar todas las posibilidades de la luz, tanto como medio de iluminación como para la producción de energía. 
      y para la producción de energía. Nuestras soluciones energéticas inteligentes, tecnologías solares y modernos sistemas de iluminación para mejorar los entornos de trabajo y de vida ayudaron a nuestros clientes a conocer formas de ahorrar dinero. 
      Nuestros clientes conocieron formas de ahorrar considerablemente en costes energéticos. 
También demostramos a todos los visitantes nuestro compromiso con un planeta más verde, reduciendo las emisiones de CO2 y los residuos mediante productos, soluciones y procesos sostenibles.`,
        fr:`Fidèles à notre devise d'innovation et de qualité, nous avons montré à nos clients à Francfort que nous pouvons exploiter toutes les possibilités de la lumière, à la fois comme moyen d'éclairage et pour la production d'énergie. 
      et pour la production d'énergie. Nos solutions énergétiques intelligentes, nos technologies solaires et nos systèmes d'éclairage modernes destinés à améliorer les environnements de travail et de vie ont permis à nos clients de découvrir des moyens d'économiser de l'argent. 
      Nos clients ont découvert des moyens d'économiser de manière significative sur les coûts de l'énergie. 
Nous avons également démontré à tous les visiteurs notre engagement en faveur d'une planète plus verte, en réduisant les émissions de CO2 et les déchets grâce à des produits, des solutions et des processus durables.`,  it:`Fedeli al nostro motto di innovazione e qualità, a Francoforte abbiamo dimostrato ai nostri clienti che possiamo sfruttare tutte le possibilità della luce, sia come mezzo di illuminazione che per la produzione di energia. 
      e per la produzione di energia. Le nostre soluzioni energetiche intelligenti, le tecnologie solari e i moderni sistemi di illuminazione per migliorare gli ambienti di lavoro e di vita hanno aiutato i nostri clienti a conoscere i modi per risparmiare. 
      i nostri clienti a conoscere i modi per risparmiare in modo significativo sui costi energetici. 
Abbiamo inoltre dimostrato a tutti i visitatori il nostro impegno per un pianeta più verde, riducendo le emissioni di CO2 e i rifiuti attraverso prodotti, soluzioni e processi sostenibili.`,
zhCN: `我们秉承创新和质量的宗旨，在法兰克福向客户展示了我们可以利用光的所有可能性，既可以作为照明手段，也可以用于能源生产和能源生产。我们的智能能源解决方案、太阳能技术和用于改善工作和生活环境的现代照明系统帮助客户了解了节约资金的方法。
      我们的客户了解了如何大幅节约能源成本。
我们还向所有参观者展示了我们对绿色地球的承诺，通过可持续产品、解决方案和工艺减少二氧化碳排放和废物。` 
       },
      gallery: [
        {linkImg:'../images/feiras/frankfurt/f1.png'},
        {linkImg:'../images/feiras/frankfurt/f2.png'},
        {linkImg:'../images/feiras/frankfurt/f3.png'},
        {linkImg:'../images/feiras/frankfurt/f4.png'},
      ],
    },
    {
      id: 4,
      year: 2022,
      title: 'Tektónica 2022',
      country:{ pt: `Portugal`, en: `Portugal`, es: `Portugal`, fr:`Portugal`, it:`Portogallo`, zhCN: `葡萄牙` 
       },
      city: { pt: `Lisboa`, en: `Lisbon`,  es: `Lisboa`, fr:`Lisbonne`,  it:`Lisbona`, zhCN:`里斯本`
       },
      date: '12/05/2022',
      imgP: '../images/feiras/tektonica/2022/poster.jpg',

      currentEImg: '../images/feiras/tektonica/2022/poster.jpg',

      sideRight: '../images/feiras/matelec/gallery2022/matelecright.png',
      title1: {
        pt: `O que é a Tektónica ?`,
        en: `What is Tektónica?`, 
        es: `¿Qué es Tektónica?`,
        fr:`Qu'est-ce que Tektónica ?`, 
        it:`Che cos'è il Tektónica?`,
        zhCN: `什么是 Tektónica？` 
       },
      logo: '../images/feiras/tektonica/2022/logo.png',
      para1: {
      pt: `É a Feira Internacional da Indústria Construção. Afirma-se como o Marketplace do sector da construção, um espaço privilegiado para o negócio,  network e o palco de excelência para o lançamento de novos produtos e inovações. `,
      en: `It is the International Construction Industry Trade Fair. It has established itself as the construction sector's marketplace, a privileged space for business, networking and the stage of excellence for launching new products and innovations. `, 
      es: `Es la Feria Internacional de la Industria de la Construcción. Se ha consolidado como el mercado del sector de la construcción, un espacio privilegiado para los negocios, la creación de redes y el escenario por excelencia para el lanzamiento de nuevos productos e innovaciones. `,
      fr: `Il s'agit du salon international de l'industrie de la construction. Il s'est imposé comme le marché du secteur de la construction, un espace privilégié pour les affaires, la mise en réseau et la scène d'excellence pour le lancement de nouveaux produits et d'innovations. `, 
      it:`È la Fiera Internazionale dell'Industria delle Costruzioni. Si è affermata come il mercato del settore delle costruzioni, uno spazio privilegiato per gli affari, il networking e il palcoscenico d'eccellenza per il lancio di nuovi prodotti e innovazioni. `
      }, 
      title2:{
       pt: `O que se expõe na Tektónica?`,
       en: `What is on display at Tektónica?`, 
       es: `¿Qué se expone en Tektónica?`,
       fr:`Qu'est-ce qui est exposé à Tektónica ?`, 
       it:`Cosa viene esposto a Tektónica?`,
       zhCN: `Tektónica 展览会有什么内容` 
      },
      para2:{
        pt: `A MATELEC está comprometida com a sustentabilidade, inovação e tecnologia, é desenvolvido sob o chamado ePower&Building com o lema “Inspiração e Negócios. 
      Construindo o futuro”. Uma comunidade setorial que reúne mais de 1.600 empresas expositoras e quase 90.000 participantes profissionais de 100 países. Serão 
      apresentando as novidades de empresas de Espanha e internacionais relacionadas aos setores de engenharia, Design eletrônico, Componentes Eletrônicos, Iluminação, Equipamento Industrial, eletrônica`,
      en: `It is the International Trade Fair for the Electrical and Electronic Industry, aimed at professionals and highly relevant to the lighting sector. 
      MATELEC is a major commercial platform that serves as a lever for accelerating and revitalising markets, both domestically and in the export sector.`, 
      es: `MATELEC apuesta por la sostenibilidad, la innovación y la tecnología MATELEC se desarrolla bajo el nombre ePower&Building con el lema «Inspiración y negocio. 
      Construyendo el futuro». Una comunidad industrial que reúne a más de 1.600 empresas expositoras y casi 90.000 participantes profesionales de 100 países. Se 
      mostrarán las últimas innovaciones de empresas españolas e internacionales en los campos de la ingeniería, diseño electrónico, componentes electrónicos, iluminación, equipamiento industrial, electrónica`,
       fr: `MATELEC s'engage en faveur de la durabilité, de l'innovation et de la technologie.  MATELEC est développé sous le nom d'ePower&Building avec la devise Inspiration and Business. 
       Construire l'avenir ». Une communauté industrielle qui rassemble plus de 1 600 sociétés exposantes et près de 90 000 participants professionnels de 100 pays. 
      et près de 90 000 participants professionnels de 100 pays. Ils seront présenteront les dernières nouveautés des entreprises espagnoles et internationales dans les domaines suivants 
      l'ingénierie, la conception électronique, les composants électroniques, l'éclairage, l'équipement industriel, l'électronique`, 
       it:`A MATELEC está comprometida com a sustentabilidade, inovação e tecnologia , é desenvolvido sob o chamado ePower&Building com o lema “Inspiração e Negócios. 
      Construindo o futuro”. Uma comunidade setorial que reúne mais de 1.600 empresas expositoras e quase 90.000 participantes profissionais de 100 países. Serão 
      apresentando as novidades de empresas de Espanha e internacionais relacionadas aos setores de engenharia, Design eletrônico, Componentes Eletrônicos, Iluminação, Equipamento Industrial, eletrônica`
      },
      gallery: [
        {linkImg:'../images/feiras/tektonica/2022/1.jpg'},{linkImg:'../images/feiras/tektonica/2022/2.jpg'},
        {linkImg:'../images/feiras/tektonica/2022/3.jpeg'},{linkImg:'../images/feiras/tektonica/2022/4.jpeg'},
        {linkImg:'../images/feiras/tektonica/2022/5.jpeg'},{linkImg:'../images/feiras/tektonica/2022/6.jpeg'},
        {linkImg:'../images/feiras/tektonica/2022/7.jpg'},{linkImg:'../images/feiras/tektonica/2022/8.jpeg'},
        {linkImg:'../images/feiras/tektonica/2022/9.jpeg'},{linkImg:'../images/feiras/tektonica/2022/10.jpg'},
        {linkImg:'../images/feiras/tektonica/2022/11.jpeg'},{linkImg:'../images/feiras/tektonica/2022/12.jpeg'},
        {linkImg:'../images/feiras/tektonica/2022/13.jpeg'},{linkImg:'../images/feiras/tektonica/2022/14.jpeg'},
        {linkImg:'../images/feiras/tektonica/2022/15.jpeg'},{linkImg:'../images/feiras/tektonica/2022/16.jpeg'},
      ],
    },
  ];

  export const projectos = [
    {
      id: 1,
      type:{
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN :"贸易"
    },
      name: 'Ginásio mais Fit',
      social: 'https://www.maisfit.pt/',
      imgP: 'https://ik.imagekit.io/uqwhopr9g/Portfolio/Gin%C3%A1sio%20Mais%20fit/maisfit.jpg?updatedAt=1722420159894',
      place: 'Vila nova de Gaia',
      descri: {
        pt: `Mais de 120 aulas, um ginásio equipado com aparelhos Fitness de última geração e uma equipa de profissionais pronta a ajudar-te a atingires os teus objetivos!`, 
        en: `More than 120 classes, a gym equipped with state-of-the-art fitness equipment and a team of professionals ready to help you achieve your goals!`,
        es: 'Más de 120 clases, un gimnasio equipado con aparatos de fitness de última generación y un equipo de profesionales dispuestos a ayudarte a alcanzar tus objetivos.',
        fr: `Plus de 120 cours, une salle de sport équipée de matériel de pointe et une équipe de professionnels prêts à vous aider à atteindre vos objectifs !`, 
        it:`Più di 120 lezioni, una palestra dotata di attrezzature fitness all'avanguardia e un team di professionisti pronti ad aiutarvi a raggiungere i vostri obiettivi!`,
        zhCN : `120 多门课程、配备最先进健身器材的健身房以及随时准备帮助您实现目标的专业团队！`
      },
      gallery: [
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Gin%C3%A1sio%20Mais%20fit/1.jpg?updatedAt=1722420158963'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Gin%C3%A1sio%20Mais%20fit/2.jpg?updatedAt=1722420158936'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Gin%C3%A1sio%20Mais%20fit/3.jpg?updatedAt=1722420158826'},
      ],
    },
    {
      id: 2,
      type:{
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN : `贸易`
    },
      name: 'Steakhouse Churrasqueira Nacional',
      social: 'https://www.facebook.com/churrasqueiranacional/?locale=pt_BR',
      imgP: 'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/churrasco-nacional.jpg?updatedAt=1722419544393',
      place: 'Braga',
      descri: {
        pt: `A Steakhouse Churrasqueira Nacional abriu no final de 2021 em Braga. A sala, ampla e de estilo industrial, elegante e acolhedora, convida a prolongar a visita até à sobremesa.`,
        en: `The Churrasqueira Nacional steakhouse opened at the end of 2021 in Braga. The large, industrial-style room is elegant and cosy and invites you to stay until dessert`,
        es: `El asador Churrasqueira Nacional abrió sus puertas a finales de 2021 en Braga. La gran sala de estilo industrial es elegante y acogedora e invita a quedarse hasta el postre`,
        fr: `Le restaurant Churrasqueira Nacional a ouvert ses portes fin 2021 à Braga. La grande salle de style industriel est élégante et accueillante et vous invite à rester jusqu'au dessert`,
        it: `La steakhouse Churrasqueira Nacional ha aperto alla fine del 2021 a Braga. L'ampia sala in stile industriale è elegante e accogliente e invita a rimanere fino al dessert.`,
        zhCN : `Churrasqueira Nacional 牛排餐厅于 2021 年底在布拉加开业。宽敞的工业风格餐厅优雅舒适，欢迎您在此逗留至甜点。`
      },
      gallery: [ 
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/1.jpg?updatedAt=1722419724256'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/2.jpg?updatedAt=1722419722550'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/3.jpg?updatedAt=1722419722472'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/4.jpg?updatedAt=1722419722522'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/5.jpg?updatedAt=1722419722658'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/6.jpg?updatedAt=1722419723054'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/7.jpg?updatedAt=1722419722535'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/8.jpg?updatedAt=1722419722790'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/9.jpg?updatedAt=1722419919433'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/10.jpg?updatedAt=1722419722678'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/11.jpg?updatedAt=1722419722587'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/12.jpg?updatedAt=1722419724134'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/13.jpg?updatedAt=1722419724134'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/Steakhouse%20Churrasqueira%20Nacional/Gallery/14.jpg?updatedAt=1722419724134'},
      ],
    },
    {
      id: 3,
      type: {
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN : `贸易`
      },
      name: 'In Fashion',
      imgP: 'https://ik.imagekit.io/uqwhopr9g/Portfolio/In%20Fashion/ImageP.png?updatedAt=1722421405727',
      place: 'Vila do Conde',
      descri: {
        pt: 'Loja de vestuário feminino em Varziela', en: "Women's clothing shop in Varziela", es: 'Tienda de ropa de mujer en Varziela',
        fr: 'Magasin de vêtements pour femmes à Varziela', it:'Negozio di abbigliamento femminile a Varziela',zhCN : `瓦尔泽拉女装店`
      },
      gallery: [
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/In%20Fashion/ImageP.png?updatedAt=1722421405727'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/In%20Fashion/Gallery/1.png?updatedAt=1722421424413'},
        {linkImg:'https://ik.imagekit.io/uqwhopr9g/Portfolio/In%20Fashion/Gallery/2.png?updatedAt=1722421272573'},
      ],
    },

    {
      id: 4,
      type: {
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN : `贸易`
      },
      name: 'Florista',
      imgP: '../images/portfolio/Florista/1.jpeg',
      descri: {
        pt: '', en: "", es: '',
        fr: '', it:'',zhCN :``
      },
      gallery: [
        {linkImg:'../images/portfolio/Florista/1.jpeg'},
        {linkImg:'../images/portfolio/Florista/2.jpeg'},
        {linkImg:'../images/portfolio/Florista/3.jpg'},
        {linkImg:'../images/portfolio/Florista/4.jpg'},
        {linkImg:'../images/portfolio/Florista/5.jpeg'},
      ],
    },

    {
      id: 5,
      type: {
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN : `贸易`
      },
      name: 'Barbearia e Tattoo',
      imgP: '../images/portfolio/Barbearia/1.jpg',
      descri: {
        pt: '', en: "", es: '',
        fr: '', it:'',zhCN :``
      },
      gallery: [
        {linkImg:'../images/portfolio/Barbearia/1.jpg'},
        {linkImg:'../images/portfolio/Barbearia/2.jpg'},
        {linkImg:'../images/portfolio/Barbearia/3.jpg'},
      ],
    },

    {
      id: 6,
      type: {
        pt: "Comércio", en: "Trade", es: "Comercio",
        fr: 'Commerce', it:'Commercio',zhCN : `贸易`
      },
      name: 'Farmácia',
      imgP: '../images/portfolio/Farmacia/0.jpg',
      descri: {
        pt: '', en: "", es: '',
        fr: '', it:'',zhCN :``
      },
      gallery: [
        {linkImg:'../images/portfolio/Farmacia/1.jpg'},
        {linkImg:'../images/portfolio/Farmacia/2.jpg'},
        {linkImg:'../images/portfolio/Farmacia/3.jpg'},
      ],
    },
    
  ];

