import React, { useState, useEffect,useRef  } from 'react'
import { Link } from 'react-router-dom'
import styles from '../layout/Home.Module.css'
import  '../layout/LanguageSelect.Module.css'
import  $ from 'jquery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight,faPlus } from '@fortawesome/free-solid-svg-icons'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cardImageSlide } from '../card-slide';
import Modal from 'react-bootstrap/Modal';
import logoBranco from '../images/logos/Logo com Avatar br.png'
import Slider from 'react-slick'
import video from '../video/S.-Iluminacao-Magnetico-LA_1.mp4'
import { news } from '../noticias';
import axios from 'axios';
import LoginModal from './LoginModal'

import { useTranslation } from 'react-i18next';
const Home = () => {
/************Change Login/Register modal**********/
const [login, setLogin] = useState(false);
const [register, setRegister] = useState(false);

const loginClose = () => setLogin(false);
const loginShow = () => setLogin(true);

const registerClose = () => setRegister(false);
const registerShow = () => setRegister(true);

const changeModal=()=>{
  loginClose();
  registerShow();
}

const changeToLogin=()=>{
  registerClose();
  loginShow();
}

/**************MYSQL database****************** */
const { t, i18n } = useTranslation();

const [categorias, setCategories] = useState([])
const [loading, setLoading] = useState(true);
useEffect(() => {

  fetch('https://api-murex-theta.vercel.app/categorias')
  .then(res => res.json())
  .then(brand => setCategories(brand)
)
  .catch(err => console.log(err));
}, [categorias]);
 
const [products, setProducts] = useState([])
useEffect(() => {
  fetch('https://api-murex-theta.vercel.app/products')
  .then(res => res.json())
  .then(prod => {
    setProducts(Array.isArray(prod) ? prod : []);
  })
  .catch(err => console.log(err));
}, [products]);


const [catego, setCategorie] = useState([]);

useEffect(() => {
  fetch('https://api-murex-theta.vercel.app/categorias')
    .then(res => res.json())
    .then(data => {
      const translatedCategories = data.map(categoria => {
        const translatedCategoryName = t(`categorias.${categoria.nome}`) || categoria.nome;

        const translatedSubcategorias = categoria.subcategorias.map(subcategoria => {
          const translatedSubcategoryName = t(`categorias.${subcategoria.nome}`) || subcategoria.nome;
          return {
            ...subcategoria,
            nome: translatedSubcategoryName,
          };
        });

        return {
          ...categoria,
          nome: translatedCategoryName, // Nome traduzido
          nomeOriginal: categoria.nome, // Nome original para o link
          subcategorias: translatedSubcategorias,
        };
      });

      setCategorie(translatedCategories);
    })
    .catch(err => console.log(err));
}, [t]); // Reexecuta quando a função de tradução muda


const [activeIndex, setActiveIndex] = useState(0);
const listRef = useRef(null);
const thumbnailsRef = useRef(null);

const countItem = cardImageSlide.length;

const showSlider = (newIndex) => {
  // Atualiza o item ativo
  setActiveIndex(newIndex);
};

const nextSlide = () => {
  const newIndex = activeIndex + 1 >= countItem ? 0 : activeIndex + 1;
  showSlider(newIndex);
};

const prevSlide = () => {
  const newIndex = activeIndex - 1 < 0 ? countItem - 1 : activeIndex - 1;
  showSlider(newIndex);
};

const handleThumbnailClick = (index) => {
  showSlider(index);
};

useEffect(() => {
  // Verifique se os elementos foram renderizados antes de tentar acessá-los
  if (listRef.current && thumbnailsRef.current) {
    const items = listRef.current.querySelectorAll(".item");
    const thumbnails = thumbnailsRef.current.querySelectorAll(".item");

    if (items.length > 0 && thumbnails.length > 0) {
      // Remove a classe "active" dos itens anteriores
      items.forEach((item) => item.classList.remove("active"));
      thumbnails.forEach((thumbnail) => thumbnail.classList.remove("active"));

      // Adiciona a classe "active" aos itens atuais
      items[activeIndex].classList.add("active");
      thumbnails[activeIndex].classList.add("active");
    }
  }
}, [activeIndex]);

const settings = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 1000,
  autoplaySpeed: 6000,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        dots: false
      }
    }
  ]
};

const newProds = {
  autoplay: false,
  dots: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }
  ]
};
//Ativa contador quando é visível na tela
var elementToCheck = $('.counterUp');
function counterRup(){
  $('.counterUp').each(function() {

    var $this = $(this),
      countTo = $this.attr('data-number');
    
    $({ countNum: $this.text()}).animate({
      countNum: countTo
    },
  
    {
      duration: 3000,
      easing:'linear',
      step: function() {
        $this.text(Math.floor(this.countNum));
      },
      complete: function() {
        $this.text(this.countNum);
        //alert('finished');
      }
    });  
  
  }); 
}

window.addEventListener('scroll', () => { 
  if (counterRup(elementToCheck)) { 
  }
});

//Date of news
const getFormattedDate = (dateStr) => {
  const date = new Date(dateStr);
  const parts = dateStr.split("/");
  const day = parseInt(parts[0], 10);
  return day;
}

const getMonth= (dateStr) => {
  var dateParts = dateStr.split("/");

  const year = parseInt(dateParts[2]);
  const month = parseInt(dateParts[1])-1;
  const day = parseInt(dateParts[0]);
  
  var dateObject = new Date(year, month, day); 
  const m = dateObject.toLocaleString('en-US', { month: 'short' });
  return m
}

const [currentLanguage, setCurrentLanguage] = useState('pt');
  return (
    <div className='homepage' style={styles}>

      <div className="slider">
        <div className='slogan'>{t('homepage.header')}</div>

        <div className="list">
          {cardImageSlide.map((item, index) => (
               <div className={`item ${index === activeIndex ? "active" : ""}`} key={index}>
               <img src={item.linkImg} alt={item.cardTitle}/>
               <div className="content">
                   <p>{item.smaltitle}</p>
                   <h2>{item.bigtitle[i18n.language]}</h2>
                   <p>{item.text[i18n.language]}</p>
               </div>
           </div>
          ))}
        </div>

        <div className="arrows">
            <button id="prev" onClick={prevSlide}><FontAwesomeIcon icon={faChevronLeft} /></button>
            <button id="next" onClick={nextSlide}><FontAwesomeIcon icon={faChevronRight} /></button>
        </div>

        <div className="thumbnail">
            {cardImageSlide.map((item, index) => (
              <div className={`item ${index === activeIndex ? "active" : ""}`} key={index} onClick={() => handleThumbnailClick(index)}>
                <img src={item.linkImg} alt={item.cardTitle}/>
                 <div className="content">{item.bigtitle[i18n.language]}</div>
              </div>
          ))}
        </div>

      <section className="section">
      <Link to="/produtos" className="bb_button"><span className="bb_button__text bg">{t('homepage.verMais')}</span></Link>
      <button href="" className="bb_button"><span className="bb_button__text bord" onClick={loginShow}>Login</span></button>
    </section>
    </div>

<div className='categories'>
<h3 className='title'>{t('homepage.titleCategories')}</h3>
  <Slider {...settings}>
  {/*Link para redirecionar para a página de produtos filtrada por categoria com marca*/}
  {catego.map((item, index) => (
  <Link to={`/search-results/${item.nomeOriginal}`} key={index}>
  <div className="card">
      <div className="card-top">
        <img src={item.imagem} alt={item.nome}/>
      </div>
      <div className="card-bottom">
        <h1 className="category">{item.nome}</h1>
      </div>
    </div>
    </Link>
    ))}
  </Slider>
   <div className='catalog-box'>
   <a href="#popup1" className="catalog"> <span className="bb_button__text">{t('homepage.catalogo')}</span></a>
   </div>
</div>

<section className='video'>
  <h2 className='text-center title-video'>{t('homepage.titleDestaque')}</h2>

  <div className='content-box'>

  <div className='text-box'>
  <h2 className='title-text'>{t('homepage.iluMagne')}</h2>
  <p>{t('homepage.iluMagneTxtPara1')}</p>

  <p>{t('homepage.iluMagneTxtPara2')}</p>
  <div className='list-magne-imgs'>
      <div className='magne-img'>
      <img src='https://advance-rr.com/wp-content/uploads/2023/03/31674-1.jpg' alt='Projetor Esférico'/>
      <span>Projetor Esférico</span>
      <h6>10 Watt</h6>
      </div>
      <div className='magne-img'>
      <img src='https://advance-rr.com/wp-content/uploads/2023/03/31636-1.jpg' alt='Projetor Esférico'/>
      <span>Projetor Esférico</span>
      <h6>12 Watt</h6>
      </div>
      <div className='magne-img'>
      <img src='https://advance-rr.com/wp-content/uploads/2023/03/31759-1.jpg' alt='Projetor Tubular'/>
      <span>Projetor Tubular</span>
      <h6>6 Watt</h6>
      </div>
      <div className='magne-img'>
      <img src='https://advance-rr.com/wp-content/uploads/2023/03/31773-1.jpg' alt='Néon Tubular'/>
      <span>Néon Tubular</span>
      <h6>18 | 36 Watt</h6>
      </div>
  </div>
</div>

  <div className='video-box'>
  <iframe id="video"  width="100%" height="100%" src={video+`?autoplay=1&mute=1`} allow="autoplay; encrypted-media" title="Sistemas magnéticos"></iframe>
  </div>
  </div>

</section>

{/************************map if novidades.map() == item.novo exibir produtos***************************** */}
<div className='news-section'>
<h2 className='text-center'>{t('homepage.novidades')}</h2>

  <main className='new-prods'>
      <div className="card-container">
      <Slider {...newProds}>
        {products.map((prod,index)=>
        (prod.novo === 1) &&
        (<Link to={`/prodetail/${prod.idP}`} key={index}>
          <div className="card-new">
          <img src={prod.imgP} alt={prod.nome} />
          <div className="card-content">
            <h2>{prod.nome}</h2>
            <div className="card-details">
            <p>{'{'} {prod.marca}/{prod.nomeC} {'}'}</p>
            </div>
          </div>
        </div>
        </Link>
        )
        )}
    </Slider>
    </div>
  </main>
</div>

<div className='info-company'>
  <div className='info-adv-box'>

    <div className='info-item'>
    <img src = "./images/icones/factory.svg" alt="factory"/>
      <h4 className='text'>+ <span className='counterUp' data-number="8000">0</span> m2</h4>
    </div>
    <div className='info-item' >
    <img src = "./images/icones/icon6.svg" alt="team"/>
      <h4 className='text'>+ <span className='counterUp' data-number="50">0</span> {t('homepage.pessoas')}</h4>
    </div>
    <div className='info-item'>
      <img src = "./images/icones/prod.svg" alt="prod"/>
      <h4 className='text'>+ <span className='counterUp' data-number="3000">0</span> {t('homepage.produtos')}</h4>
    </div>
    <div className='info-item'>
      <img src = "./images/icones/positive.svg" alt="positive grown"/>
      <h4 className='text'>{t('homepage.cresci')}</h4>
    </div>

  </div>

  {/*  <span>Iluminamos seu caminho onde quer que esteja</span>
  <span>Iluminamos todos os seus caminhos</span>*/}
</div>
<div className='brands'>
  <h2 className='text-center'></h2>

</div>
<div className='news'>
<section className="blog">
  <div className="container">
    {/* header of section */}
    <div className="blog-head text-center">
      <h2>{t('homepage.titleNoticias')}</h2>
      <h6>{t('homepage.subTituloNoticias')}</h6><br/>
      <Link to="/noticias" className=''>{t('homepage.verMais')} +</Link>
    </div>
    {/* trocar pelas notícias da lista*/}
    <div className="row">
      {news.map((n,index)=>{
        if(index <= 2) return(
          <div className="col-md-6 col-lg-4" key={index}>
            <div className="item">
              <div className="img">
                <img src={n.imgP} alt="" />
              </div>
              <div className="info">
                <div className="date">
                  <span>{getFormattedDate(n.data)}<br />{getMonth(n.data)}</span>
                </div>
                <Link to={`/noticia/${n.id}`} onClick={()=>{window.scroll(0,0);}}><h5>{n.title[i18n.language]}</h5></Link>

                <p className='news-descri'>{n.descri[i18n.language]}</p>
                <Link className="user" to={`/noticia/${n.id}`} onClick={()=>{window.scroll(0,0); }}>
                <FontAwesomeIcon icon={faPlus} />Ler+</Link>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
</section>
</div>
    {/*Pop up downloads*/}
    <LoginModal 
        login={login} 
        loginClose={loginClose} 
        loginShow={loginShow}
        register={register}
        registerClose={registerClose}
        registerShow={registerShow}
        changeModal={changeModal}
        changeToLogin={changeToLogin}
      />

  </div>
  )
}

export default Home


